import { Container, Typography, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import IllustrationScreen from 'components/IllustrationPage'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import routes from '../navigation/routes'

export const TechPage = () => {
  const { t } = useTranslation()
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        '.subhead': { color: '#888', fontSize: '1.2rem', textAlign: 'center' },
        '.title': {
          fontSize: '2.2rem',
          fontWeight: 700,
          letterSpacing: '-0.02em',
          mt: 2,
          textAlign: 'center'
        },
        // 'a:hover': {
        //   color: '#FF0000 !important'
        // },
        // 'a:link, a:visited': {
        //   color: '#05112c !important',
        //   fontWeight: 'bold',
        //   textDecoration: 'none'
        // },
        backgroundColor: '#F8F8F5FF',
        fontSize: '1.2rem'
      }}
    >
      <Container maxWidth="md" sx={{ backgroundColor: '#F8F8F5FF' }}></Container>
      <IllustrationScreen
        backgroundColor="#F8F8F5FF"
        imgUrl="/landing/tenko_platform.png"
        tooltips={[
          { label: t('external_models'), left: 0.05, top: 0.35 },
          { label: t('external_data'), left: 0.87, top: 0.45 },
          { label: t('exposure_models'), left: 0.36, small: true, top: 0.32 },
          { label: t('climate_related_risk'), left: 0.48, small: true, top: 0.28 },
          { label: t('economic_supply_chain_risk'), left: 0.58, small: true, top: 0.37 },
          { label: t('financial_impact'), left: 0.45, small: true, top: 0.42 },
          { label: t('stakeholders'), left: 0.02, top: 0.85 },
          { label: t('stakeholders_clients'), left: 0.75, top: 0.72 },
          { label: t('reporting'), left: 0.25, small: true, top: 0.58 },
          { label: t('dataviz'), left: 0.35, small: true, top: 0.54 },
          { label: t('decision_support'), left: 0.52, small: true, top: 0.56 },
          { label: t('collaborative_tools'), left: 0.55, small: true, top: 0.65 }
        ]}
      >
        <Typography className="title">{t('tech_page_title')}</Typography>
        <Typography className="subhead">{t('tech_page_desc')}</Typography>
        <p>{t('tech_page_par_1')}</p>
        <p>{t('tech_page_par_2')}</p>
        <p>
          <Link component={RouterLink} to="/#use-cases-nav">
            {t('tech_page_use_cases_link')}
          </Link>{' '}
          {t('tech_page_use_cases_text')}
        </p>
        <p>
          <Link component={RouterLink} to={generatePath(routes.contact)}>
            {t('contact_us')}
          </Link>{' '}
          {t('tech_page_contact_text')}
        </p>
      </IllustrationScreen>
    </Container>
  )
}
