import axios from 'axios'

export async function loginUser(dispatch, payload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' })
    const { data } = await axios.post('/user/login', payload)

    const token = data.access_token
    localStorage.setItem('token', token)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    const { data: user } = await axios.get('/user/me')
    localStorage.setItem('currentUser', JSON.stringify(user))

    dispatch({ payload: { authUser: user, token }, type: 'LOGIN_SUCCESS' })
  } catch (error) {
    console.log(error)
    dispatch({ error, type: 'LOGIN_ERROR' })
  }
}

export async function reloadSession(dispatch, token) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' })
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    const { data: user } = await axios.get('/user/me')
    dispatch({ payload: { authUser: user, token }, type: 'LOGIN_SUCCESS' })
  } catch (error) {
    await logoutUser(dispatch)
  }
}

export async function logoutUser(dispatch) {
  localStorage.removeItem('token')
  delete axios.defaults.headers.common.Authorization
  dispatch({ type: 'LOGOUT' })
}
