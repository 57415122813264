import { Container } from '@mui/material'
import ErrorBoundaryWithFallback from 'components/ErrorBoundaryWithFallback'

export const CustomContainer = ({ children, noBorder = false, sx }) => {
  return (
    <Container
      maxWidth="lg"
      sx={
        !noBorder
          ? {
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              p: 4,
              pt: 2,
              ...sx
            }
          : { ...sx }
      }
    >
      {children}
    </Container>
  )
}

export const CustomContainerWithErrorBoundary = ({ children, ...rest }) => {
  return (
    <ErrorBoundaryWithFallback fullPageError>
      <CustomContainer {...rest}>{children}</CustomContainer>
    </ErrorBoundaryWithFallback>
  )
}
