import { Clear, SearchOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

const FilterInput = ({
  filterReset,
  initialValue,
  items,
  setFilterReset,
  setFilteredItems,
  sx,
  extraSearchString = () => '',
  ...others
}) => {
  const { t } = useTranslation()
  const [filterVal, setFilterVal] = useState(initialValue)
  const searchString = i =>
    [
      i.name,
      i.address?.country.display_name,
      i.sector?.description,
      i.sector?.eora_category,
      extraSearchString(i)
    ]
      .filter(s => !!s)
      .join('#')
      .toUpperCase()

  useEffect(() => {
    if (!filterVal) setFilteredItems(items)
    else {
      setFilteredItems(
        items.filter(item => searchString(item).indexOf(filterVal.toUpperCase()) !== -1)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, filterVal, setFilteredItems])

  useEffect(() => {
    if (filterReset) {
      setFilterVal(null)
      setFilterReset(false)
    }
  }, [filterReset, setFilterReset, setFilterVal])

  return (
    <>
      {!!items?.length && (
        <TextField
          {...others}
          InputProps={{
            endAdornment: !!filterVal && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear filter"
                  edge="end"
                  onClick={() => setFilterVal(null)}
                  size="small"
                >
                  <Clear fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined fontSize="small" />
              </InputAdornment>
            )
          }}
          autoCapitalize="off"
          autoCorrect="off"
          name="filter"
          onChange={e => setFilterVal(e.target.value)}
          placeholder={t('filter')}
          size="small"
          spellCheck={false}
          sx={{
            '.MuiInputLabel-sizeSmall': { zIndex: 0 },
            ...sx
          }}
          value={filterVal || ''}
          variant="outlined"
        />
      )}
    </>
  )
}

export default FilterInput

FilterInput.propTypes = {
  items: PropTypes.array.isRequired,
  setFilteredItems: PropTypes.func.isRequired
}
