import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material'
import { Loader } from '@googlemaps/js-api-loader'
import { useCallback, useMemo, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete'
import ToggleContent from './UI/ToggleContent'

const AddressLookup = ({ mutation }) => {
  const [address, setAddress] = useState('')
  const [isGeocoding, setIsGeocoding] = useState(false)

  const handleChange = useCallback(
    address => {
      setAddress(address.label)
      setIsGeocoding(true)
      geocodeByAddress(address.label)
        .then(data =>
          mutation.mutate({
            address: {
              google_address_components: data[0].address_components,
              latitude: data[0].geometry.location.lat(),
              longitude: data[0].geometry.location.lng()
            }
          })
        )
        .finally(() => {
          setIsGeocoding(isGeocoding)
        })
    },
    [isGeocoding, mutation]
  )

  return (
    <Box sx={{ '& > div': { zIndex: 1 } }}>
      {isGeocoding ? (
        <LinearProgress />
      ) : (
        <GooglePlacesAutocomplete
          // apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          // apiOptions={{ language: 'en', libraries: ['places'] }}
          selectProps={{
            onChange: handleChange,
            placeholder: 'Type a street address…',
            value: address
          }}
        />
      )}
    </Box>
  )
}

const Marker = () => {
  return (
    <Box
      sx={{
        bottom: 32,
        position: 'relative'
      }}
    >
      <img height={32} src="/marker.png" width={20.5} />
    </Box>
  )
}

const Address = ({ address, baseline, mutation }) => {
  const googleApiParams = {
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    id: '__google_maps_api',
    libraries: ['places'],
    mapIds: ['87721ba2a932c4e']
  }
  const googleApiLoader = new Loader(googleApiParams)
  googleApiLoader.load()

  const summary = useMemo(() => {
    if (address?.country) {
      if (address?.city) {
        return `${address.city} (${address.country?.display_name})…`
      }
      return `${address.country?.display_name}…`
    }
    return 'Add Location…'
  }, [address])

  const addressBlock = useMemo(() => {
    const initialArray = [address?.details] || []
    if (address?.city || address?.postcode)
      initialArray.push(
        (address?.city || '') +
          ((address?.postcode && address?.city && ', ') || '') +
          (address?.postcode || '')
      )
    initialArray.push(address?.country?.display_name)
    return initialArray
  }, [address?.city, address?.country?.display_name, address?.details, address?.postcode])

  const latLng = { lat: address?.latitude, lng: address?.longitude }

  const modified = useMemo(() => {
    if (baseline !== undefined)
      return baseline?.latitude !== address?.latitude || baseline?.longitude !== address?.longitude
  }, [address?.latitude, address?.longitude, baseline])

  return (
    <ToggleContent
      closed={summary}
      color={modified ? 'red' : null}
      isLoading={!address}
      startOpen={!address?.country}
      title="Address"
    >
      <>
        <AddressLookup mutation={mutation} />
        {mutation.isLoading ? (
          <LinearProgress />
        ) : (
          address?.country && (
            <Box
              sx={{
                backgroundColor: '#EFEFEF',
                fontSize: '100%',
                fontStyle: 'italic',
                marginTop: 0.5,
                padding: 1
              }}
            >
              {addressBlock.map((l, idx) => (
                <Typography key={idx}>{l}</Typography>
              ))}
            </Box>
          )
        )}
        {latLng.lat && latLng.lng ? (
          <Box
            sx={{
              height: '320px',
              marginTop: 2,
              textAlign: 'center',
              width: '100%'
            }}
          >
            {mutation.isLoading ? (
              <CircularProgress size={150} />
            ) : (
              <GoogleMapReact
                bootstrapURLKeys={googleApiParams}
                center={latLng}
                defaultZoom={11}
                options={{
                  controlSize: 20,
                  mapId: '87721ba2a932c4e',
                  styles: undefined
                }}
              >
                <Marker {...latLng} />
              </GoogleMapReact>
            )}
          </Box>
        ) : null}
      </>
    </ToggleContent>
  )
}

export default Address
