import { CircularProgress } from '@mui/material'
import { opTypes, useGetOperation, useMutateOperation } from '../api/operations'
// import QueryErrors from './QueryErrors'
import Address from './Address'
import Fieldset from './UI/Fieldset'
import OperationDetails from './OperationDetails'
import React from 'react'

export default function PortfolioDetails({ baselineOp, cachedOp, selectItem }) {
  const { data: portfolio, isLoading } = useGetOperation(cachedOp)
  const mutation = useMutateOperation(portfolio)

  if (isLoading) return <CircularProgress />

  return (
    <OperationDetails
      baseline={baselineOp}
      createBtnProps={{
        opType: opTypes.COMPANY
      }}
      data={portfolio}
      isLoading={isLoading}
      mutation={mutation}
      selectItem={selectItem}
    >
      <Fieldset dividerAfter>
        <Address address={portfolio?.address} baseline={baselineOp?.address} mutation={mutation} />
      </Fieldset>
    </OperationDetails>
  )
}
