import { IconButton, Link, Typography } from '@mui/material'
import { opTypes, useGetOperation, useMutateOperation } from '../api/operations'
import { useTranslation } from 'react-i18next'
import Address from './Address'
import ContactInvite from './ContactInvite'
import Edit from '@mui/icons-material/Edit'
import Fieldset from './UI/Fieldset'
import OperationDetails from './OperationDetails'
import React from 'react'
import SectorCombo from './SectorCombo'

export default function CompanyDetails(props) {
  const { t } = useTranslation()
  const { baselineOp, cachedOp, selectItem } = props
  const { data: company, isLoading } = useGetOperation(cachedOp)
  const mutation = useMutateOperation(company)

  return (
    <OperationDetails
      createBtnProps={{
        opType: opTypes.ASSET,
        payload: { sector_code: company.sector?.code }
      }}
      data={company}
      isLoading={isLoading}
      mutation={mutation}
      selectItem={selectItem}
    >
      {!company.strategy_of_id && (
        <Fieldset dividerAfter>
          {company.contact ? (
            <>
              <Typography variant="h5">{t('contact')}</Typography>
              <Typography>
                <Link
                  href={'mailto:' + company.contact?.email}
                  underline="hover"
                >{`${company.contact?.first_name} ${company.contact?.last_name}`}</Link>{' '}
                <ContactInvite buttonType={IconButton} company={company} size="small">
                  <Edit fontSize="small" />
                </ContactInvite>
              </Typography>
            </>
          ) : (
            <ContactInvite color="primary" company={company}>
              {`${t('add')} ${t('contact')}`}
            </ContactInvite>
          )}
        </Fieldset>
      )}
      <Fieldset>
        <Address address={company?.address} baseline={baselineOp?.address} mutation={mutation} />
      </Fieldset>
      <Fieldset dividerAfter>
        <SectorCombo
          baseline={baselineOp?.sector}
          mutation={mutation}
          sector={company.sector}
          withToggle
        />
      </Fieldset>
    </OperationDetails>
  )
}
