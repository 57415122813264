import { CallMade } from '@mui/icons-material'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import ToggleContent from './UI/ToggleContent'
import FilterInput from './UI/FilterInput'
import { FACILITY } from 'utils/operations'

export default function OperationList({ parentBaseline, parentOp, selectItem }) {
  const { t } = useTranslation()
  // const { data: baseline, isLoading: loadingBaseline } = useGetOperation(parentOp.baseline, {
  //   enabled: !!parentOp.baseline?.endpoint,
  //   staleTime: 0
  // })

  const [displayedOpsList, setDisplayedOpsList] = useState(parentOp?.children)
  const [filterReset, setFilterReset] = useState(false)

  const deletedOps = parentBaseline?.children?.filter(
    c => !parentOp.children.find(op => op.baseline?.id === c.id)
  )

  const modified =
    parentBaseline && (deletedOps?.length || parentOp.children.find(op => !op.baseline))

  return (
    !!parentOp?.children?.length && (
      <ToggleContent
        color={modified ? 'red' : null}
        startOpen={parentOp?.children.length <= 5}
        title={`${t(parentOp.childrenLabel)}: ${parentOp.children.length}`}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {parentOp.opType === FACILITY && parentOp.is_warehouse && (
            <FilterInput
              filterReset={filterReset}
              items={parentOp.children}
              setFilterReset={setFilterReset}
              setFilteredItems={setDisplayedOpsList}
              sx={{ mb: 1 }}
            />
          )}
          <List
            dense
            disablePadding
            sx={{
              '.added': {
                color: 'red'
              },
              '.deleted': {
                color: 'red',
                px: '16px',
                py: '4px',
                textDecoration: 'line-through'
              },
              bgcolor: 'background.paper'
            }}
          >
            {displayedOpsList?.map(op => (
              <ListItem
                className={parentBaseline && !op.baseline ? 'added' : ''}
                disableGutters
                disablePadding
                key={op.id}
              >
                <ListItemButton
                  TouchRippleProps={{ classes: { root: 'MuiRippleRoot' } }}
                  dense
                  onClick={() => {
                    selectItem(op.endpoint)
                  }}
                  role={undefined}
                  sx={{
                    '&:hover': { backgroundColor: '#285D7D22' },
                    '.MuiRippleRoot': { color: 'primary.main' }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      marginRight: 1,
                      minWidth: 0
                    }}
                  >
                    <op.labelIcon />
                  </ListItemIcon>
                  <ListItemText primary={op.name || `<${op.placeholder}>`} />
                  <CallMade color="primary" />
                </ListItemButton>
              </ListItem>
            ))}
            {deletedOps?.map(op => (
              <ListItem className="deleted" disableGutters disablePadding key={op.id}>
                <ListItemIcon
                  sx={{
                    marginRight: 1,
                    minWidth: 0
                  }}
                >
                  <op.labelIcon />
                </ListItemIcon>
                <ListItemText primary={op.name || `<${op.placeholder}>`} />
              </ListItem>
            ))}
          </List>
        </Box>
      </ToggleContent>
    )
  )
}
