/* eslint-disable prettier/prettier */

const getMin = arr => {
  let len = arr.length
  let min = Infinity
  while (len--) {
    min = arr[len] < min ? arr[len] : min
  }
  return min
}

const getMax = arr => {
  let len = arr.length
  let max = -Infinity
  while (len--) {
    max = arr[len] > max ? arr[len] : max
  }
  return max
}

const formatCurrency = amount => {
  return Intl.NumberFormat('en-GB', {
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency'
  }).format(amount)
}

const formatAmountWithCurrency = (amount, currency) => {
  if (!currency) return amount.toLocaleString("en")

  if (isNaN(amount)) return amount

  return Intl.NumberFormat('en-GB', {
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency'
  }).format(amount)
}

const formatCurrencyWithNormalisation = (amount, currency) => {
  if (amount >= 100000 && amount < 1000000) {
    return `${formatAmountWithCurrency((amount / 1000), currency)}K`
  }
  if (amount >= 1000000 && amount < 1000000000) {
    return `${formatAmountWithCurrency((amount / 1000000), currency)}M`
  }
  if (amount >= 1000000000) {
    return `${formatAmountWithCurrency((amount / 1000000000), currency)}Bn`
  } 
  
  return formatAmountWithCurrency(amount, currency)
}

const formatPercentage = percent => {
  if (!percent) return 0
  
  return `${parseFloat(percent * 100
  ).toFixed(1)}%`
}

const normalizeValue = (value, min, max) => {
  return (value - min) / (max - min)
}

export const roundNumber = (value, decimals) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

export { getMin, getMax, formatCurrency, formatPercentage, formatCurrencyWithNormalisation, formatAmountWithCurrency, normalizeValue }
