import 'services/i18n'
import App from './App'
import GA4React from 'ga-4-react'
import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

const root = createRoot(document.getElementById('root'))
root.render(<App />)

const gaKey = process.env.REACT_APP_GA4_KEY

if (gaKey) {
  try {
    const ga4react = new GA4React(
      gaKey,
      {
        /* ga custom config, optional */
      },
      [
        /* additional code, optional */
      ],
      5000 /* timeout, optional, defaults is 5000 */
    )
    ga4react
      .initialize()
      .then(ga4 => {
        console.log('Google Analytics enabled')
        ga4.pageview('path')
      })
      .catch(err => console.error(err))
  } catch (err) {
    console.error(err)
  }
}
