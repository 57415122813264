import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import { en, fr } from './lang'
import { sanitizePath } from 'utils/routing'
// "Inline" English and French translations.

export const supportedLangs = ['en', 'fr']

i18next.on('languageChanged', lng => {
  const { location } = window
  const prevLang = location.pathname.split('/')[1]
  const langPrefix = lng.includes('-') ? lng.split('-')[0].toLowerCase() : lng.toLowerCase()

  if (!location.pathname.includes(langPrefix)) {
    const newPath = supportedLangs.some(lang => location.pathname.includes(lang))
      ? location.pathname.replace(prevLang.toLowerCase(), langPrefix)
      : `/${langPrefix + location.pathname}`

    window.location.replace(sanitizePath(newPath))
  }
})

const resources = {
  en,
  fr
}
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      caches: ['localStorage', 'cookie'],
      checkWhitelist: true,
      lookupFromPathIndex: 0,
      order: ['path', 'localStorage', 'cookie', 'sessionStorage', 'navigator']
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    nonExplicitSupportedLngs: true,
    resources,
    supportedLngs: supportedLangs,
    whitelist: supportedLangs
  })
export default i18next
