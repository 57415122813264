import { Box, Divider } from '@mui/material'

export default function Fieldset(props) {
  const { className, dividerAfter, dividerBefore, ...other } = props

  return (
    <Box {...other}>
      {dividerBefore && <Divider variant="middle" />}
      <Box className={className} sx={{ marginBottom: 2, marginTop: 0.5 }}>
        {props.children}
      </Box>
      {dividerAfter && <Divider sx={{ marginBottom: 1.5 }} variant="middle" />}
    </Box>
  )
}
