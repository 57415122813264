import { AppContext } from '../App'
import {
  Button,
  //   CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import { processError } from 'api/error'
import { useFormik } from 'formik'
import { useInviteQuery } from '../api/invite'
import QueryErrors from './UI/QueryErrors'
import React, { useContext, useEffect, useState } from 'react'
import Toast from './UI/Toast'

export default function ContactInvite(props) {
  //   const classes = useStyles()

  const { buttonType, company, ...btnOptions } = props
  const { user } = useContext(AppContext)
  const mutation = useInviteQuery(user, company)
  const [open, setOpen] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    setFormErrors(processError(mutation.error).fields || {})
  }, [mutation.error])

  const formik = useFormik({
    initialValues: {
      recipient_email: company.contact?.email || '',
      recipient_first_name: company.contact?.first_name || '',
      recipient_last_name: company.contact?.last_name || ''
    },
    onSubmit: values => {
      mutation.mutateAsync(values).then(() => setOpen(false))
    }
  })

  const ButtonEl = buttonType || Button

  //   if (isLoading) return <CircularProgress />
  return (
    <>
      <Toast
        message="Contact updated"
        onClose={mutation.reset}
        open={mutation.isSuccess}
        type="success"
      />
      <ButtonEl {...btnOptions} disabled={mutation.isLoading} onClick={() => setOpen(true)}>
        {props.children || 'Invite'}
      </ButtonEl>
      <Dialog
        aria-describedby="dialog-description"
        aria-labelledby="dialog-title"
        onClose={() => setOpen(false)}
        open={open}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Invite contact</DialogTitle>
          <DialogContent>
            <DialogContentText>Invite a new user to manage this company:</DialogContentText>
            <QueryErrors mutation={mutation} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  error={!!formErrors.recipient_first_name || false}
                  fullWidth
                  helperText={formErrors.recipient_first_name || null}
                  label="First Name"
                  name="recipient_first_name"
                  onChange={formik.handleChange}
                  size="medium"
                  value={formik.values.recipient_first_name}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={!!formErrors.recipient_last_name || false}
                  fullWidth
                  helperText={formErrors.recipient_last_name || null}
                  label="Last Name"
                  name="recipient_last_name"
                  onChange={formik.handleChange}
                  required
                  size="medium"
                  value={formik.values.recipient_last_name}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.recipient_email || false}
                  fullWidth
                  helperText={formErrors.recipient_email || null}
                  label="Email"
                  name="recipient_email"
                  onChange={formik.handleChange}
                  required
                  size="medium"
                  value={formik.values.recipient_email}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Invite
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
