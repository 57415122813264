/* eslint-disable no-unused-vars */
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Link,
  Stack,
  TextField,
  useTheme
} from '@mui/material'
import { CallMade } from '@mui/icons-material'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { useCreateStrategy, useDeleteStrategy, useGetStrategies } from 'api/strategies'
import { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import DeleteConfirmButton from 'components/UI/DeleteConfirmButton'
import ErrorBoundaryWithFallback from 'components/ErrorBoundaryWithFallback'
import FormDialog from 'components/UI/FormDialog'
import RevenueRiskChart from 'charts/RevenueRiskChart'
import RiskResilienceSelectorHeader from 'components/RiskResilienceSelectorHeader'
import Widget from 'components/UI/Widget'
import routes from 'navigation/routes'
import sortBy from 'lodash/sortBy'

const Filters = ({
  isLoading,
  operation,
  selectedStrategies,
  setSelectedStrategies,
  strategies,
  strategyColours
}) => {
  const deleteStrategyMutation = useDeleteStrategy(operation)
  const theme = useTheme()

  const handleChange = e => {
    if (e.currentTarget.checked) {
      setSelectedStrategies([...selectedStrategies, Number(e.target.value)])
    } else {
      const newArr = selectedStrategies.filter(item => item !== Number(e.target.value))
      setSelectedStrategies(newArr)
    }
  }

  if (isLoading) return <CircularProgress color="primary" />

  return (
    <FormControl component="fieldset" fullWidth>
      <FormGroup
        sx={{ '.MuiFormControlLabel-root, .MuiFormControlLabel-label': { width: '100%' } }}
      >
        {[{ id: operation.id, name: 'Baseline' }, ...sortBy(strategies, ['label'])].map(
          strategy => {
            const isBaseline = strategy.name === 'Baseline'
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBaseline || selectedStrategies.includes(strategy.id)}
                    disabled={isBaseline}
                    onChange={handleChange}
                    style={{
                      color: isBaseline
                        ? theme.palette.primary.main
                        : strategyColours?.find(x => x.strategy_id === strategy.id)?.colour ||
                          'grey'
                    }}
                    value={strategy.id}
                  />
                }
                key={isBaseline ? '<baseline>' : strategy.id}
                label={
                  <Stack alignItems="center" direction="row">
                    <Box sx={{ flexGrow: 1 }}>{strategy.name}</Box>
                    {!isBaseline && (
                      <DeleteConfirmButton
                        btnSize="small"
                        deleteMutation={deleteStrategyMutation}
                        mutationPayload={strategy}
                        noMargin
                        targetLabel="strategy"
                        targetName={strategy.name}
                      />
                    )}
                    <Link
                      component={RouterLink}
                      to={
                        isBaseline
                          ? generatePath(routes.data)
                          : generatePath(routes.strategy, {
                              opId: operation.id,
                              opType: operation.opType,
                              strategyId: strategy.id
                            })
                      }
                    >
                      <CallMade
                        sx={{
                          '&:hover': { color: '#000' },
                          color: 'primary',
                          cursor: 'pointer'
                        }}
                      />
                    </Link>
                  </Stack>
                }
                name="strategy_id"
              />
            )
          }
        )}
      </FormGroup>
    </FormControl>
  )
}

const ResilienceCharts = ({ climateScenario, endDate, operation, startDate }) => {
  const { data: strategies, isLoading: isStrategyLoading } = useGetStrategies(operation, {
    enabled: !!operation
  })
  const createStrategyMutation = useCreateStrategy(operation)

  const [formErrors, setFormErrors] = useState({})
  const [strategyColours, setStrategyColours] = useState([])
  const [selectedStrategies, setSelectedStrategies] = useState([])
  useEffect(() => {
    setSelectedStrategies(strategies ? strategies.map(s => s.id) : [])
  }, [strategies])

  useEffect(() => {
    const colours = ['#CC33B2', '#E19C42', '#edc948', '#0E1CB6', '#A8E161', '#4e79b7', '#131112']
    setStrategyColours(colours)
    // setStrategyColours(
    //   data?.metadata?.strategy_id?.map((strategy, idx) => ({
    //     colour: colours[idx < colours.length ? idx : colours.length],
    //     strategy_id: strategy.val
    //   })) || []
    // )
  }, [setStrategyColours])

  return !operation ? (
    <LinearProgress color="primary" />
  ) : (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item lg={8} md={8} sm={7} xl={7} xs={12}>
        {/* {data?.data && (
          <Widget title="Revenue-Spend Resilience">
            <RevenueRiskChart
              data={data?.data}
              endDate={endDate}
              filters={{ strategy_id: selectedStrategies }}
              metadata={data?.metadata}
              showDownloadLink={true}
              startDate={startDate}
              strategyCol="strategy_id"
              strategyColours={strategyColours}
            />
          </Widget>
        )} */}
      </Grid>
      <Grid item lg={3} md={4} sm={5} xs={12}>
        <Widget title="Strategies">
          {/* <Filters
            isLoading={isStrategyLoading}
            metadata={data?.metadata}
            operation={operation}
            selectedStrategies={selectedStrategies}
            setSelectedStrategies={setSelectedStrategies}
            strategies={strategies}
            strategyColours={strategyColours}
          /> */}
          <Box sx={{ mt: 1, textAlign: 'center' }}>
            <FormDialog
              btnLabel="Create Strategy"
              fixOverflowY={true}
              mutation={createStrategyMutation}
              setFormErrors={setFormErrors}
            >
              <TextField
                autoFocus
                error={!!formErrors.name}
                fullWidth
                helperText={formErrors.name || null}
                label="Strategy Name"
                name="name"
                required
                size="medium"
              />
            </FormDialog>
          </Box>
        </Widget>
      </Grid>
    </Grid>
  )
}

const ResiliencePageWithData = () => {
  return (
    <RiskResilienceSelectorHeader isExperimental>
      <ResilienceCharts />
    </RiskResilienceSelectorHeader>
  )
}

export const ResiliencePage = () => {
  return (
    <ErrorBoundaryWithFallback fullPageError>
      <ResiliencePageWithData />
    </ErrorBoundaryWithFallback>
  )
}
