/* eslint-disable no-unused-vars */
import { Grid, TableCell, TableRow, TextField, Typography } from '@mui/material'
import {
  useAddExistingVendor,
  useAddNewVendor,
  useDeleteSupply,
  useMutateSupply
} from '../api/supply'
import { useEffect, useState, useMemo } from 'react'
import { useGetVendors } from '../api/vendors'
import { useTranslation } from 'react-i18next'
import AutosaveInput from './UI/AutoSaveInput'
import ComboSelect from './UI/ComboSelect'
import DeleteButton from './UI/DeleteButton'
import Fieldset from './UI/Fieldset'
import FormDialog from './UI/FormDialog'
import PropTypes from 'prop-types'
import SectorCombo from './SectorCombo'
import TableList from './UI/TableList'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { AnimatePresence } from 'framer-motion'
import { format } from 'date-fns'
import {
  LineChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Line,
  CartesianGrid,
  Tooltip
} from 'recharts'

const Row = ({ isSelected, setSelected, supply, vendorFacility }) => {
  const updateSupply = useMutateSupply(supply)
  const deleteMutation = useDeleteSupply(supply)
  const [isOrderTableDisplayed, setIsOrderTableDisplayed] = useState(false)
  const { t } = useTranslation()

  const orderData = useMemo(() => {
    if (supply?.orders_forecast?.length > 0) {
      return supply.orders_forecast.map(order => {
        return {
          date: format(new Date(order.order_date), 'MMM yyy'),
          quantity: order.quantity,
          quantity_f: order.is_forecast ? order.quantity : undefined,
          quantity_r: order.is_forecast ? undefined : order.quantity
        }
      })
    }
  }, [supply])

  return (
    <>
      <TableRow
        key={vendorFacility.id}
        onClick={() => setSelected(vendorFacility)}
        selected={isSelected}
      >
        <TableCell component="th" size="medium">
          {vendorFacility.name}
        </TableCell>

        <TableCell align="right" size="small">
          <AutosaveInput
            isNumber
            mutation={updateSupply}
            name="quantity"
            sx={{ maxWidth: '4em', minWidth: '2em' }}
            value={supply.quantity || 0}
            variant="standard"
          />
        </TableCell>
        <TableCell align="right" size="small">
          <AutosaveInput
            fullWidth
            isNumber
            mutation={updateSupply}
            name="max_capacity"
            sx={{ maxWidth: '4em', minWidth: '2em' }}
            value={supply.max_capacity || 0}
            variant="standard"
          />
        </TableCell>
        <TableCell>
          <DeleteButton
            deleteMutation={deleteMutation}
            label="Remove"
            mutationParams={[supply.id]}
          />
        </TableCell>
      </TableRow>
      {supply?.orders_forecast?.length > 0 && (
        <>
          <TableRow>
            <TableCell colSpan={4}>
              <ResponsiveContainer height={150} width="100%">
                <LineChart data={orderData} style={{ marginLeft: -15 }}>
                  <Line
                    animationDuration={500}
                    dataKey="quantity_r"
                    dot={{ r: 1 }}
                    name="Quantity"
                    stroke="rgba(3,23,58,1)"
                    type="monotone"
                  />
                  <Line
                    animationBegin={500}
                    dataKey="quantity_f"
                    dot={{ r: 1 }}
                    name="Quantity (forecast)"
                    stroke="#560212"
                    strokeDasharray="2 1"
                    type="monotone"
                  />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="date" style={{ fontSize: '10px' }} />
                  <YAxis dataKey="quantity" style={{ fontSize: '10px' }} tick={{ dx: -10 }} />
                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </TableCell>
          </TableRow>
          <TableRow onClick={() => setIsOrderTableDisplayed(!isOrderTableDisplayed)}>
            <TableCell colSpan={3} sx={{ color: 'grey', fontSize: '12px' }}>
              {isOrderTableDisplayed ? t('hide_order_history') : t('show_order_history')}
            </TableCell>
            <TableCell>{isOrderTableDisplayed ? <ExpandLess /> : <ExpandMore />}</TableCell>
          </TableRow>
          {isOrderTableDisplayed && (
            <AnimatePresence>
              <TableRow>
                <TableCell colSpan={2} sx={{ color: 'grey', fontSize: '12px' }}>
                  {t('order_date')}
                </TableCell>
                <TableCell colSpan={2} sx={{ color: 'grey', fontSize: '12px' }}>
                  {t('quantity')}
                </TableCell>
              </TableRow>
              {supply.orders_forecast.map((order, idx) => (
                <TableRow key={`order.${order.id}-${idx}`}>
                  <TableCell colSpan={2} sx={{ color: order.is_forecast ? '#560212' : 'initial' }}>
                    {format(new Date(order.order_date), 'MMM yyy')}
                  </TableCell>
                  <TableCell colSpan={2} sx={{ color: order.is_forecast ? '#560212' : 'initial' }}>
                    {order.quantity}
                  </TableCell>
                </TableRow>
              ))}
            </AnimatePresence>
          )}
        </>
      )}
    </>
  )
}

export const InputVendors = ({
  clientFacility,
  input,
  selectedVendorFacility,
  setSelectedVendorFacility
}) => {
  const { t } = useTranslation()
  const addMutation = useAddExistingVendor(input)
  const createMutation = useAddNewVendor(input)
  const [formErrors, setFormErrors] = useState({})
  const [selected, setSelected] = useState(null)
  const { data: vendors, isLoading } = useGetVendors(clientFacility)

  const onSuccess = ({ data }) => {
    setSelected(data.product.facility)
  }

  useEffect(() => {
    setSelectedVendorFacility(selected)
  }, [setSelectedVendorFacility, selected])

  // useEffect(() => {
  //   console.log(selected, selectedVendorFacility)
  // }, [selectedVendorFacility, selected])

  const excludeVendorIds = input?.supplies.map(s => s.product.facility.id) || []
  const vendorMenuItems = vendors
    ?.filter(v => !excludeVendorIds.includes(v.vendor_company.facility.id))
    .map(v => ({ label: v.name, value: v.id }))

  return (
    <Fieldset>
      {!!input.supplies?.length && (
        <>
          <Typography variant="h5">{t('input_vendors')}</Typography>
          <TableList headers={[null, t('quantity'), t('max_capacity'), null]}>
            {input.supplies.map(supply => (
              <Row
                input={input}
                isSelected={supply.product.facility.id === selected?.id}
                key={supply.product.facility.id}
                setSelected={setSelected}
                supply={supply}
                vendorFacility={supply.product.facility}
              />
            ))}
          </TableList>
        </>
      )}
      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        {!!vendorMenuItems?.length && (
          <Grid item xs={6}>
            <FormDialog
              btnLabel={t('assign_vendor')}
              fixOverflowY={true}
              mutation={addMutation}
              onSuccess={onSuccess}
              setFormErrors={setFormErrors}
            >
              <Grid alignItems="flex-end" container spacing={2}>
                <Grid item xs={12}>
                  <ComboSelect
                    autoFocus
                    error={formErrors.vendor_id}
                    isLoading={isLoading}
                    items={vendorMenuItems}
                    name="vendor_id"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    defaultValue={100}
                    error={!!formErrors.quantity}
                    fullWidth
                    helperText={formErrors.quantity || null}
                    label={t('quantity')}
                    name="quantity"
                    required
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    defaultValue={100}
                    error={!!formErrors.max_capacity}
                    fullWidth
                    helperText={formErrors.max_capacity || null}
                    label={t('max_capacity')}
                    name="max_capacity"
                    required
                    size="small"
                  />
                </Grid>
              </Grid>
            </FormDialog>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormDialog
            btnLabel={t('add_new_vendor')}
            fixOverflowY={true}
            mutation={createMutation}
            onSuccess={onSuccess}
            setFormErrors={setFormErrors}
          >
            <Grid alignItems="flex-end" container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  error={!!formErrors.name}
                  fullWidth
                  helperText={formErrors.name || null}
                  label={t('vendor_name')}
                  name="vendor.name"
                  required
                  size="medium"
                />
              </Grid>
              <Grid item xs={12}>
                <SectorCombo
                  error={formErrors.sector_code}
                  name="vendor.sector_code"
                  sector={input.sector}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={100}
                  error={!!formErrors.quantity}
                  fullWidth
                  helperText={formErrors.quantity || null}
                  label={t('quantity')}
                  name="quantity"
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={100}
                  error={!!formErrors.max_capacity}
                  fullWidth
                  helperText={formErrors.max_capacity || null}
                  label={t('max_capacity')}
                  name="max_capacity"
                  required
                  size="small"
                />
              </Grid>
            </Grid>
          </FormDialog>
        </Grid>
      </Grid>
    </Fieldset>
  )
}

InputVendors.propTypes = {
  clientFacility: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  selectedVendorFacility: PropTypes.object,
  setSelectedVendorFacility: PropTypes.func.isRequired
}
