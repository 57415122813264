import {
  Alert,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { NumericFormat } from 'react-number-format'
import {
  opMetadata,
  opTypes,
  useGetOperation,
  useMutateFinancial,
  useMutateFixAsset,
  useMutateOperation
} from '../api/operations'
import { useTranslation } from 'react-i18next'
import Fieldset from './UI/Fieldset'
import OperationDetails from './OperationDetails'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import SectorCombo from './SectorCombo'
import ToggleContent from './UI/ToggleContent'
import debounce from 'lodash/debounce'

const COLOURS = [
  '#004c6d',
  '#006083',
  '#007498',
  '#008aac',
  '#00a0c0',
  '#00b6d2',
  '#00cde2',
  '#00e4f2',
  '#00fcff'
]

export default function AssetDetails({ baselineOp, cachedOp, selectItem }) {
  const { t } = useTranslation()
  const { data: asset, isLoading } = useGetOperation(cachedOp)
  const finMutation = useMutateFinancial(asset)
  const mutation = useMutateOperation(asset)
  const fixSharesMutation = useMutateFixAsset(asset)
  const isMount = useRef(true)

  const currYear = new Date().getFullYear()

  const [year, setYear] = useState(
    asset?.financials[0]
      ? [asset?.financials[0].year, asset?.financials[0].is_partial]
      : [currYear, true]
  )
  const [spend, setSpend] = useState(
    asset?.financials.find(f => f.financial_type === 'SPEND')?.amount
  )
  const [revenue, setRevenue] = useState(
    asset?.financials.find(f => f.financial_type === 'REVENUE')?.amount
  )
  useEffect(() => {
    setSpend(asset?.financials.find(f => f.financial_type === 'SPEND')?.amount)
    setRevenue(asset?.financials.find(f => f.financial_type === 'REVENUE')?.amount)
  }, [asset])

  /* eslint react-hooks/exhaustive-deps: "off" */
  const debouncedMutate = useCallback(
    debounce(payload => {
      // console.log('finMutation.mutate', payload.financial_type)
      finMutation.mutate(payload)
    }, 1000),
    []
  )

  const updateFinancials = (financialType, amount) => {
    debouncedMutate({
      amount,
      financial_type: financialType,
      is_partial: year ? year[1] : null,
      year: year ? year[0] : null
    })
  }

  // useEffect(() => {
  //   if (isMount.current) return
  //   updateFinancials('SPEND', spend)
  // }, [spend])

  // useEffect(() => {
  //   if (isMount.current) return
  //   updateFinancials('REVENUE', revenue)
  // }, [revenue])

  useEffect(() => {
    if (isMount.current) return
    const yearInfo = { is_partial: year ? year[1] : null, year: year ? year[0] : null }
    finMutation
      .mutateAsync({
        amount: spend,
        financial_type: 'SPEND',
        ...yearInfo
      })
      .then(
        finMutation.mutateAsync({
          amount: revenue,
          financial_type: 'REVENUE',
          ...yearInfo
        })
      )
  }, [year])

  // Must run *after* the other useEffect:
  useEffect(() => {
    isMount.current = false
  }, [])

  const grossProfit = revenue !== undefined && spend !== undefined ? revenue - spend : undefined

  const baselineRevenue = baselineOp?.financials?.find(f => f.financial_type === 'REVENUE')?.amount
  const baselineSpend = baselineOp?.financials?.find(f => f.financial_type === 'SPEND')?.amount
  const baselineGrossProfit =
    baselineRevenue !== undefined && baselineSpend !== undefined
      ? baselineRevenue - baselineSpend
      : undefined

  const spendShares = asset.facility_shares.map(({ name, spend }) => ({
    name: name || `<${opMetadata[opTypes.FACILITY].placeholder}>` + ' Spend',
    value: spend
  }))
  const revenueShares = asset.facility_shares.map(({ name, revenue }) => ({
    name: name || `<${opMetadata[opTypes.FACILITY].placeholder}>` + ' Revenue',
    value: revenue
  }))

  const [showSharesWarning, setShowSharesWarning] = useState(false)

  useEffect(() => {
    setShowSharesWarning(asset.facility_shares_warning)
  }, [asset])

  return (
    <OperationDetails
      createBtnProps={{
        opType: opTypes.FACILITY
      }}
      data={asset}
      isLoading={isLoading}
      mutation={mutation}
      selectItem={selectItem}
    >
      <Fieldset>
        <SectorCombo
          baseline={baselineOp?.sector}
          mutation={mutation}
          sector={asset.sector}
          withToggle
        />
      </Fieldset>
      <Fieldset dividerAfter>
        <ToggleContent
          closed={
            !!grossProfit && (
              <>
                {year} {t('revenue_spend')}:{' '}
                <Typography component="span" sx={{ color: '#111' }} variant="h5">
                  €&nbsp;{grossProfit?.toLocaleString('en')}
                </Typography>
              </>
            )
          }
          color={
            baselineOp?.financials !== undefined && baselineGrossProfit !== grossProfit
              ? 'red'
              : null
          }
          startOpen={grossProfit === undefined}
          title="Financials"
        >
          <Grid alignItems="flex-end" container spacing={1} sx={{ px: 1 }}>
            <Grid item lg={6} sm={7} xl>
              <FormControl>
                <Select
                  autoWidth
                  onChange={e => {
                    setYear(e.target.value.split('+'))
                  }}
                  value={year?.join('+')}
                  variant="standard"
                >
                  <MenuItem value={`${currYear - 1}+true`}>{currYear - 1}</MenuItem>
                  <MenuItem value={`${currYear}+true`}>
                    {currYear} ({t('to_date')})
                  </MenuItem>
                  <MenuItem value={`${currYear}+false`}>
                    {currYear} ({t('projected')})
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} sm={5} xl>
              <NumericFormat
                customInput={TextField}
                disabled
                label={t('gross_profit')}
                placeholder="n/a"
                prefix={'€'}
                size="medium"
                sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                thousandSeparator=","
                type="text"
                value={grossProfit || ''}
                variant="standard"
              />
            </Grid>
            <Grid container item sm={12} spacing={1} xl={6}>
              <Grid item sm={6}>
                <NumericFormat
                  customInput={TextField}
                  label={t('revenue')}
                  name="revenue"
                  onValueChange={({ value: v }) => {
                    setRevenue(v)
                    updateFinancials('REVENUE', v)
                  }}
                  prefix={'€'}
                  size="medium"
                  sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                  thousandSeparator=","
                  type="text"
                  value={revenue || ''}
                  variant="standard"
                />
              </Grid>
              <Grid item sm={6}>
                <NumericFormat
                  customInput={TextField}
                  label={t('spend')}
                  name="spend"
                  onValueChange={({ value: v }) => {
                    setSpend(v)
                    updateFinancials('SPEND', v)
                  }}
                  prefix={'€'}
                  size="medium"
                  sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                  thousandSeparator=","
                  type="text"
                  value={spend || ''}
                  variant="standard"
                />
              </Grid>
            </Grid>
            {revenueShares.length > 0 && (
              <Grid item xs={12}>
                {showSharesWarning && (
                  <Alert
                    action={
                      <Button
                        color="inherit"
                        onClick={() => {
                          console.log('fix')
                          setShowSharesWarning(false)
                          fixSharesMutation.mutate()
                        }}
                        size="small"
                        variant="outlined"
                      >
                        {t('fix').toUpperCase()}
                      </Button>
                    }
                    severity="warning"
                    variant="outlined"
                  >
                    {t('facility_shares_warning')}
                  </Alert>
                )}
                <ResponsiveContainer aspect={2} width="98%">
                  <PieChart>
                    <Pie
                      animationDuration={600}
                      cy="100%"
                      data={revenueShares}
                      dataKey="value"
                      endAngle={0}
                      innerRadius={40}
                      outerRadius={70}
                      startAngle={180}
                    >
                      {revenueShares.map((_, index) => (
                        <Cell fill={COLOURS[index % COLOURS.length]} key={index} />
                      ))}
                    </Pie>
                    <Pie
                      animationDuration={800}
                      cy="100%"
                      data={spendShares}
                      dataKey="value"
                      endAngle={0}
                      innerRadius={70}
                      isAnimationActive={true}
                      outerRadius={99}
                      startAngle={180}
                    >
                      {spendShares.map((_, index) => (
                        <Cell fill={COLOURS[index % COLOURS.length]} key={index} />
                      ))}
                    </Pie>
                    <Tooltip formatter={value => `${(value * 100).toFixed(1)}%`} />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            )}
          </Grid>
        </ToggleContent>
      </Fieldset>
    </OperationDetails>
  )
}
