import { CustomContainerWithErrorBoundary } from 'layout/CustomContainer'
import CMSList from 'components/CMS/CMSList'

export const CMSListPage = () => {
  return (
    <CustomContainerWithErrorBoundary noBorder>
      <CMSList />
    </CustomContainerWithErrorBoundary>
  )
}
