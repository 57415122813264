import { Box, Button, Card, CardActions, CardContent, TextField } from '@mui/material'
import { LoginSignupTabs } from '../components/LoginSignupTabs'
import { Navigate, generatePath, useLocation } from 'react-router-dom'
import { SplitLayout } from '../components/UI/SplitLayout'
import { loginUser, useAuthDispatch, useAuthState } from '../Context'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import QueryErrors from 'components/UI/QueryErrors'
import routes from 'navigation/routes'

export const LoginPage = () => {
  const { t } = useTranslation()
  const dispatch = useAuthDispatch()
  const { error, isLoading, isLoggedIn } = useAuthState()
  const loginDisabled = process.env.REACT_APP_TENKO_DISABLE_LOGIN
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: async payload => {
      await loginUser(dispatch, payload)
      // if (!response.user) return
      // props.history.push('/dashboard') // navigate to dashboard on success
    }
  })

  const { state } = useLocation()
  if (isLoggedIn) {
    return <Navigate to={state?.to || generatePath(routes.dashboard)} />
  }

  return (
    <Box>
      <SplitLayout>
        {(!loginDisabled || loginDisabled === '0') && (
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <LoginSignupTabs tab={0} />
            <Card sx={{ borderRadius: '0 0 8px 8px' }}>
              <CardContent
                sx={{
                  '.MuiTextField-root': {
                    display: 'block',
                    my: 1.5
                  },
                  minWidth: '20vw',
                  paddingBottom: 0,
                  paddingTop: 2,
                  px: 2
                }}
              >
                <QueryErrors error={error} />
                <TextField
                  autoComplete="username"
                  fullWidth
                  label={t('email')}
                  name="email"
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                  variant="outlined"
                />

                <TextField
                  autoComplete="current-password"
                  fullWidth
                  label={t('password')}
                  name="password"
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                  variant="outlined"
                />
              </CardContent>

              <CardActions sx={{ justifyContent: 'center' }}>
                <Button disabled={formik.isSubmitting || isLoading} type="submit">
                  {t('log_in')}
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </SplitLayout>
    </Box>
  )
}
