import { Link } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ReportViewButton = ({ extraParams, showButton }) => {
  const { t } = useTranslation()
  return (
    <>
      {showButton && (
        <Link
          href={`?printView=true${extraParams ? `&${extraParams}` : ''}`}
          sx={{ alignSelf: 'flex-end', cursor: 'pointer', mt: 2 }}
          target="_blank"
          underline="hover"
        >
          {t('report_view')}
        </Link>
      )}
    </>
  )
}

export default ReportViewButton
