import { FormControl, FormHelperText, useTheme } from '@mui/material'
import { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'

const ComboSelect = ({
  children,
  error,
  isLoading,
  items,
  mutation,
  name,
  value: initialValue,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  const muiTheme = useTheme()

  const handleChange = option => {
    setValue(option)
    if (mutation) mutation.mutate({ [name]: option.value })
  }

  return (
    <FormControl fullWidth>
      <ReactSelect
        classNamePrefix="react-select"
        color="red"
        isLoading={isLoading}
        menuPosition="fixed"
        name={name}
        onChange={handleChange}
        options={items}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: muiTheme.palette.primary.main,
            primary25: '#78ADDD'
          }
        })}
        value={items.find(i => i.value === value)}
        {...props}
      >
        {children}
      </ReactSelect>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default ComboSelect

ComboSelect.propTypes = {
  items: PropTypes.array.isRequired,
  mutation: PropTypes.object,
  name: PropTypes.string.isRequired
}
