/* eslint-disable no-unused-vars */
import { Box, LinearProgress, Typography } from '@mui/material'
import { ImageErrorFallback, PostImage } from './CMSCard'
import { Markup } from 'interweave'
import { styled } from '@mui/material/styles'
import { useGetCMSPostByID } from 'api/cms'
import { useLocation, useParams, Link as RouterLink } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ErrorBoundaryWithFallback from 'components/ErrorBoundaryWithFallback'
import { useTranslation } from 'react-i18next'

const ContentContainer = styled(Box)(({ theme }) => ({
  div: {
    blockquote: {
      borderLeft: '2px solid #888',
      paddingLeft: '1em'
    },
    display: 'flex',
    figure: {
      img: {
        maxWidth: 'inherit',
        objectFit: 'contain'
      },
      maxWidth: '100%',
      textAlign: 'center'
    },
    flexDirection: 'column',
    gap: 3,
    h1: {
      fontSize: '5em'
    },
    h2: {
      fontSize: '4em',
      fontWeight: '300'
    },
    'h2, h3, h4, h5, h6': {
      fontWeight: '200',
      lineHeight: '1.5em',

      marginBottom: '.2em',
      // fontSize: '52px',
      // margin: 'auto',
      marginTop: '.5em',
      width: '650px',
      [theme.breakpoints.down('md')]: {
        width: '400px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '200px'
      }
    },
    h3: {
      fontSize: '2.6em'
    },
    h4: {
      fontSize: '2.3em'
    },
    h5: {
      fontSize: '2em'
    },
    h6: {
      fontSize: '1.5em'
    },
    img: {
      height: 'auto !important'
    },
    'p, ol, ul': {
      a: { '&:hover': { textDecorationStyle: 'dashed' }, color: 'inherit' },
      fontFamily: 'system-ui',
      fontSize: '1.2em',
      lineHeight: '1.5m',
      // margin: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '500px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '300px'
      },
      marginBottom: '.3em',
      marginTop: '.3em',
      width: '800px'
    }
  }
}))

export const RenderPost = ({ post }) => {
  return (
    <Box>
      <Typography sx={{ margin: 'auto', mt: '20px' }} variant="h1">
        <Markup content={post?.title.rendered} tagName="div" />
      </Typography>
      <ErrorBoundaryWithFallback customErrorFallback={ImageErrorFallback}>
        <Box sx={{ display: 'block', margin: 'auto', my: 1 }}>
          <PostImage post={post} />
        </Box>
      </ErrorBoundaryWithFallback>
      <ContentContainer>
        <Markup content={post?.content.rendered} tagName="div" />
      </ContentContainer>
    </Box>
  )
}

const CMSItem = () => {
  const { slug } = useParams()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { data: post, isLoading } = useGetCMSPostByID(slug.split('-')[0])

  if (isLoading) return <LinearProgress color="primary" />

  return (
    <Box>
      <RouterLink
        style={{ color: 'initial', display: 'flex', textDecoration: 'none' }}
        to={`/${pathname.split('/')[1]}`}
      >
        <ArrowBackIcon sx={{ height: '0.8em', mr: 1 }} /> {t('back')}
      </RouterLink>
      {post ? (
        <RenderPost post={post} />
      ) : (
        <Typography sx={{ fontSize: 20, fontWeight: 'bold', mt: 3, textAlign: 'center' }}>
          {t('post_not_found')}
        </Typography>
      )}
    </Box>
  )
}

export default CMSItem
