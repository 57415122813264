import { useGetCMSCategories } from 'api/cms'
import { useMemo } from 'react'

export const useGetCategoryId = ({ pathname }) => {
  const { data: categories, isLoading: isLoadingCategory } = useGetCMSCategories()

  const selectedCategory = useMemo(() => {
    if (pathname.charAt(0) === '/') {
      return pathname.substring(1).replaceAll(/\/+$/g, '')
    } else {
      return pathname?.replaceAll(/\/+$/g, '')
    }
  }, [pathname])

  const categoryId = useMemo(() => {
    if (selectedCategory) {
      return categories?.find(c => c.slug === selectedCategory)?.id
    } else {
      return undefined
    }
  }, [categories, selectedCategory])

  return { categoryId, isLoadingCategory, selectedCategory }
}
