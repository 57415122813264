import { AppContext } from '../App'
import { loginUser, useAuthDispatch } from '../Context'
import { useContext } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { wrapCompanies, wrapPortfolios } from '../api/operations'
import axios from 'axios'

export const useGetCurrentUser = (options = {}) => {
  const endpoint = '/user/me'
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const getUserOperationsQuery = 'useGetUserOperations'

export const useGetUserOperations = (user, options = {}) => {
  return useQuery(
    getUserOperationsQuery,
    () =>
      Promise.all([
        axios(`/user/${user.id}/portfolio/`).then(({ data }) => wrapPortfolios(data)),
        axios(`/user/${user.id}/company/`).then(({ data }) => wrapCompanies(data))
      ]).then(data => data.flat(1)),
    {
      notifyOnChangeProps: ['data', 'error'],
      // select: data => data.flat(1),
      structuralSharing: false,
      ...options
    }
  )
}

export const useSignUp = options => {
  const dispatch = useAuthDispatch()
  return useMutation(
    payload =>
      axios
        .post('/user/signup', payload)
        .then(() => loginUser(dispatch, { email: payload.email, password: payload.password })),
    options
  )
}

export const useUpdateUserMutation = options => {
  const queryClient = useQueryClient()
  const { user } = useContext(AppContext)
  const endpoint = `/user/${user?.id}`
  return useMutation(payload => axios.put(endpoint, payload), {
    onSettled: () => queryClient.invalidateQueries(endpoint),
    ...options
  })
}

export const useGetSettings = (user, options = {}) => {
  const endpoint = `/user/${user?.id}/settings/`

  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    ...options,
    // onSuccess: data => (options.onSuccess ? options.onSuccess(onSucess(data)) : onSucess(data)),
    staleTime: Infinity
  })
}

export const useUpdateSettingsMutation = (options = {}) => {
  const queryClient = useQueryClient()
  const { user } = useContext(AppContext)
  const endpoint = `/user/${user?.id}/settings/`
  return useMutation(
    payload => {
      const keyVals = Object.entries(payload).map(([k, v]) => ({ key: k, value: String(v) }))
      queryClient.setQueryData(endpoint, oldData => [
        ...keyVals,
        ...oldData.filter(({ key }) => payload.key === undefined)
      ])
      return Promise.all(keyVals.map(kv => axios.post(endpoint, kv)))
    },
    {
      onSettled: () => queryClient.invalidateQueries(endpoint),
      ...options
    }
  )
}
