import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography
} from '@mui/material'
import { Markup } from 'interweave'
import { format } from 'date-fns'
import { useGetMediaDetail } from 'api/cms'
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ErrorBoundaryWithFallback from 'components/ErrorBoundaryWithFallback'
import { getLastChar } from 'utils/typography'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

export const ImageErrorFallback = ({ resetErrorBoundary }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '150px',
        minWidth: '300px'
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }}>{t('image_load_fail')}</Typography>
      </Box>
      <Button onClick={resetErrorBoundary} variant="outlined">
        {t('try_again')}
      </Button>
    </Box>
  )
}

export const PostImage = ({ cardMedia = false, post }) => {
  const { data: featuredMedia } = useGetMediaDetail(post.featured_media)

  if (cardMedia) {
    return (
      <CardMedia
        alt={featuredMedia?.alt_text}
        component="img"
        height={150}
        image={featuredMedia?.source_url}
        sx={{ maxWidth: '300px', minWidth: '300px' }}
      />
    )
  }

  return (
    <Box
      alt={featuredMedia?.alt_text}
      component="img"
      src={featuredMedia?.source_url}
      sx={{ display: 'block', margin: 'auto', maxWidth: '100%', my: 3 }}
    />
  )
}

const PostCardAction = ({ alignSelf = 'center', children, href }) => {
  return (
    <CardActions sx={{ alignSelf, p: 0 }}>
      <RouterLink
        style={{ alignItems: 'center', color: 'initial', display: 'flex', textDecoration: 'none' }}
        to={href}
      >
        {children}
      </RouterLink>
    </CardActions>
  )
}

const CMSCard = ({ categories, post }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const href = useMemo(() => {
    return `${pathname}${getLastChar(pathname) === '/' ? '' : '/'}${post.id}-${post.slug}`
  }, [pathname, post])

  const handleClickBadge = useCallback(
    category => {
      navigate({
        pathname,
        search: `?category=${category}`
      })
    },
    [navigate, pathname]
  )

  return (
    <Card
      sx={{
        '@media (max-width: 600px)': {
          flexDirection: 'column'
        },
        alignItems: 'center',
        display: 'flex',
        mb: 4,
        px: 2
      }}
      variant="outlined"
    >
      <PostCardAction href={href}>
        <ErrorBoundaryWithFallback customErrorFallback={ImageErrorFallback}>
          <PostImage cardMedia post={post} />
        </ErrorBoundaryWithFallback>
      </PostCardAction>
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <PostCardAction alignSelf="flex-start" href={href}>
          <Typography sx={{ ':hover': { textDecoration: 'underline' }, mb: '5px' }} variant="h2">
            <Markup content={post.title.rendered} />
          </Typography>
        </PostCardAction>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
          <Typography style={{ color: 'gray' }} sx={{ fontStyle: 'italic' }}>
            {format(new Date(post.date), 'MMMM d, yyyy')}
          </Typography>
          {categories?.map((c, idx) => (
            <Chip
              color="primary"
              key={idx}
              label={c}
              onClick={() => handleClickBadge(c)}
              size="small"
              sx={{ fontWeight: 'bold', opacity: 0.9 }}
              variant="outlined"
            />
          ))}
        </Box>
        <Markup content={post.excerpt.rendered} />
        <PostCardAction alignSelf="flex-end" href={href}>
          {t('read_more')} <ArrowForwardIcon sx={{ height: '0.8em', ml: 1 }} />
        </PostCardAction>
      </CardContent>
    </Card>
  )
}

export default CMSCard
