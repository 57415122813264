import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import { createContext, useContext, useMemo, useState } from 'react'
import { useUpdateSettingsMutation } from '../api/user'
import Hero from '../components/UI/Hero'
import QueryErrors from 'components/UI/QueryErrors'

const STEPS = ['Welcome', 'Role', 'Data']
const OnboardingContext = createContext()

const Content = ({ step }) => {
  const { financials, inputs, role, setRole, setters, suppliers } = useContext(OnboardingContext)

  const handleChange = ({ target: { checked } }, key) => setters[key](checked)

  switch (step) {
    case 0:
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h1">Welcome to Tenkō!</Typography>
          <Typography>Let&apos;s set up your account …</Typography>
        </Box>
      )

    case 1:
      return (
        <>
          <Typography variant="h1">You will be using Tenkō to…</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              name="role"
              onChange={({ target: { value } }) => setRole(value)}
              value={role}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Manage a single company"
                value="company"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Manage a portfolio of companies"
                value="portfolio"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Undecided"
                value="undecided"
              />
            </RadioGroup>
          </FormControl>
        </>
      )

    case 2:
      return (
        <>
          <Typography variant="h1">You can provide Tenkō with…</Typography>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={financials}
                    color="primary"
                    onChange={event => handleChange(event, 'financials')}
                  />
                }
                label="Data on revenue/spend of divisions within the company"
                name="financials"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputs}
                    color="primary"
                    onChange={event => handleChange(event, 'inputs')}
                  />
                }
                label="Information on quantity/type of inputs (raw materials, outsourced labour, components …) supplied by external vendors"
                name="inputs"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={suppliers}
                    color="primary"
                    onChange={event => handleChange(event, 'suppliers')}
                  />
                }
                label="Information on vendors/suppliers: company names, locations …"
                name="suppliers"
              />
            </FormGroup>
          </FormControl>
        </>
      )

    default:
      return null
  }
}

const OnboardingComplete = () => {
  const { financials, inputs, role, suppliers } = useContext(OnboardingContext)
  const mutation = useUpdateSettingsMutation()
  const [isSubmitted, setIsSubmitted] = useState(false)

  if (!isSubmitted) {
    console.log('saving settings')
    setIsSubmitted(true)
    mutation.mutate({
      are_financials_known: financials,
      are_inputs_known: inputs,
      are_suppliers_known: suppliers,
      is_onboarding_complete: true,
      is_portfolio_user: role !== 'company'
    })
  }

  if (mutation.isError) {
    console.log('query error:', mutation.isError, mutation.error)
    return <QueryErrors mutation={mutation} />
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

const OnboardingContextProvider = ({ children }) => {
  const [financials, setFinancials] = useState(false)
  const [inputs, setInputs] = useState(false)
  const [role, setRole] = useState('company')
  const [suppliers, setSuppliers] = useState(false)
  const setters = useMemo(
    () => ({
      financials: setFinancials,
      inputs: setInputs,
      suppliers: setSuppliers
    }),
    []
  )

  const onboardingContext = useMemo(
    () => ({
      financials,
      inputs,
      role,
      setRole,
      setters,
      suppliers
    }),
    [financials, inputs, role, setters, suppliers]
  )

  return (
    <OnboardingContext.Provider value={onboardingContext}>{children}</OnboardingContext.Provider>
  )
}

// exported components
export const OnboardingPage = () => {
  const [currentStep, setCurrentStep] = useState(0)

  const handleBack = () => {
    setCurrentStep(currentStep => currentStep - 1)
  }

  const handleNext = () => {
    setCurrentStep(currentStep => currentStep + 1)
  }

  return (
    <OnboardingContextProvider>
      <Hero>
        <Card sx={{ maxWidth: '800px', padding: 2, width: '70vw' }}>
          {currentStep === 3 ? (
            <OnboardingComplete />
          ) : (
            <>
              <Stepper activeStep={currentStep}>
                {STEPS.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <CardContent
                sx={{
                  '& p': {
                    '&:last-child': {
                      marginBottom: 0
                    },
                    marginBottom: 2
                  },
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: 4
                }}
              >
                <Content step={currentStep} />
              </CardContent>

              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 1
                }}
              >
                {currentStep !== 0 && <Button onClick={handleBack}>Back</Button>}

                <Button onClick={handleNext}>{currentStep < 2 ? 'Next' : 'Done!'}</Button>
              </CardActions>
            </>
          )}
        </Card>
      </Hero>
    </OnboardingContextProvider>
  )
}
