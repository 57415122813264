// import { AppContext } from '../App'
import { Box, CircularProgress, Grid } from '@mui/material'
import { CreateButton } from './UI/CreateButton'
import { InputVendors } from './InputVendors'
// import { VisibilityTwoTone } from '@mui/icons-material'
import { requiredNonNegativeInteger } from 'utils/validation'
// import { useAddUpdateSettingsMutation } from '../mutations/settings'
import {
  useCreateInput,
  useDeleteInput,
  useGetInput,
  useGetProductInputs,
  useMutateInput
} from 'api/inputs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccordionItem from './UI/AccordionItem'
import AutosaveInput from './UI/AutoSaveInput'
import Fieldset from './UI/Fieldset'
import FilterInput from './UI/FilterInput'
import PropTypes from 'prop-types'
import SectorCombo from './SectorCombo'

const InputDetails = ({
  facility,
  handleChange,
  hideCollapse,
  input: initialInput,
  openedInput,
  selectedVendorFacility,
  setSelectedVendorFacility
}) => {
  const { t } = useTranslation()
  const { data: input, isLoading } = useGetInput(initialInput, { initialData: initialInput })
  const updateMutation = useMutateInput(input)
  const deleteMutation = useDeleteInput(input)

  return (
    <AccordionItem
      deleteMutation={deleteMutation}
      details={input.sector?.eora_category}
      dimmed={!!openedInput}
      entityName={input.name}
      entityNamePlaceholder="<unnamed input>"
      handleChange={handleChange(input)}
      hideCollapse={hideCollapse}
      isExpanded={input?.id === openedInput?.id}
      updateMutation={updateMutation}
    >
      <Fieldset>
        <AutosaveInput
          fullWidth
          label={t('description')}
          multiline
          mutation={updateMutation}
          name="description"
          onClick={e => e.stopPropagation()}
          rows={3}
          size="small"
          sx={{ mb: 2 }}
          value={input.description}
          variant="outlined"
        />
        <SectorCombo
          disabled={isLoading}
          mutation={updateMutation}
          sector={input.sector}
          withToggle
        />
      </Fieldset>
      <Fieldset dividerAfter>
        <AutosaveInput
          disabled={isLoading}
          label={t('inventory_days')}
          mutation={updateMutation}
          name="inventory_days"
          schema={requiredNonNegativeInteger}
          size="small"
          value={input.inventory_days}
          variant="outlined"
        />
      </Fieldset>
      <InputVendors
        clientFacility={facility}
        input={input}
        selectedVendorFacility={selectedVendorFacility}
        setSelectedVendorFacility={setSelectedVendorFacility}
      />
    </AccordionItem>
  )
}

export const Inputs = ({
  facility,
  isWarehouse,
  product,
  selectedInput,
  selectedVendorFacility,
  setSelectedInput,
  setSelectedVendorFacility
}) => {
  const { t } = useTranslation()
  // const { setSettings, settings } = useContext(AppContext)
  // const addUpdateSettingsMutation = useAddUpdateSettingsMutation({
  //   onSuccess: settings => setSettings(settings)
  // })
  const createMutation = useCreateInput(product)
  const { data: inputs, isLoading } = useGetProductInputs(product, { enabled: !!product })

  // TODO: move that mess into FilterInput:
  const [filteredInputs, setFilteredInputs] = useState(inputs)
  const [filterReset, setFilterReset] = useState(false)
  // const [autoSelected, setAutoSelected] = useState(null)
  const autoSelected = false

  // useEffect(() => {
  //   if (filteredInputs?.length === 1) setAutoSelected(filteredInputs[0])
  //   else setAutoSelected(null)

  //   return () => setAutoSelected(null) // cleanup
  // }, [filteredInputs])

  useEffect(() => {
    if (selectedInput && !filteredInputs?.find(i => i.id === selectedInput?.id))
      setSelectedInput(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredInputs, setSelectedInput])

  useEffect(() => {
    if (selectedInput && !filteredInputs?.find(i => i.id === selectedInput?.id))
      setFilterReset(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInput, setFilterReset])

  useEffect(() => {
    if (!inputs?.find(i => i.id === selectedInput?.id)) setSelectedInput(null)
  }, [inputs, setSelectedInput, selectedInput])

  const handleChange = input => (_, isExpanded) => {
    setSelectedInput(isExpanded ? input : null)
  }

  // const addUpdateSettings = value => addUpdateSettingsMutation.mutate({ are_inputs_hidden: value })

  // if (settings.are_inputs_hidden) {
  //   return (
  //     <Box>
  //       <VisibilityTwoTone onClick={() => addUpdateSettings(false)} />
  //     </Box>
  //   )
  // }
  if (isLoading)
    return (
      <Box sx={{ backgroundColor: '#FFF', paddingTop: 3, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <Box sx={{ backgroundColor: '#FFF' }}>
      <Fieldset
        sx={{
          display: isWarehouse ? 'none' : 'initial',
          paddingTop: 1,
          px: 1,
          textAlign: 'center'
        }}
      >
        <Grid container spacing={1}>
          <Grid item sx={{ display: !inputs?.length ? 'none' : 'inherit' }} xs>
            <FilterInput
              filterReset={filterReset}
              items={inputs}
              setFilterReset={setFilterReset}
              setFilteredItems={setFilteredInputs}
            />
          </Grid>
          <Grid item xs>
            <CreateButton
              createMutation={createMutation}
              onSuccess={({ data }) => {
                setSelectedInput(data)
              }}
            >
              {t('add_input')}
            </CreateButton>
          </Grid>
        </Grid>
      </Fieldset>

      {filteredInputs?.map(input => (
        <InputDetails
          facility={facility}
          handleChange={handleChange}
          hideCollapse={filteredInputs?.length <= 1}
          input={input}
          key={input.id}
          openedInput={selectedInput || autoSelected}
          selectedVendorFacility={selectedVendorFacility}
          setSelectedVendorFacility={setSelectedVendorFacility}
        />
      ))}
    </Box>
  )
}

Inputs.propTypes = {
  facility: PropTypes.object.isRequired,
  selectedInput: PropTypes.object,
  selectedVendorFacility: PropTypes.object,
  setSelectedInput: PropTypes.func.isRequired,
  setSelectedVendorFacility: PropTypes.func.isRequired
}
