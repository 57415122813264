import * as yup from 'yup'

// exported constants

export const required = yup.mixed().required('Required.')

export const requiredString = yup.string().required('Required.')

export const requiredNumber = yup.number().moreThan(0, 'Required.').required('Required.')

export const requiredNonNegativeInteger = yup
  .number()
  .integer('Must be an integer.')
  .moreThan(-1, 'Must not be negative.')
  .required('Required.')
  .typeError('Must be a number.')

export const requiredPositiveInteger = yup
  .number()
  .integer('Must be an integer.')
  .moreThan(0, 'Must be positive.')
  .required('Required.')
  .typeError('Must be a number.')
