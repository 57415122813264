import {
  Checkbox,
  CircularProgress,
  Grid,
  // Slider,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Box
} from '@mui/material'
import { requiredNonNegativeInteger } from 'utils/validation'
import {
  useCreateInput,
  useCreateInputWithVendor,
  useDeleteInput,
  useGetInput,
  useGetProductInputs
  // useMutateProductInput
} from 'api/inputs'
import { useGetOperation, useMutateOperation } from '../api/operations'
import AutosaveInput from './UI/AutoSaveInput'
// import Address from '../components/Address'
// import ComboSelect from './UI/ComboSelect'
import { useTranslation } from 'react-i18next'
import DeleteButton from './UI/DeleteButton'
import Fieldset from './UI/Fieldset'
import FormDialog from './UI/FormDialog'
import OperationDetails from './OperationDetails'
import QueryErrors from './UI/QueryErrors'
import React, { useEffect, useState } from 'react'
import SectorCombo from './SectorCombo'
import TableList from './UI/TableList'
// import ToggleContent from './UI/ToggleContent'

const InputRow = ({ isSelected, product, productInput, setSelected }) => {
  const { data: input } = useGetInput(productInput, { initialData: productInput })
  const deleteMutation = useDeleteInput(input)
  // const updateMutation = useMutateProductInput(facility, product)

  return (
    <TableRow key={productInput.id} onClick={() => setSelected(productInput)} selected={isSelected}>
      <TableCell component="th" size="medium">
        {input.name || '<unnamed input>'}
      </TableCell>
      <TableCell>
        <DeleteButton deleteMutation={deleteMutation} />
      </TableCell>
      {deleteMutation.isError && (
        <TableCell>
          <QueryErrors mutation={deleteMutation} />
        </TableCell>
      )}
    </TableRow>
  )
}

const AddCreateProductInputForm = ({
  isLoading: isProductInputsLoading,
  product,
  productInputs,
  setSelected
}) => {
  const { t } = useTranslation()
  const createMutation = useCreateInput(product)
  const createWithVendorMutation = useCreateInputWithVendor(product)
  const [formErrors, setFormErrors] = useState({})
  const [isNewVendor, setIsNewVendor] = useState(true)
  // const { data: products, isLoading } = useGetProducts(facility)
  // const productIds = productInputs?.map(p => p.id)
  // const excludeProductIds = productInputs?.map(p => p.id) || []
  // const productMenuItems = products
  //   ?.filter(p => !excludeProductIds.includes(p.id))
  //   .map(p => ({ label: p.name || '<unnamed product>', value: p.id }))

  const onSuccess = ({ data }) => {
    setSelected(data)
  }

  return (
    <Grid container spacing={1} sx={{ marginTop: 1 }}>
      <Grid item xs={6}>
        <FormDialog
          btnLabel={t('add_new_input')}
          fixOverflowY={true}
          mutation={isNewVendor ? createWithVendorMutation : createMutation}
          onSuccess={onSuccess}
          setFormErrors={setFormErrors}
        >
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                autoFocus
                error={!!formErrors.name}
                fullWidth
                helperText={formErrors.name || null}
                label={t('input_name')}
                name="name"
                required
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                defaultValue={0}
                error={!!formErrors.inventory_days}
                fullWidth
                helperText={formErrors.inventory_days || null}
                label={t('inventory_days')}
                name="inventory_days"
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <SectorCombo
                error={formErrors.sector_code}
                name="sector_code"
                sector={product.sector}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <h3>
                <Checkbox
                  checked={isNewVendor}
                  onChange={event => setIsNewVendor(event.target.checked)}
                />{' '}
                {t('new_vendor')}
              </h3>
            </Grid>
            {isNewVendor && (
              <Grid item md={6} xs={12}>
                <TextField
                  autoFocus
                  error={!!formErrors.vendor?.name}
                  fullWidth
                  helperText={formErrors.vendor?.name || null}
                  label={t('vendor_name')}
                  name="vendor.name"
                  required
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </FormDialog>
      </Grid>
    </Grid>
  )
}

export default function ProductDetails(props) {
  const { t } = useTranslation()
  const { baselineOp, cachedOp, isWarehouse, selectItem, selectedInput, setSelectedInput } = props

  const { data: product, isLoading } = useGetOperation(cachedOp)
  const { data: productInputs, isLoading: isProductsLoading } = useGetProductInputs(product, {
    initialData: product.inputs
  })

  const mutation = useMutateOperation(product)
  // const mutationReloadAsset = useMutateOperation(product, true, true)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (selected) setSelectedInput(selected)
  }, [setSelectedInput, selected])

  useEffect(() => {
    setSelected(productInputs?.find(p => p.id === selectedInput?.id))
  }, [selectedInput, setSelected, productInputs])

  // select first input if the facility is a warehouse
  useEffect(() => {
    if (isWarehouse) {
      setSelected(productInputs?.[0])
    }
  }, [isWarehouse, productInputs])

  if (isLoading) return <CircularProgress />

  return (
    <Box>
      <OperationDetails
        data={product}
        isLoading={isLoading}
        mutation={mutation}
        selectItem={selectItem}
      >
        <Fieldset>
          <SectorCombo
            baseline={baselineOp?.sector}
            mutation={mutation}
            sector={product.sector}
            withToggle
          />
        </Fieldset>
        <Fieldset>
          <AutosaveInput
            disabled={isLoading}
            label={t('inventory_days')}
            mutation={mutation}
            name="inventory_days"
            schema={requiredNonNegativeInteger}
            size="small"
            value={product.inventory_days}
            variant="outlined"
          />
        </Fieldset>
        <Fieldset>
          {!!productInputs?.length && <Typography variant="h5">{t('inputs')}</Typography>}
          <TableList headers={[null, null]} isLoading={isProductsLoading}>
            {productInputs?.map(productInput => (
              <InputRow
                isSelected={productInput?.id === selected?.id}
                key={productInput.id}
                product={product}
                productInput={productInput}
                setSelected={setSelected}
              />
            ))}
          </TableList>
          {!isProductsLoading && (
            <AddCreateProductInputForm
              isLoading={isLoading}
              product={product}
              productInputs={productInputs}
              setSelected={setSelected}
            />
          )}
        </Fieldset>
      </OperationDetails>
    </Box>
  )
}
