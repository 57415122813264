import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import banner from 'assets/banner.jpeg'
import emailjs from 'emailjs-com'

export default function ContactForm({ sx = {}, children, isPage }) {
  const { t } = useTranslation()
  const [email, setEmail] = useState()
  const form = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const sendEmail = e => {
    e.preventDefault()
    setIsSubmitting(true)
    emailjs
      .sendForm('service_6kqhv23', 'template_78wcyla', form.current, 'user_I2dqlfBvv5NIwg4QvdGPi')
      .then(
        result => {
          setIsSubmitted(true)
          console.log(result.text)
        },
        error => {
          setIsSubmitting(false)
          setErrorMsg(error.text)
          console.log('submit error', error.text)
          console.log(errorMsg)
        }
      )
      .catch(error => {
        console.log('catch', error)
      })
  }

  return (
    <Container maxWidth="md" sx={sx}>
      {isPage && (
        <Box sx={{ position: 'relative' }}>
          <Box
            alt="tenko logo"
            component="img"
            src="/tenko_logo_white.png"
            sx={{ position: 'absolute', right: 10, top: 7, width: 30 }}
          />
          <Box
            alt="banner"
            component="img"
            src={banner}
            sx={{ borderRadius: '8px 8px 0 0', height: '150px', objectFit: 'cover', width: '100%' }}
          />
        </Box>
      )}
      <Box sx={isPage ? { px: 4 } : {}}>
        {children}
        {!!isSubmitted && (
          <>
            <Typography variant="h2">
              {t('submission_confirmation_1')} (<em>{email}</em>) {t('submission_confirmation_2')}
            </Typography>
            {isPage && (
              <Box>
                <Link component={RouterLink} to="/">
                  {t('back_to_homepage')}
                </Link>
              </Box>
            )}
          </>
        )}
        {!!errorMsg && (
          <Alert color="error" sx={{ mb: 3 }}>
            <AlertTitle>{t('submission_error')}</AlertTitle>
            {errorMsg}
          </Alert>
        )}
        {!isSubmitted && (
          <Box autoComplete="off" component="form" onSubmit={sendEmail} ref={form}>
            <Grid container spacing={isPage ? 3 : 1}>
              <Grid item md={isPage ? 12 : 6} xs={12}>
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label={t('name')}
                  name="from_name"
                  placeholder={t('name')}
                  required
                />
              </Grid>
              <Grid item md={isPage ? 12 : 6} xs={12}>
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label={t('email')}
                  name="from_email"
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  placeholder={t('email')}
                  required
                  type="email"
                />
              </Grid>
              {isPage && (
                <>
                  <Grid item md={6} xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      fullWidth
                      label={t('company')}
                      name="from_company"
                      placeholder={t('company')}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      fullWidth
                      label={t('job_title')}
                      name="from_job_title"
                      placeholder={t('job_title')}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  multiline
                  name="message"
                  placeholder={t('message')}
                  required
                  rows={10}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item sx={isPage ? {} : { textAlign: 'right' }} xs={12}>
                <Button
                  disabled={isSubmitting}
                  sx={isPage ? { mt: 2, width: '150px' } : {}}
                  type="submit"
                  variant="outlined"
                >
                  {t('send')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  )
}
