import { Box, Container, Typography } from '@mui/material'

import React, { createRef, useCallback, useEffect, useLayoutEffect, useState } from 'react'

const IllustrationScreen = ({ backgroundColor, children, imgUrl, subhead, title, tooltips }) => {
  const containerRef = createRef()
  const [imgRect, setImgRect] = useState()

  const updateContainerRect = useCallback(() => {
    if (!containerRef.current) return
    const _imgRect = containerRef.current.getBoundingClientRect()
    if (JSON.stringify(imgRect) !== JSON.stringify(_imgRect)) setImgRect(_imgRect)
  }, [containerRef, imgRect])

  useEffect(() => {
    updateContainerRect()
  }, [updateContainerRect])

  useLayoutEffect(() => {
    window.addEventListener('resize', updateContainerRect)
    return () => window.removeEventListener('resize', updateContainerRect)
  }, [updateContainerRect])

  const imgDimsRatio = 1076 / 1600 // height / width

  const getPosition = ({ left, top }) => {
    if (!imgRect || !imgRect.height || !imgRect.width) return { display: 'none' }

    const imgRectRatio = imgRect.height / imgRect.width
    if (imgRectRatio < imgDimsRatio) left = (left - 0.5) * (imgRectRatio / imgDimsRatio) + 0.5
    else top = (top - 0.5) * (imgDimsRatio / imgRectRatio) + 0.5

    return { left: `${(left * 100).toFixed(2)}%`, top: `${(top * 100).toFixed(2)}%` }
  }

  return (
    <>
      <Box
        sx={{
          '&:hover .tooltip, &:hover .small-tooltip, ': {
            opacity: 1
          },
          '.small-tooltip': {
            maxWidth: { md: '15vw', xs: '10vw' },
            px: 0.5,
            py: 0.1
          },
          '.tooltip': {
            border: '1px solid #3B7EE9',
            maxWidth: '20vw',
            px: 1,
            py: 0.2
          },
          '.tooltip, .small-tooltip': {
            backgroundColor: '#FEFEFE99',
            borderRadius: '2px',
            fontFamily: 'serif',
            fontSize: {
              md: '0.8rem',
              sm: '0.5rem',
              xs: '0.4rem'
            },

            opacity: 0.2,
            position: 'absolute'
          },
          alignContent: 'start',
          backgroundColor,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          minHeight: '100vh'
        }}
      >
        <Container maxWidth="md">
          {title && <Typography className="title">{title}</Typography>}
          {subhead && <Typography className="subhead">{subhead}</Typography>}
          {children}
        </Container>
        <Box sx={{ flex: 1, position: 'relative' }}>
          <Box
            component="img"
            ref={containerRef}
            src={imgUrl}
            sx={{
              height: '100%',
              objectFit: 'contain',
              position: 'absolute',
              width: '100%'
            }}
          />
          {tooltips.map(({ details, label, left, small, top }) => (
            <Box
              className={small ? 'small-tooltip' : 'tooltip'}
              key={`${left}-${top}`}
              sx={{
                '&:hover': details ? { cursor: 'pointer' } : {},
                ...getPosition({ left, top })
              }}
            >
              {label}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default IllustrationScreen
