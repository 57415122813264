import { Box, Button, Chip, LinearProgress, Typography } from '@mui/material'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useGetCMSPostsByCategory } from 'api/cms'
import { useGetCategoryId } from 'hooks/useGetCategoryId'
import CMSCard from 'components/CMS/CMSCard'
import ErrorBoundaryWithFallback from 'components/ErrorBoundaryWithFallback'
import { useUrlQuery } from 'hooks/useUrlQuery'
import { useNavigate } from 'react-router-dom'
import useGetTagId from 'hooks/useGetTagId'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const CMSList = () => {
  const navigate = useNavigate()
  const query = useUrlQuery()
  const { t } = useTranslation()

  const categoryFromQuery = query.get('category')
  const lang = i18next.language

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(undefined)
  const [posts, setPosts] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  const { categoryId: newsCategoryId, isLoadingCategory: isLoadingNewsCategory } = useGetCategoryId(
    { pathname: 'news' }
  )
  const { categoryId: blogCategoryId, isLoadingCategory: isLoadingBlogCategory } = useGetCategoryId(
    { pathname: 'blog' }
  )

  const { isLoading: isLoadingTags, tagId: langTagId } = useGetTagId(`lang:${lang}`)

  // save category id and name to object to simplify showing badge
  const humanReadableCategories = useMemo(() => {
    return {
      [blogCategoryId]: { id: blogCategoryId, label: 'blog' },
      [newsCategoryId]: { id: newsCategoryId, label: 'news' }
    }
  }, [blogCategoryId, newsCategoryId])

  const {
    data: fetchedPosts,
    isFetching,
    isLoading,
    refetch
  } = useGetCMSPostsByCategory({
    categoryId: newsCategoryId?.toString().concat(',', blogCategoryId.toString()),
    currentPage,
    options: { keepPreviousData: true },
    tags: langTagId
  })

  // only add post to display after making sure that the right posts has been refetched
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allPosts = useMemo(() => posts.concat(fetchedPosts?.data), [fetchedPosts?.data])
  useEffect(() => {
    if (fetchedPosts) {
      if (selectedCategory) {
        const filteredPosts = allPosts?.filter(item =>
          item.categories?.some(cat => selectedCategory.includes(cat))
        )
        setPosts(filteredPosts)
      } else {
        setPosts(allPosts)
      }

      setTotalPage(fetchedPosts.totalPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPosts, selectedCategory])

  const onClickLoadMore = useCallback(() => {
    setCurrentPage(currentPage + 1)
  }, [currentPage])

  // apply category filter
  useEffect(() => {
    if (categoryFromQuery) {
      const categoriesValues = Object.values(humanReadableCategories)
      const selectedCategoryId = categoriesValues.find(item => item.label === categoryFromQuery)?.id
      setSelectedCategory([selectedCategoryId])
    } else {
      setSelectedCategory(null)
    }
  }, [categoryFromQuery, humanReadableCategories])

  useEffect(() => {
    if (currentPage > 1) {
      refetch()
    }
  }, [currentPage, refetch])

  return (
    <Box>
      {isLoading || isLoadingNewsCategory || isLoadingBlogCategory || isLoadingTags ? (
        <LinearProgress color="primary" />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h1">{t('news')}</Typography>
          {selectedCategory?.length &&
            selectedCategory.map((cat, idx) => (
              <Chip
                color="primary"
                key={`category-${idx}`}
                label={humanReadableCategories[cat]?.label}
                onDelete={() => navigate('/news')}
                size="small"
                sx={{ fontWeight: 'bold', mb: 3, opacity: 0.9, width: 'fit-content' }}
                variant="outlined"
              />
            ))}
          {posts?.length ? (
            posts.map(post => (
              <ErrorBoundaryWithFallback key={post.id}>
                <CMSCard
                  categories={post.categories?.map(c => humanReadableCategories[c]?.label)}
                  post={post}
                />
              </ErrorBoundaryWithFallback>
            ))
          ) : (
            <Typography>{t('no_articles_found')}</Typography>
          )}
          {isFetching && <LinearProgress color="primary" />}
          {totalPage > currentPage && (
            <Button
              onClick={onClickLoadMore}
              sx={{
                alignSelf: 'flex-end',
                fontSize: '16px',
                textTransform: 'none',
                width: 'fit-content'
              }}
              variant="text"
            >
              {t('load_more')}...
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CMSList
