import { Box, Tab, Tabs } from '@mui/material'
import { Link, generatePath, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import routes from 'navigation/routes'

export const LoginSignupTabs = ({ tab }) => {
  const { t } = useTranslation()
  const { state } = useLocation()

  const [value, setValue] = useState(tab)

  const handleChange = (_, newValue) => setValue(newValue)
  const signupDisabled = process.env.REACT_APP_TENKO_DISABLE_SIGNUP

  return (
    <Box sx={{ marginTop: 16 }}>
      <Tabs
        indicatorColor="primary"
        onChange={handleChange}
        sx={{ borderRadius: '8px 8px 0 0' }}
        textColor="primary"
        value={value}
        variant="fullWidth"
      >
        <Tab
          component={Link}
          label={t('log_in')}
          to={{ pathname: generatePath(routes.login), state }}
        />

        {(!signupDisabled || signupDisabled === '0') && (
          <Tab
            component={Link}
            label={t('sign_up')}
            to={{ pathname: generatePath(routes.signup), state }}
          />
        )}
      </Tabs>
    </Box>
  )
}
