import { getUserOperationsQuery } from 'api/user'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export const useGetStrategies = (operation, options = {}) => {
  const endpoint = `/strategy/${operation?.opType}/${operation?.id}/`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useGetStrategy = (operation, strategyId, options = {}) => {
  const endpoint = `/strategy/${operation?.opType}/${operation?.id}/${strategyId}`

  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useMutateStrategy = (operation, strategyId, options = {}) => {
  const parentEndpoint = `/strategy/${operation?.opType}/${operation?.id}/`
  const endpoint = `${parentEndpoint}/${strategyId}`
  const { queryClient } = useQueryClient()

  return useMutation(payload => axios.put(endpoint, payload), {
    onSettled: () => {
      queryClient.invalidateQueries(parentEndpoint)
      queryClient.invalidateQueries(endpoint)
    },
    ...options
  })
}

export const useDeleteStrategy = (operation, options = {}) => {
  const parentEndpoint = `/strategy/${operation?.opType}/${operation?.id}/`
  const queryClient = useQueryClient()
  return useMutation(strategy => axios.delete(`${parentEndpoint}${strategy.id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(parentEndpoint)
      queryClient.invalidateQueries(getUserOperationsQuery)
      queryClient.invalidateQueries(`/risk/${operation?.opType}/${operation?.id}/`)
    },
    ...options
  })
}

export const useCreateStrategy = (operation, options = {}) => {
  const endpoint = `/strategy/${operation?.opType}/${operation?.id}/`
  const queryClient = useQueryClient()
  return useMutation(payload => axios.post(endpoint, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(endpoint)
      queryClient.invalidateQueries(getUserOperationsQuery)
      queryClient.invalidateQueries(`/risk/${operation?.opType}/${operation?.id}/`)
    },
    ...options
  })
}
