import { Box, CircularProgress, Grid, InputAdornment } from '@mui/material'
import { CreateOperationButton } from './UI/CreateButton'
import { useTranslation } from 'react-i18next'
import AutosaveInput from './UI/AutoSaveInput'
import DeleteOpButton from './UI/DeleteOpButton'
import Fieldset from './UI/Fieldset'
import OperationList from './OperationList'
import QueryErrors from './UI/QueryErrors'
import React from 'react'

export default function OperationDetails({
  baseline,
  children,
  createBtnProps,
  data,
  deleteBtn = true,
  isLoading,
  mutation,
  selectItem,
  setTryState
}) {
  const { t } = useTranslation()

  const showDeleteBtn =
    deleteBtn && (data.is_strategy ? !!data.parent?.is_strategy : !!data.parent?.parent)

  if (isLoading) return <CircularProgress />

  return (
    <>
      <QueryErrors mutation={mutation} />
      <Fieldset dividerAfter>
        <Grid container spacing={0}>
          <Grid item xs={showDeleteBtn ? 10 : 12}>
            <AutosaveInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <data.labelIcon sx={{ color: '#CCC' }} />
                  </InputAdornment>
                )
              }}
              autoFocus={!data.name}
              disabled={!!data.baseline}
              fullWidth
              label={`${t(data.opType)} ${t('name')}`}
              mutation={mutation}
              name="name"
              placeholder={data.placeholder}
              size="medium"
              value={data.name}
              variant="outlined"
            />
          </Grid>
          {showDeleteBtn && <DeleteOpButton gridItem operation={data} selectItem={selectItem} />}
        </Grid>
      </Fieldset>
      {children}
      {createBtnProps && (
        <Fieldset>
          <Box sx={{ mb: 1, mt: 1, textAlign: 'center' }}>
            <CreateOperationButton {...createBtnProps} parent={data} selectItem={selectItem} />
          </Box>
          <OperationList
            parentBaseline={baseline}
            parentOp={data}
            selectItem={selectItem}
            setTryState={setTryState}
          />
        </Fieldset>
      )}
    </>
  )
}
