import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export const useGetProductInputs = (product, options = {}) => {
  const endpoint = `/supply_chain/input/product/${product.id}/`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useGetAllProductsInputs = ids => {
  const queriesResults = useQueries(
    ids?.map(id => {
      const endpoint = `/supply_chain/input/product/${id}/`
      return {
        queryFn: () =>
          axios
            .get(endpoint)
            .then(({ data }) => data)
            .catch(error => console.log(error)),
        queryKey: endpoint,
        staleTime: Infinity
      }
    }) ?? []
  )
  const isLoading = queriesResults.some(query => query.isLoading)
  const isError = queriesResults.some(query => query.isLoading)

  return {
    data: queriesResults,
    isError,
    isLoading
  }
}

export const useGetFacilityInputs = (facility, options = {}) => {
  const endpoint = `/supply_chain/input/facility/${facility.id}/`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useGetAllFacilitiesInputs = ids => {
  const queriesResults = useQueries(
    ids?.map(id => {
      const endpoint = `/supply_chain/input/facility/${id}/`
      return {
        queryFn: () =>
          axios
            .get(endpoint)
            .then(({ data }) => data)
            .catch(error => console.log(error)),
        queryKey: endpoint,
        staleTime: Infinity
      }
    }) ?? []
  )
  const isLoading = queriesResults.some(query => query.isLoading)
  const isError = queriesResults.some(query => query.isLoading)

  return {
    data: queriesResults,
    isError,
    isLoading
  }
}

export const useGetInput = (input, options = {}) => {
  const endpoint = `/supply_chain/input/${input.id}`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useMutateInput = (input, options = {}) => {
  const parentEndpoint = `/operation/product/${input.product.id}`
  const endpoint = `/supply_chain/input/${input.id}`
  const queryClient = useQueryClient()
  return useMutation(payload => axios.put(endpoint, payload), {
    onSettled: () => {
      queryClient.invalidateQueries(parentEndpoint)
      queryClient.invalidateQueries(endpoint)
    },
    ...options
  })
}

export const useDeleteInput = (input, options = {}) => {
  const endpoint = `/supply_chain/input/${input.id}`
  const queryClient = useQueryClient()
  return useMutation(() => axios.delete(endpoint), {
    onSuccess: () => {
      // const endpoint = `/supply_chain/input/facility/${facility.id}/`
      // const parentEndpoint = `/supply_chain/input/product/${input.product.id}/`
      // console.log('invalidate:', parentEndpoint, '/supply_chain/input/product/\\d+$')
      // queryClient.invalidateQueries(parentEndpoint)
      // eslint-disable-next-line prefer-regex-literals
      const re = new RegExp('/supply_chain/input/(product|facility)/\\d+/$')
      queryClient.invalidateQueries({
        predicate: q => typeof q.queryKey === 'string' && q.queryKey.match(re)
      })
    },
    ...options
  })
}

export const useCreateInput = (product, options = {}) => {
  const endpoint = `/supply_chain/input/`
  const queryClient = useQueryClient()
  return useMutation(
    payload => {
      if (payload.sector_code === '') {
        delete payload.sector_code
      }
      payload.product_id = product.id
      return axios.post(endpoint, payload)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(endpoint)
        // eslint-disable-next-line prefer-regex-literals
        const re = new RegExp('/supply_chain/input/(product|facility)/\\d+/$')
        queryClient.invalidateQueries({
          predicate: q => typeof q.queryKey === 'string' && q.queryKey.match(re)
        })
      },
      ...options
    }
  )
}

export const useCreateInputWithVendor = (product, options = {}) => {
  const endpointParent = `/supply_chain/input/product/${product.id}/`
  const queryClient = useQueryClient()
  return useMutation(
    payload => {
      if (payload.sector_code === '') {
        delete payload.sector_code
      }
      payload = {
        input: Object.fromEntries(
          Object.entries(payload).filter(([k, _]) => !k.startsWith('vendor.'))
        ),
        vendor: Object.fromEntries(
          Object.entries(payload)
            .filter(([k, _]) => k.startsWith('vendor.'))
            .map(([k, v]) => [k.substring(7), v])
        )
      }
      return axios.post(`${endpointParent}create_with_new_vendor`, payload)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(endpointParent)
        // eslint-disable-next-line prefer-regex-literals
        const re = new RegExp('/supply_chain/input/(product|facility)/\\d+/$')
        queryClient.invalidateQueries({
          predicate: q => typeof q.queryKey === 'string' && q.queryKey.match(re)
        })
      },
      ...options
    }
  )
}
