import { Button } from '@mui/material'
import { useCreateOperation } from '../../api/operations'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import QueryErrors from './QueryErrors'
import React from 'react'

export function CreateButton({ createMutation, onSuccess = null, payload = {}, children }) {
  return (
    <>
      <QueryErrors mutation={createMutation} />
      <Button
        color="primary"
        onClick={() => createMutation.mutateAsync(payload).then(onSuccess)}
        variant="outlined"
      >
        {children}
      </Button>
    </>
  )
}

CreateButton.propTypes = {
  createMutation: PropTypes.object.isRequired,
  onSuccess: PropTypes.func
}

export function CreateOperationButton(props) {
  const { t } = useTranslation()
  const { children, opType, parent, payload, selectItem, ...other } = props

  const createMutation = useCreateOperation(opType, parent)

  return (
    <CreateButton
      createMutation={createMutation}
      onSuccess={op => selectItem(op.endpoint)}
      payload={{ name: '', ...payload }}
      {...other}
    >
      {children || `${t('add')} ${t(opType)}`}
    </CreateButton>
  )
}
