const routes = {
  aboutUs: '/about-us',
  // blog: '/blog/:slug',
  blogs: '/blog',
  careers: '/careers',
  contact: '/contact',
  dashboard: '/dashboard',
  data: '/data',
  faq: '/faq',
  landing: '/',
  legal: '/legal',
  login: '/login',
  logout: '/logout',
  news: '/news',
  newsArticle: '/news/:slug',
  profile: '/profile',
  resilience: '/resilience',
  risk: '/risk',
  riskOpened: '/risk/:tab/:openedPanel',
  riskTab: '/risk/:tab',
  signup: '/signup',
  strategy: '/strategy/:opType(portfolio|company)/:opId(\\d+)/:strategyId(\\d+)',
  tech: '/tech',
  useCases: '/#use-cases'
}

export const aliasRoutes = [{ from: '/about', to: routes.aboutUs }]

export default routes
