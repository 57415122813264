import { Avatar, Box, Button, Container, Grid, Link, IconButton, Typography } from '@mui/material'
import { Element, Link as ScrollLink, scroller } from 'react-scroll'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link as RouterLink, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ContactForm from 'components/ContactForm'
import i18next from 'i18next'
import 'video-react/dist/video-react.css'
import { Player, ControlBar, BigPlayButton, VolumeMenuButton } from 'video-react'
import routes from '../navigation/routes'
import { useScroll, motion } from 'framer-motion'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { SocialMediaStack } from './ContactPage'

// const TooltipContainer = ({ props }) => {
//   const defaultRect = { left: 0, width: 0 }

//   const [containerRect, setContainerRect] = useState(defaultRect)
//   const [tooltipRect, setTooltipRect] = useState(defaultRect)

//   const containerRef = createRef()
//   const tooltipRef = createRef()
//   let getRectsInterval

//   useEffect(() => {
//     getRectsInterval = setInterval(() => {
//       setContainerRect(() => {
//         const _containerRect = containerRef.current.getBoundingClientRect()
//         return JSON.stringify(_containerRect) !== JSON.stringify(containerRect)
//           ? null
//           : { _containerRect }
//       })
//       setTooltipRect(() => {
//         const _tooltipRect = tooltipRef.current.getBoundingClientRect()
//         return JSON.stringify(_tooltipRect) === JSON.stringify(tooltipRect)
//           ? null
//           : { _tooltipRect }
//       })
//     }, 10)

//     return () => {
//       clearInterval(getRectsInterval)
//     }
//   })

//   const left = containerRect.left + containerRect.width / 2 - tooltipRect.width / 2 + 'px'

//   return (
//     <div ref={containerRef} style={{ display: 'inline-block', position: 'relative' }}>
//       <span>Here is some text that will make the parent expand</span>
//       <img src="https://www.telegraph.co.uk/content/dam/pets/2017/01/06/1-JS117202740-yana-two-face-cat-news_trans_NvBQzQNjv4BqJNqHJA5DVIMqgv_1zKR2kxRY9bnFVTp4QZlQjJfe6H0.jpg?imwidth=450" />
//       <div
//         ref={tooltipRef}
//         style={{
//           background: 'blue',
//           left,
//           position: 'absolute',
//           top: 0
//         }}
//       >
//         Tooltip
//       </div>
//     </div>
//   )
// }

const Screen0 = () => (
  <Container
    sx={{
      alignContent: 'start',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 50px)',
      justifyContent: 'end',
      pb: '5%',
      zIndex: 1
    }}
  >
    <Container
      maxWidth="lg"
      sx={{
        color: '#FFF',
        margin: 0,
        paddingLeft: '5%',
        paddingRight: '5%'
        // zIndex: 1000
      }}
    >
      <Box
        component="img"
        src="/tenko_white.png"
        sx={{
          filter: 'drop-shadow(1px 3px 5px rgba(0,0,0,0.85))',
          height: '200px',
          maxWidth: '90%',
          objectFit: 'contain',
          width: 'auto'
        }}
      />
      <Typography
        sx={{
          fontSize: '3rem',
          marginBottom: 10,
          marginTop: 2,
          maxWidth: '90%'
        }}
        variant="h1"
      >
        {i18next.t('tagline')}
      </Typography>
    </Container>
    <Container
      sx={{
        '&:hover .MuiIconButton-root': { display: 'inherit' },
        height: '10%',
        textAlign: 'center'
      }}
    >
      <ScrollLink duration={300} smooth to="problem">
        <IconButton aria-label="Scroll down" size="large" sx={{ height: '3rem', width: '3rem' }}>
          <FontAwesomeSvgIcon color="white" icon={faAngleDoubleDown} size="md" />
        </IconButton>
      </ScrollLink>
    </Container>
  </Container>
)

const Screen1 = () => (
  <Container
    maxWidth={false}
    sx={{
      // backgroundColor: '#00000044',
      background:
        'linear-gradient(180deg,  #00000000 0%, #00000088 25%, #00000088 75%, #00000044 85%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      minHeight: '100vh',
      px: 2,
      py: 2
    }}
  >
    <Container
      maxWidth="lg"
      sx={{
        '.bullet': {
          '.block': {
            color: '#BBB',
            fontSize: '1rem',
            textAlign: 'center'
          },
          '.icon': { backgroundColor: '#EEE', color: '#005', marginBottom: 0.5 },
          '.subhead': { color: '#EEE', fontSize: '1.5rem', my: 1 },
          px: 5,
          textAlign: 'center'
        },

        '.title': { color: '#FAFAFA' }
      }}
    >
      <Typography className="title">{i18next.t('landing_screen_1_title')}</Typography>
      <Typography className="subhead">{i18next.t('landing_screen_1_description')}</Typography>
      <Grid container spacing={5} sx={{ marginTop: 5 }}>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Costs" className="icon" size="large">
            <Avatar src="/landing/icons/costs.png" />
          </IconButton>
          <Typography className="subhead">{i18next.t('screen_1_col_1')}</Typography>
          <Typography className="block">{i18next.t('screen_1_col_1_description')}</Typography>
        </Grid>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Operations" className="icon" size="large">
            <Avatar src="/landing/icons/operations.png" variant="square" />
          </IconButton>
          <Typography className="subhead">{i18next.t('screen_1_col_2')}</Typography>
          <Typography className="block">{i18next.t('screen_1_col_2_description')}</Typography>
        </Grid>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Analytics" className="icon" size="large">
            <Avatar src="/landing/icons/mitigation.png" variant="square" />
          </IconButton>
          <Typography className="subhead">{i18next.t('screen_1_col_3')}</Typography>
          <Typography className="block">{i18next.t('screen_1_col_3_description')}</Typography>
        </Grid>
      </Grid>
    </Container>
  </Container>
)

const OurServices = () => (
  <Container
    maxWidth={false}
    sx={{
      '.bullet': {
        '.block': { color: '#888', fontSize: '1rem', textAlign: 'center' },
        '.icon': { backgroundColor: '#EEE', color: '#005', marginBottom: 0.5 },
        '.subhead': { color: '#333', fontSize: '1.5rem', my: 1 },

        marginBottom: 2,
        px: 5,
        textAlign: 'center'
      },
      background: 'linear-gradient(180deg, #FDFCFCFF 25%, #FEFAF7FF 100%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      minHeight: '100vh',
      pb: 2,
      pt: '55px',
      px: 2
    }}
  >
    <Container maxWidth="lg">
      <Typography className="title">{i18next.t('landing_screen_2_title')}</Typography>
      <Typography className="subhead">{i18next.t('landing_screen_2_description')}</Typography>
      <Grid container spacing={5} sx={{ marginTop: 1 }}>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Costs" className="icon" size="large">
            <Avatar src="/landing/icons/earth-analytics.png" />
          </IconButton>
          <Typography className="subhead">{i18next.t('screen_2_col_1')}</Typography>
          <Typography className="block">{i18next.t('screen_2_col_1_description')}</Typography>
        </Grid>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Operations" className="icon" size="large">
            <Avatar src="/landing/icons/analytics.png" variant="square" />
          </IconButton>
          <Typography className="subhead">{i18next.t('screen_2_col_2')}</Typography>
          <Typography className="block">{i18next.t('screen_2_col_2_description')}</Typography>
        </Grid>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Analytics" className="icon" size="large">
            <Avatar src="/landing/icons/decision.png" variant="square" />
          </IconButton>
          <Typography className="subhead">{i18next.t('screen_2_col_3')}</Typography>
          <Typography className="block">{i18next.t('screen_2_col_3_description')}</Typography>
        </Grid>
      </Grid>
    </Container>
    <Container maxWidth="lg">
      <Typography className="title" sx={{ fontSize: '2rem !important' }}>
        {i18next.t('landing_services_description_2')}
      </Typography>
      <Grid
        container
        my={1.5}
        spacing={3}
        sx={{
          '.block': { maxWidth: '15rem' },
          '.subhead': {
            color: '#173660',
            fontWeight: 'bold',
            maxWidth: '15rem',
            textAlign: 'left'
          },
          '.workflow': {
            '.icon-box': {
              '&.inter': {
                img: { width: '2rem' },
                paddingRight: 2.5
              },
              paddingRight: 2,
              textAlign: 'right'
            },
            img: { width: '3rem' }
          }
        }}
      >
        <Grid className="workflow" container item md={6} sm={6} spacing={0} xs={12}>
          <Grid className="icon-box" item xs={3}>
            <img src={'/landing/icons/business-data.png'} />
          </Grid>
          <Grid item xs={9}>
            <Typography className="subhead">{i18next.t('screen_2_workflow_1')}</Typography>
            <Typography className="block">
              {i18next.t('screen_2_workflow_1_description')}
            </Typography>
          </Grid>
          <Grid className="icon-box inter" item xs={3}>
            <img src={'/landing/icons/multiply.png'} />
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid className="icon-box" item xs={3}>
            <img src={'/landing/icons/models.png'} />
          </Grid>
          <Grid item xs={9}>
            <Typography className="subhead">{i18next.t('screen_2_workflow_2')}</Typography>
            <Typography className="block">
              {i18next.t('screen_2_workflow_2_description')}
            </Typography>
          </Grid>
          <Grid className="icon-box inter" item xs={3}>
            <img src={'/landing/icons/angle-double-down.png'} />
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid className="icon-box" item xs={3}>
            <img src={'/landing/icons/analytics-charts.png'} />
          </Grid>
          <Grid item xs={9}>
            <Typography className="subhead">{i18next.t('screen_2_workflow_3')}</Typography>
            <Typography className="block">
              {i18next.t('screen_2_workflow_3_description')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} sx={{ maxHeight: '32vh', textAlign: 'center' }} xs={12}>
          <img height="100%" src={'/landing/dashboard_screen.png'} />
        </Grid>
      </Grid>
    </Container>
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Typography className="cta">
        <Link component={RouterLink} to={generatePath('/#contact-us-nav')}>
          {i18next.t('contact_us')}
        </Link>{' '}
        {i18next.t('landing_services_cta')}&nbsp;
        <Link component={RouterLink} to={generatePath('/#contact-us-nav')}>
          ➤
        </Link>
      </Typography>
      <Typography className="cta">
        <Link component={RouterLink} to={generatePath(routes.tech)}>
          {i18next.t('landing_services_tech_link')}
        </Link>{' '}
        {i18next.t('landing_services_tech_text')}&nbsp;
        <Link component={RouterLink} to={generatePath(routes.tech)}>
          ➤
        </Link>
      </Typography>
    </Container>
  </Container>
)

const UseCases = () => {
  const videos = [
    { file: 'use_case_supply_chain.mp4', title: 'Disruptions' },
    { file: 'use_case_costs.mp4', title: 'Costs' }
  ]
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: '#FFFFFFDD',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        minHeight: '100vh',
        pb: 2,
        pt: '55px',
        px: 2
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          '.slick-dots': {
            justifyContent: 'space-evenly !important'
          },
          '.slick-prev:before, .slick-next:before': {
            color: '#222'
          },
          paddingBottom: '3.5rem',
          textAlign: 'center'
        }}
      >
        <Typography className="title">{i18next.t('landing_use_cases_title')}</Typography>
        <Slider
          appendDots={dots => (
            <Grid
              container
              justifyContent="center"
              spacing={0}
              sx={{
                '.MuiButton-root': {
                  borderColor: '#111',
                  color: '#111'
                },
                '.slick-active .MuiButton-root': {
                  borderColor: '#999 !important',
                  color: '#999 !important'
                },
                bottom: '-3rem !important',
                display: 'flex !important',
                height: '3rem'
              }}
            >
              {dots.map(dot => {
                return (
                  <Grid
                    className={dot.props.className}
                    item
                    key={dot.key}
                    sx={{
                      fontSize: '2rem',
                      fontWeight: 'bold',
                      margin: 0,
                      padding: 0
                    }}
                    xs={3}
                  >
                    {dot.props.children}
                  </Grid>
                )
              })}
            </Grid>
          )}
          customPaging={i => (
            <Button color={'primary'} variant="outlined">
              #{i + 1}: {videos[i].title}
            </Button>
          )}
          dots={true}
          slidesToScroll={1}
          slidesToShow={1}
          speed={500}
        >
          {videos.map(video => (
            <Player autoPlay={true} key={video.file} src={`/use-cases/${video.file}`}>
              <BigPlayButton position="center" />
              <ControlBar autoHide={true} autoHideTime={50}>
                <VolumeMenuButton disabled />
              </ControlBar>
            </Player>
          ))}
        </Slider>
      </Container>
      <Container maxWidth="lg" sx={{ mt: 1 }}>
        <Typography className="cta">
          <Link component={RouterLink} to={generatePath('/#contact-us-nav')}>
            {i18next.t('contact_us')}
          </Link>{' '}
          {i18next.t('landing_services_cta')}&nbsp;
          <Link component={RouterLink} to={generatePath('/#contact-us-nav')}>
            ➤
          </Link>
        </Typography>
      </Container>
    </Container>
  )
}

const Screen3 = () => (
  <Container
    maxWidth={false}
    sx={{
      background: 'linear-gradient(180deg, #000000AA 0%, #FEFAF7FF 50%, #FFFFFFFF 100%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      // minHeight: '90vh',
      // paddingBottom: 20,
      px: 2,
      py: 5
    }}
  >
    <Container
      maxWidth="lg"
      sx={{
        '.bullet': {
          '.block': {
            color: '#333',
            fontSize: '1rem',
            textAlign: 'center'
          },
          '.icon': { backgroundColor: '#EEE', color: '#005', marginBottom: 0.5 },
          '.subhead': { color: '#111', fontSize: '1.5rem', my: 1 },

          px: 5,
          textAlign: 'center'
        },
        '.title': { color: '#EEE' }
      }}
    >
      <Typography className="title">{i18next.t('landing_advantage_title')}</Typography>
      <Typography className="subhead">{i18next.t('landing_advantage_description')}</Typography>
      <Grid container spacing={5} sx={{ marginTop: 5 }}>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Innovation" className="icon" size="large">
            <Avatar src="/landing/icons/lightbulb-innovation.png" />
          </IconButton>
          <Typography className="subhead">{i18next.t('landing_advantage_col_1')}</Typography>
          <Typography className="block">
            {i18next.t('landing_advantage_col_1_description')}
          </Typography>
        </Grid>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Expertise" className="icon" size="large">
            <Avatar src="/landing/icons/tools.png" variant="square" />
          </IconButton>
          <Typography className="subhead">{i18next.t('landing_advantage_col_2')}</Typography>
          <Typography className="block">
            {i18next.t('landing_advantage_col_2_description')}
          </Typography>
        </Grid>
        <Grid className="bullet" item sm={4} xs={12}>
          <IconButton aria-label="Bespoke" className="icon" size="large">
            <Avatar src="/landing/icons/agile.png" variant="square" />
          </IconButton>
          <Typography className="subhead">{i18next.t('landing_advantage_col_3')}</Typography>
          <Typography className="block">
            {i18next.t('landing_advantage_col_3_description')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Container>
)

// handle change in anchor on scroll
const Section = ({ children, path }) => {
  const navigate = useNavigate()
  const ref = useRef()

  useEffect(() => {
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          navigate(`#${path}`)
        }
      },
      {
        root: null,
        threshold: 0.1 // set offset 0.1 means trigger if at least 10% of element in viewport
      }
    )

    if (ref.current) observer.observe(ref.current)
  }, [path, navigate, ref])

  return (
    <div id={path} ref={ref}>
      {children}
    </div>
  )
}

export const LandingPage = () => {
  const { t } = useTranslation()
  const { hash } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (hash.includes('-nav')) {
      scroller.scrollTo(hash.slice(1, -4), {
        duration: 300,
        smooth: true
      })
    }
  }, [hash])

  // first load
  // @FIXME react-scroll could not recognize section name on first render, so this is a workaround to manipulate the hash instead
  useEffect(() => {
    if (hash) {
      navigate(`/${hash}-nav`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // logo animation
  const scrollRef = useRef(null)
  const { scrollYProgress } = useScroll({
    offset: ['start start', 'end end'],
    target: scrollRef
  })

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        '.cta': {
          // '&:after': {
          //   content: '" ☞"'
          // },
          color: '#444',
          fontSize: '1.2rem',
          fontStyle: 'italic',
          my: 1.5,
          textAlign: 'center'
        },
        '.subhead': { color: '#888', fontSize: '1.2rem', mt: -1.5, textAlign: 'center' },
        '.title': {
          // fontSize: '2.2rem',
          fontSize: '2.8rem',
          fontWeight: 700,
          letterSpacing: '-0.02em',
          mb: 2,
          mt: 2,
          textAlign: 'center'
        },
        position: 'relative'
      }}
    >
      <RouterLink to="/">
        <motion.img
          src="/tenko_white.png"
          style={{
            height: '35px',
            left: '16px',
            opacity: scrollYProgress,
            position: 'fixed',
            top: '14px',
            zIndex: 100
          }}
        />
      </RouterLink>
      <Box
        sx={{
          backgroundImage: 'url(/background.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: 'auto',
          left: 0,
          minHeight: '100%',
          minWidth: 1024,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: -1
        }}
      />
      <div ref={scrollRef}>
        <Section path="">
          <Element name="home">
            <Screen0 />
          </Element>
          <Element name="problem">
            <Screen1 />
          </Element>
        </Section>
      </div>
      <Section path="our-services">
        <Element name="our-services">
          <OurServices />
        </Element>
      </Section>
      <Section path="use-cases">
        <Element name="use-cases">
          <UseCases />
        </Element>
      </Section>
      <Screen3 path="tenko-advantages" />
      <Section path="contact-us">
        <Element name="contact-us">
          <Container
            maxWidth={false}
            sx={{
              background: 'linear-gradient(180deg, #FFFFFFFF 0%, rgba(248,247,245,1) 50%)'
            }}
          >
            <Container
              maxWidth="md"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '100vh',
                px: 1,
                py: 5
              }}
            >
              <Typography
                className="subhead"
                sx={{
                  color: '#333 !important',
                  fontSize: '1.5rem !important',
                  fontStyle: 'italic'
                }}
              >
                {t('contact_us_subhead')}
              </Typography>
              <Typography className="title">{t('contact_us_title')}</Typography>
              <SocialMediaStack />
              <ContactForm></ContactForm>
            </Container>
          </Container>
        </Element>
      </Section>
    </Container>
  )
}
