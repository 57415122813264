import { useGetCMSTags } from 'api/cms'
import { useMemo } from 'react'

const useGetTagId = tag => {
  const { data: tags, isLoading } = useGetCMSTags()

  const tagId = useMemo(() => {
    return tags?.find(t => t.name === tag)?.id
  }, [tag, tags])

  return { isLoading, tagId }
}

export default useGetTagId
