import { CustomContainerWithErrorBoundary } from 'layout/CustomContainer'
import { LinearProgress } from '@mui/material'
import { RenderPost } from 'components/CMS/CMSItem'
import { useGetCMSPageBySlugAndLang } from 'api/cms'
import { useEffect, useState } from 'react'

export const StaticCMSPage = ({ slug }) => {
  // @FIXME: workaround to force component state change on first render
  // there is an issue where on the first render, the query will stuck on loading state
  // we have force the component to rerender
  const [renderPage, setRenderPage] = useState(false)

  useEffect(() => {
    setRenderPage(true)
  }, [])

  const { data, isFetching, isLoading } = useGetCMSPageBySlugAndLang(slug)

  if (!renderPage || isLoading || isFetching) return <LinearProgress color="primary" />

  return (
    <CustomContainerWithErrorBoundary>
      <RenderPost post={data} />
    </CustomContainerWithErrorBoundary>
  )
}

export const LegalPage = () => {
  return <StaticCMSPage slug="legal" />
}

export const FAQPage = () => {
  return <StaticCMSPage slug="faq" />
}

export const CareersPage = () => {
  return <StaticCMSPage slug="careers" />
}
