import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Box, ButtonBase, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ToggleContent = ({
  children,
  closed,
  color = '#555',
  isLoading,
  opened,
  startOpen = false,
  title,
  ...others
}) => {
  const [isOpen, setIsOpen] = useState(startOpen)
  /* eslint react-hooks/exhaustive-deps: "off" */
  useEffect(() => {
    setIsOpen(startOpen)
  }, [])

  return (
    <Box {...others}>
      <ButtonBase
        TouchRippleProps={{ classes: { root: 'MuiRippleRoot' } }}
        className="toggle-button"
        disabled={isLoading} // debouncing
        onClick={() => setIsOpen(!isOpen)}
        size="medium"
        sx={{
          '&:hover': { backgroundColor: '#FAFAFF' },
          '.MuiRippleRoot': { color: 'primary.main' },
          justifyContent: 'left',
          textAlign: 'left',
          width: '100%'
        }}
      >
        {isOpen ? (
          <ArrowDropDown sx={{ color }} />
        ) : (
          <ArrowRight sx={{ color, marginLeft: '-5px' }} />
        )}{' '}
        <Typography sx={{ color }} variant="h5">
          {!!closed && !isOpen ? closed : title}
        </Typography>
      </ButtonBase>
      {isOpen && (
        <Box className="toggle-content" sx={{ marginTop: 0.5 }}>
          {isLoading ? <CircularProgress /> : opened || children}
        </Box>
      )}
    </Box>
  )
}

export default ToggleContent

ToggleContent.propTypes = {
  title: PropTypes.string.isRequired
}
