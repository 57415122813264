import { CustomContainerWithErrorBoundary } from 'layout/CustomContainer'
import CMSItem from 'components/CMS/CMSItem'

export const CMSItemPage = () => {
  return (
    <CustomContainerWithErrorBoundary
      sx={{ px: { md: '145px !important' }, py: '40px !important' }}
    >
      <CMSItem />
    </CustomContainerWithErrorBoundary>
  )
}
