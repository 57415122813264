import { useQuery } from 'react-query'
import axios from 'axios'
import sortBy from 'lodash/sortBy'

export const useGetCountries = (options = {}) => {
  const endpoint = '/geo/country/'
  return useQuery(
    endpoint,
    () =>
      axios.get(endpoint).then(({ data: countries }) =>
        sortBy(
          countries.map(({ iso3, name }) => ({ label: name, value: iso3 })),
          ['label']
        )
      ),
    {
      staleTime: Infinity,
      ...options
    }
  )
}
