import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export const useGetInputSupplies = (input, options = {}) => {
  const endpoint = `/supply_chain/supply/input/${input.id}/`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useMutateSupply = (supply, options = {}) => {
  const parentEndpoint = `/supply_chain/input/${supply.input?.id}`
  const endpoint = `/supply_chain/supply/${supply.id}`
  const queryClient = useQueryClient()
  return useMutation(payload => axios.put(endpoint, payload), {
    onSettled: () => {
      queryClient.invalidateQueries(parentEndpoint)
    },
    ...options
  })
}

export const useDeleteSupply = (supply, options = {}) => {
  const parentEndpoint = `/supply_chain/input/${supply.input?.id}`
  const endpoint = `/supply_chain/supply/${supply.id}`
  const queryClient = useQueryClient()
  return useMutation(() => axios.delete(endpoint), {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(parentEndpoint)
    },
    ...options
  })
}

export const useAddExistingVendor = (input, options = {}) => {
  const parentEndpoint = `/supply_chain/input/${input?.id}`
  const queryClient = useQueryClient()
  return useMutation(
    payload => {
      const vendorId = payload.vendor_id
      delete payload.vendor_id
      return axios.post(
        `/supply_chain/supply/input/${input.id}/create_with_vendor/${vendorId}`,
        payload
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(parentEndpoint)
      },
      ...options
    }
  )
}

export const useAddNewVendor = (input, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    payload => {
      if (payload.sector_code === '') {
        delete payload.sector_code
      }
      payload = {
        supply: Object.fromEntries(
          Object.entries(payload).filter(([k, _]) => !k.startsWith('vendor.'))
        ),
        vendor: Object.fromEntries(
          Object.entries(payload)
            .filter(([k, _]) => k.startsWith('vendor.'))
            .map(([k, v]) => [k.substring(7), v])
        )
      }

      return axios.post(`/supply_chain/supply/input/${input.id}/create_with_new_vendor`, payload)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(`/supply_chain/input/${input?.id}`)
        // eslint-disable-next-line prefer-regex-literals
        const re = new RegExp('/supply_chain/vendor/facility/\\d+/')
        queryClient.invalidateQueries({
          predicate: q => typeof q.queryKey === 'string' && q.queryKey.match(re)
        })
      },
      ...options
    }
  )
}
