import { Box, Typography, Stack, Avatar, IconButton } from '@mui/material'
import ContactForm from 'components/ContactForm'
import { useTranslation } from 'react-i18next'

export const SocialMediaStack = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      spacing={5}
      sx={{
        '.bullet': {
          '.block': {
            color: '#888',
            fontSize: '1rem',
            textAlign: 'center'
          },
          '.icon': {
            backgroundColor: '#EEE',
            color: '#005',
            marginBottom: 0.5
          },
          '.subhead': { color: '#333', my: 1 },
          textAlign: 'center',
          width: '25%'
        },
        my: 5
      }}
    >
      <Box className="bullet">
        <IconButton aria-label="e-Mail" className="icon" href="mailto:info@tenko.ai" size="large">
          <Avatar src="/landing/icons/email.png" variant="square" />
        </IconButton>
      </Box>
      <Box className="bullet">
        <IconButton
          aria-label="LinkedIn"
          className="icon"
          href="https://www.linkedin.com/company/77269596/"
          size="large"
        >
          <Avatar src="/landing/icons/linkedin.png" variant="square" />
        </IconButton>
      </Box>
      <Box className="bullet">
        <IconButton
          aria-label="Twitter"
          className="icon"
          href="https://twitter.com/TenkoAI"
          size="large"
        >
          <Avatar src="/landing/icons/twitter.png" variant="square" />
        </IconButton>
      </Box>
    </Stack>
  )
}

export const ContactPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContactForm
        isPage
        sx={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #adadad',
          borderRadius: '8px',
          mt: 3,
          pb: 5,
          px: '0 !important'
        }}
      >
        <>
          <Box sx={{ mb: 5, mt: 3 }}>
            <Typography variant="h1">{t('contact_us_title')}</Typography>
          </Box>
          <SocialMediaStack />
        </>
      </ContactForm>
    </>
  )
}
