import { AppContext } from 'App'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
  Typography
} from '@mui/material'
import { Inputs } from 'components/Inputs'
import { Vendors } from 'components/Vendors'
import { formatFileName } from 'utils/typography'
import { opTypes, useBulkExport, useBulkImport, useGetOperation } from 'api/operations'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import AssetDetails from 'components/AssetDetails'
import CompanyDetails from 'components/CompanyDetails'
import DownloadIcon from '@mui/icons-material/Download'
import ErrorBoundaryWithFallback from 'components/ErrorBoundaryWithFallback'
import FacilityDetails from 'components/FacilityDetails'
import NoDataAlert from 'components/UI/NoDataAlert'
import OperationTreeView from 'components/OperationTreeView'
import PortfolioDetails from 'components/PortfolioDetails'
import ProductDetails from 'components/ProductDetails'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Toast from 'components/UI/Toast'
import UploadIcon from '@mui/icons-material/Upload'
import { findItemNested } from 'utils/looping'

const PREFIX = 'DataPage'

const classes = {
  column: `${PREFIX}-column`,
  dataGrid: `${PREFIX}-dataGrid`
}

const modalContentStyle = {
  alignItems: 'center',
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  left: '50%',
  p: 4,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.column}`]: {
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(1.5)
  },

  [`&.${classes.dataGrid}`]: {
    '& > .MuiGrid-item': {
      height: '100%'
    },
    '& > .MuiGrid-item > div': {
      borderRadius: '3px',
      boxShadow: `0px 6px 6px -3px rgb(0 0 0 / 15%), 0px 10px 14px 1px rgb(0 0 0 / 10%), 0px 4px 18px 3px rgb(0 0 0 / 8%)`,
      height: '100%',
      overflowY: 'scroll'
    },
    height: '100%',
    position: 'absolute'
  }
}))

const DataPageWithData = () => {
  const { t } = useTranslation()
  const [displayOp, setDisplayOp] = useState()

  const [selectedItem, setSelectedItem] = useState()
  const [selectedInput, setSelectedInput] = useState()
  const [selectedVendorFacility, setSelectedVendorFacility] = useState()
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [showInvalidFileToast, setShowInvalidFileToast] = useState(false)
  const [isWarehouse, setIsWarehouse] = useState(false)
  const inputFileRef = useRef(null)

  const { isLoading, operations: defaultOperations } = useContext(AppContext)

  const fileNameToExport = useMemo(() => {
    return formatFileName(defaultOperations?.[0]?.name)
  }, [defaultOperations])

  const currentFacility = !displayOp
    ? null
    : displayOp.opType === opTypes.FACILITY
    ? displayOp
    : displayOp.opType === opTypes.PRODUCT
    ? displayOp.parent
    : null

  const { data: baselineOp } = useGetOperation(displayOp?.baseline, {
    enabled: !!displayOp?.baseline?.endpoint,
    staleTime: 0
  })

  const [operationTree, setOperationTree] = useState(null)

  // init tree
  useEffect(() => {
    setOperationTree(defaultOperations || [])
  }, [defaultOperations])

  useEffect(() => {
    setSelectedItem(defaultOperations?.[0]?.endpoint)
  }, [defaultOperations])

  useEffect(() => {
    if (operationTree?.length > 0) {
      // update operation object to display
      const newDisplayOp = findItemNested(operationTree, 'endpoint', selectedItem)
      setDisplayOp(newDisplayOp)
    }
  }, [selectedItem, operationTree])

  const {
    isError: onImportError,
    isLoading: isImportLoading,
    isSuccess: onImportSuccess,
    mutate: importData,
    reset: resetImportState
  } = useBulkImport(selectedFile)

  const {
    isError: onExportError,
    isLoading: isExportLoading,
    isSuccess: onExportSuccess,
    mutate: exportData,
    reset: resetExportState
  } = useBulkExport({ fileName: fileNameToExport })

  useEffect(() => {
    if (onImportSuccess) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }, [onImportSuccess])

  // upload file
  useEffect(() => {
    if (selectedFile) {
      importData()
    }
  }, [importData, selectedFile])

  // set isWarehouse value on facility level, to be consumed by others
  useEffect(() => {
    if (displayOp?.opType === opTypes.FACILITY) {
      setIsWarehouse(displayOp?.is_warehouse)
    }
  }, [displayOp])

  const ConfirmationModal = (
    <Modal onClose={() => setConfirmationModalOpen(false)} open={confirmationModalOpen}>
      <Box sx={modalContentStyle}>
        <Typography variant="h6">{t('import_from_json')}</Typography>
        <Typography sx={{ color: '#C5001B', textAlign: 'center' }}>
          {t('import_data_description')}
        </Typography>
        <Typography>{t('proceed_confirmation')}</Typography>
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Button
            onClick={() => {
              inputFileRef.current.click()
            }}
            sx={{ mr: 2 }}
          >
            <input
              accept=".json"
              hidden
              onChange={e => {
                if (e.target.files[0].type === 'application/json') {
                  setSelectedFile(e.target.files[0])
                  setConfirmationModalOpen(false)
                } else {
                  setShowInvalidFileToast(true)
                }
                e.target.value = null
              }}
              ref={inputFileRef}
              type="file"
            />
            {t('continue')}
          </Button>
          <Button
            onClick={() => {
              setConfirmationModalOpen(false)
              setSelectedFile(null)
            }}
          >
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )

  const Toasts = (
    <>
      <Toast
        message={t('import_success')}
        onClose={() => resetImportState()}
        open={onImportSuccess}
        type="success"
      />
      <Toast
        message={t('export_success')}
        onClose={() => resetExportState()}
        open={onExportSuccess}
        type="success"
      />
      <Toast
        message={t('import_fail')}
        onClose={() => resetImportState()}
        open={onImportError}
        type="error"
      />
      <Toast
        message={t('export_fail')}
        onClose={() => resetExportState()}
        open={onExportError}
        type="error"
      />
      <Toast
        message={t('file_must_be_json')}
        onClose={() => setShowInvalidFileToast(false)}
        open={showInvalidFileToast}
        type="error"
      />
    </>
  )

  if (isLoading) return <LinearProgress color="primary" />
  if (!defaultOperations?.length) return <NoDataAlert />

  const leftColWidth = 4
  const midColWidth = 4 // 3 if no vendor
  const vendorColWidth = 4

  return (
    <StyledGrid alignItems="stretch" className={classes.dataGrid} container spacing={1}>
      {Toasts}
      {ConfirmationModal}
      {(isImportLoading || isExportLoading) && (
        <Box
          sx={{
            backgroundColor: '#30353a',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            opacity: 0.5,
            position: 'absolute',
            pt: '20%',
            width: '100%',
            zIndex: 100
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}

      <Grid item md={leftColWidth} sm={6} xs={6}>
        <div className={classes.column}>
          <Tooltip title="Import data from file">
            <IconButton component="label" onClick={() => setConfirmationModalOpen(true)}>
              <UploadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export data to file">
            <IconButton onClick={exportData}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <OperationTreeView
            operationTree={operationTree}
            selectedItem={selectedItem}
            setOperationTree={setOperationTree}
            setSelectedItem={setSelectedItem}
          />
        </div>
      </Grid>
      <Grid
        item
        md={midColWidth}
        sm={6}
        sx={displayOp?.opType === opTypes.PRODUCT && isWarehouse ? { display: 'none' } : {}}
        xs={6}
      >
        <div className={classes.column}>
          {displayOp?.opType === opTypes.PORTFOLIO && (
            <ErrorBoundaryWithFallback>
              <PortfolioDetails
                baselineOp={baselineOp}
                cachedOp={displayOp}
                key={displayOp.id}
                selectItem={setSelectedItem}
              />
            </ErrorBoundaryWithFallback>
          )}
          {displayOp?.opType === opTypes.COMPANY && (
            <ErrorBoundaryWithFallback>
              <CompanyDetails
                baselineOp={baselineOp}
                cachedOp={displayOp}
                key={displayOp.id}
                selectItem={setSelectedItem}
              />
            </ErrorBoundaryWithFallback>
          )}
          {displayOp?.opType === opTypes.ASSET && (
            <ErrorBoundaryWithFallback>
              <AssetDetails
                baselineOp={baselineOp}
                cachedOp={displayOp}
                key={displayOp.id}
                selectItem={setSelectedItem}
              />
            </ErrorBoundaryWithFallback>
          )}
          {displayOp?.opType === opTypes.FACILITY && (
            <ErrorBoundaryWithFallback>
              <FacilityDetails
                baselineOp={baselineOp}
                cachedOp={displayOp}
                key={displayOp.id}
                selectItem={setSelectedItem}
              />
            </ErrorBoundaryWithFallback>
          )}
          {displayOp?.opType === opTypes.PRODUCT && (
            <ErrorBoundaryWithFallback>
              <ProductDetails
                baselineOp={baselineOp}
                cachedOp={displayOp}
                isWarehouse={isWarehouse}
                key={displayOp.id}
                selectItem={setSelectedItem}
                selectedInput={selectedInput}
                setSelectedInput={setSelectedInput}
                setSelectedVendorFacility={setSelectedVendorFacility}
              />
            </ErrorBoundaryWithFallback>
          )}
        </div>
      </Grid>
      {displayOp?.opType === opTypes.PRODUCT && (
        <>
          <Grid item md={midColWidth} sm={6} xs={6}>
            <ErrorBoundaryWithFallback>
              <Inputs
                facility={currentFacility}
                isWarehouse={isWarehouse}
                key={displayOp?.id}
                product={displayOp}
                selectedInput={selectedInput || null}
                selectedVendor={selectedVendorFacility || null}
                setSelectedInput={setSelectedInput}
                setSelectedVendorFacility={setSelectedVendorFacility}
              />
            </ErrorBoundaryWithFallback>
          </Grid>
          <Grid item md={vendorColWidth} sm={6} xs={6}>
            <ErrorBoundaryWithFallback>
              <Vendors
                facility={currentFacility}
                key={currentFacility.id}
                selectedVendorFacility={selectedVendorFacility || null}
                setSelectedVendorFacility={setSelectedVendorFacility}
              />
            </ErrorBoundaryWithFallback>
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

const DataPage = () => {
  return (
    <ErrorBoundaryWithFallback fullPageError>
      <DataPageWithData />
    </ErrorBoundaryWithFallback>
  )
}

export default DataPage
