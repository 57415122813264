import { Box, Link, Typography } from '@mui/material'
import { CustomContainer } from 'layout/CustomContainer'
import { Link as RouterLink } from 'react-router-dom'

export const NotFoundPage = () => {
  return (
    <CustomContainer
      noBorder
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '60vh',
        justifyContent: 'center',
        width: 'fit-content'
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h1">404</Typography>

        <Box sx={{ borderLeft: '4px solid #03173a', height: '70px', mx: 4 }} />

        <Typography sx={{ fontWeight: 'bold' }} variant="h1">
          Page not found
        </Typography>
      </Box>
      <Link
        component={RouterLink}
        sx={{
          '&:hover': { fontWeight: 'bold' },
          alignSelf: 'flex-end',
          mt: 3,
          textAlign: 'center',
          textDecoration: 'none'
        }}
        to="/"
      >
        Go back to homepage
      </Link>
    </CustomContainer>
  )
}
