import 'chartjs-adapter-date-fns'
import { Bar, Line } from 'react-chartjs-2'
import { Box, IconButton } from '@mui/material'
import { CSVLink } from 'react-csv'
import { getMax, getMin } from 'utils/numbers'
import { getOperationsIds } from 'utils/operations'
import DownloadIcon from '@mui/icons-material/Download'
import React, { Component } from 'react'
import ReportViewButton from 'components/Risk/ReportViewButton'
import _ from 'lodash'

// TODO: transform into functional component
class RiskChart extends Component {
  constructor(props) {
    super(props)

    this.lineOptions = {
      aspectRatio: 2,
      elements: {
        point: { radius: 1 }
      },
      maintainAspectRatio: true,
      plugins: {
        legend: { display: false },
        tooltip: {
          callbacks: {
            labelPointStyle: function (context) {
              return {
                pointStyle: 'crossRot',
                rotation: 0
              }
            }
          },
          usePointStyle: true
        }
      },
      responsive: true,
      scales: {
        xAxes: {
          ticks: { display: true },
          // time: { unit: 'quarter' },
          type: 'time'
        },
        yAxes: {
          beginAtZero: true,
          display: true,
          // min: 0,
          // suggestedMax: 1.01,
          ticks: { display: true }
        }
      }
    }

    this.barOptions = {
      barThickness: '5',
      layout: {
        padding: {
          top: 5
          // left: -10,
          // right: -50
        }
      },
      // aspectRatio: 14,
      maintainAspectRatio: false,
      // barPercentage: 0.1,
      // categoryPercentage: 0.1,
      plugins: {
        legend: { display: false },
        title: {
          display: true,
          position: 'left'
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return `${Math.round(context.parsed.y).toLocaleString()} €`
            },
            title: function (context) {
              return 'Impact'
            }
          },
          displayColors: false,
          enabled: true,
          xAlign: 'right',
          yAlign: 'center'
        }
      },
      scales: {
        x: {
          display: true,
          grid: { display: false },
          ticks: { display: false },
          // time: { unit: 'quarter' },
          type: 'time'
        },
        y: {
          beginAtZero: true,
          display: false,
          grid: { display: false },
          // type: 'linear',
          ticks: { display: true }
        }
      }
    }

    this.state = {
      csvData: []
    }

    this.csvLinkEl = React.createRef()

    this.datasets = []
    this.riskBars = []
    this.filteredData = []
    this.colNames = []
    this.idxToKey = {}
  }

  processData() {
    // console.log('RevenueRiskChart: processing data')
    // let df = new dfd.DataFrame(this.props.data)

    // TODO : refactor using filterDataset function
    let keys
    if (this.props.data[0]?.doy || false) {
      // console.log('Data is in dict form')
      this.data = this.props.data
      keys = Object.entries(this.props.data[0]).keys()
    } else {
      // console.log('Data is not in dict form. Assuming lists with col names in first line.')
      keys = this.props.data[0]
      this.data = this.props.data.slice(1)
    }

    const idxToKey = {}
    keys.forEach((key, i) => (idxToKey[key] = i))

    this.colNames = [...keys]
    this.idxToKey = idxToKey

    const filters = { ...this.props.filters, ...getOperationsIds(this.props.selectedOps) }

    const filterFunctions = Object.entries(filters).map(([k, vals]) => {
      const valsSet = new Set(vals)
      return function (row) {
        return (
          row[idxToKey[k]] === null ||
          row[idxToKey[k]] === undefined ||
          valsSet.has(row[idxToKey[k]])
        )
      }
    })

    const startDate = this.props.startDate && new Date(this.props.startDate).getTime()
    const endDate = this.props.endDate && new Date(this.props.endDate).getTime()

    const filteredData = this.data.filter(row => {
      return (
        (!startDate || row[idxToKey.timestamp] >= startDate) &&
        (!endDate || endDate >= row[idxToKey.timestamp]) &&
        filterFunctions.every(fn => fn(row))
      )
    })

    this.lineOptions.scales.xAxes.time = { unit: 'quarter' }
    if (startDate && endDate) {
      if (new Date(this.props.endDate) - new Date(this.props.startDate) <= 1000 * 3600 * 24 * 366) {
        this.lineOptions.scales.xAxes.time = { unit: 'month' }
      }
    }

    // console.log('filteredData:', filteredData.length)
    this.filteredData = filteredData

    if (filteredData.length === 0) {
      // console.log('filteredData.length === 0')
      this.datasets = []
      return
    }

    let strategyCol
    let groupedData
    if (this.props.strategyCol && this.props.strategyCol in idxToKey) {
      strategyCol = idxToKey[this.props.strategyCol]
      groupedData = filteredData.reduce((entryMap, e) => {
        const key = e[strategyCol] ? `Strategy ${e[strategyCol]}` : `Forecast`
        return entryMap.set(key, [...(entryMap.get(key) || []), e])
      }, new Map())
    } else {
      strategyCol = undefined
      groupedData = new Map().set('Forecast', filteredData)
    }

    let normFactor = 1
    if (this.props.usePercent) {
      // console.log(arrData[0][1])
      const group = groupedData.get('Forecast', groupedData.values()[0])
      const minDoy = group.reduce(
        (acc, r) => Math.min(acc, r[idxToKey.doy]),
        group[0][idxToKey.doy]
      )
      normFactor = group
        .filter(r => r.risk_type == null && r[idxToKey.doy] === minDoy)
        .reduce((tot, r) => tot + r[idxToKey.val], 0)
      if (!normFactor) {
        normFactor = 1
        // this.lineOptions.scales.yAxes = {
        //   ticks: {
        //     beginAtZero: true,
        //     min: 0
        //   }
        // }
      } else {
        this.lineOptions.scales.yAxes.ticks.callback = value => (value * 100).toPrecision(3) + '%'
      }
    }

    this.datasets = []
    const colours = ['#330B27', '#001736', '#131112', '#ba5a31', '#058E8A', '#001736']
    // const colours = ['#FF0000', '#00FF00', '#0000FF', '#ba5a31', '#058E8A', '#001736']
    // Default colour
    let colour = colours[0]

    for (const [label, data] of groupedData.entries()) {
      if (data.length === 0) continue

      if (strategyCol !== undefined) {
        colour = this.props.strategyColours.find(
          x => x.strategy_id === data[0][strategyCol]
        )?.colour
        //  | colours.shift()
        if (!colour) colour = colours.shift()
      } else if (colours.length > 1) {
        colour = colours.shift()
      }
      // console.log(label, colour)

      const reducedData = data.reduce((entryMap, e) => {
        return entryMap.set(e[idxToKey.timestamp], [
          ...(entryMap.get(e[idxToKey.timestamp]) || []),
          e
        ])
      }, new Map())
      const arrData = Array.from(reducedData.entries()).sort((r1, r2) => r1[0] - r2[0])

      this.datasets.push({
        borderColor: colour,
        data: arrData.map(([d, arr]) => [
          d,
          arr.reduce((tot, r) => tot + r[idxToKey.val], 0) / normFactor
        ]),
        label
      })

      if ('lower' in idxToKey && 'upper' in idxToKey) {
        this.datasets.push({
          borderColor: '#FFFFFF00',
          data: arrData.map(([d, arr]) => [
            d,
            arr.reduce((tot, r) => tot + r[idxToKey.upper], 0) / normFactor
          ]),
          fill: { above: '#00000020', target: '-1' },
          label: `${label}: 95% CI (upper)`,
          pointRadius: 0
        })
        this.datasets.push({
          borderColor: '#FFFFFF00',
          data: arrData.map(([d, arr]) => [
            d,
            arr.reduce((tot, r) => tot + r[idxToKey.lower], 0) / normFactor
          ]),
          fill: { below: '#00000020', target: '-2' },
          label: `${label}: 95% CI (lower)`,
          pointRadius: 0
        })
      }
    }

    if (this.props.showRiskBars) {
      const allTimestamps = this.filteredData.map(r => r[idxToKey.timestamp])

      const riskTypes = [
        {
          colour: '#0995f2',
          label: 'Flood',
          risk: 'flood'
        },
        {
          colour: '#09a2ac',
          label: 'Wind',
          risk: 'wind'
        },
        {
          colour: '#BF99C9',
          label: 'Drought',
          risk: 'drought'
        },
        {
          colour: '#918CED',
          label: 'Supply Chain',
          risk: 'supply_chain'
        },
        {
          colour: '#666666',
          label: 'Transition',
          risk: 'transition'
        }
      ]
      this.riskBars = riskTypes
        .map(({ colour, label, risk }) => {
          const dataset = this.filteredData.filter(r => r[idxToKey.risk_type] === risk)

          // if (! dataset.length) {
          //   return null
          // }

          const mapDataset = dataset.reduce((entryMap, e) => {
            return entryMap.set(e[idxToKey.timestamp], [
              ...(entryMap.get(e[idxToKey.timestamp]) || []),
              e
            ])
          }, new Map())

          const aggDataset = Array.from(mapDataset.entries()).map(([d, arr]) => ({
            x: d,
            y: arr.reduce((tot, r) => tot - r[idxToKey.val], 0)
          }))

          return {
            data: {
              datasets: [
                {
                  backgroundColor: `${colour}CC`,
                  data: [
                    ...aggDataset,
                    // Make sure x axes are aligned:
                    { x: getMin(allTimestamps), y: 0 },
                    { x: getMax(allTimestamps), y: 0 }
                  ]
                }
              ]
            },
            name: risk,
            options: _.merge(_.cloneDeep(this.barOptions), {
              // aspectRatio: this.props.showRevenueChart ? 14 : 8,
              plugins: {
                title: {
                  color: colour,
                  text: label
                }
              }
            })
            // maxVal: Math.max(allVals)
          }
        })
        .filter(bar => bar !== null)
    }
  }

  componentDidUpdate() {
    if (this.datasets && this.props.preview) {
      this.props.setDatasetsLength(this.datasets.length)
    }
  }

  downloadCSV = async () => {
    // Should use async here:
    const namedData = this.filteredData.map(row => {
      return [
        new Date(row[this.idxToKey.timestamp]),
        ...Object.entries(this.idxToKey).map(([key, idx]) => {
          // console.log(key, this.props.metadata)
          if (key in this.props.metadata) {
            // console.log(this.props.metadata[key])
            return this.props.metadata[key].find(el => el.val === row[idx])?.label || row[idx]
          } else {
            return row[idx]
          }
        })
      ]
    })
    const data = [['date', ...this.colNames], ...namedData]

    this.setState({ csvData: data }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click()
      })
    })
  }

  render() {
    this.processData()
    this.lineOptions.scales.xAxes.ticks.display = this.props.showAxes
    this.lineOptions.scales.yAxes.ticks.display = this.props.showAxes
    this.lineOptions.scales.xAxes.beginAtZero = !this.props.showAxes
    this.lineOptions.scales.yAxes.beginAtZero = !this.props.showAxes
    this.lineOptions.maintainAspectRatio = this.props.showRiskBars

    // _.merge(this.lineOptions.scales.xAxes, {
    //   grid: { display: this.props.showAxes }
    // })
    // this.lineOptions.scales.xAxes.display = this.props.showAxes
    const { csvData } = this.state
    const isPrintView = window.location.href.includes('printView')

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
        {this.props.showDownloadLink ? (
          <Box className="download" sx={{ position: 'absolute', right: 1, top: 1 }}>
            <IconButton
              aria-label="download"
              color="secondary"
              onClick={this.downloadCSV}
              size="small"
              sx={{
                '&:hover': { backgroundColor: 'primary.main', color: 'white' },
                backgroundColor: 'white',
                border: '1px solid #DDD'
              }}
              title="Download"
            >
              <DownloadIcon />
            </IconButton>
            <CSVLink data={csvData} filename="tenko_risk_data.csv" ref={this.csvLinkEl} />
          </Box>
        ) : null}
        {this.props.showRevenueChart && (
          <Line data={{ datasets: this.datasets }} options={this.lineOptions} />
        )}
        {this.props.showRiskBars && (
          <Box sx={{ height: this.props.showRevenueChart ? '35%' : '100%' }}>
            {this.riskBars.map(bar => (
              <Box key={bar.name} sx={{ height: '20%', position: 'relative' }}>
                <Bar data={bar.data} key={bar.name} options={bar.options} />
              </Box>
            ))}
          </Box>
        )}
        <ReportViewButton showButton={!this.props.preview && !isPrintView} />
      </Box>
    )
  }
}

RiskChart.defaultProps = {
  filters: {},
  metadata: {},
  showAxes: true,
  showDownloadLink: false,
  showRevenueChart: true,
  showRiskBars: true,
  strategyColours: [],
  usePercent: true
}

export default RiskChart
