import { AppContext } from 'App'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { notes } from '../content/notes'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useUpdateSettingsMutation } from '../api/user'

const Note = () => {
  const mutation = useUpdateSettingsMutation()
  const { settings } = useContext(AppContext)
  const { pathname } = useLocation()
  const note = notes[pathname]?.find(note => !settings[`note_${note.id}`])

  if (!note) return null

  const handleClose = () => mutation.mutate({ [`note_${note.id}`]: true })

  return (
    <Popover
      anchorPosition={note.position}
      anchorReference="anchorPosition"
      open={!settings[`note_${note.id}`]}
      sx={{ backgroundColor: '#00000033' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          sx={{
            fontSize: '16',
            fontWeight: 'bold',
            padding: [2, 2, 0, 2]
          }}
        >
          {note.title || ''}
        </Typography>
        <IconButton onClick={handleClose} size="large">
          <Close />
        </IconButton>
      </Box>
      <Typography
        component="div"
        sx={{
          fontSize: '16',
          fontWeight: 'bold',
          padding: [2, 2, 0, 2]
        }}
      >
        {note.content}
      </Typography>
    </Popover>
  )
}

export default Note
