import { lazy } from 'react'

import {
  CareersPage,
  CMSItemPage,
  CMSListPage,
  ContactPage,
  FAQPage,
  LandingPage,
  LegalPage,
  LoginPage,
  ResiliencePage,
  SignupPage,
  StrategyPage,
  TechPage,
  AboutUsPage
} from 'pages'

import i18next from 'i18next'

import routes from './routes'

const DataPage = lazy(() => import('pages/DataPage'))
const RiskPage = lazy(() => import('pages/RiskPage'))
const DashboardPage = lazy(() => import('pages/DashboardPage'))
const ProfilePage = lazy(() => import('pages/ProfilePage'))

const routeComponents = {
  [routes.aboutUs]: {
    component: <AboutUsPage />,
    label: i18next.t('about_us'),
    path: routes.aboutUs,
    public: true
  },
  [routes.dashboard]: {
    component: <DashboardPage />,
    label: i18next.t('dashboard'),
    path: routes.dashboard
  },
  [routes.data]: {
    component: <DataPage />,
    label: i18next.t('manage_data'),
    path: routes.data
  },
  [routes.faq]: {
    component: <FAQPage />,
    label: i18next.t('faq'),
    path: routes.faq,
    public: true
  },
  [routes.landing]: {
    component: <LandingPage />,
    label: '',
    path: routes.landing,
    public: true
  },
  [routes.legal]: {
    component: <LegalPage />,
    label: i18next.t('legal'),
    path: routes.legal,
    public: true
  },
  [routes.login]: {
    component: <LoginPage />,
    label: i18next.t('log_in'),
    path: routes.login,
    public: true
  },
  [routes.logout]: {
    label: i18next.t('log_out'),
    path: routes.logout
  },
  [routes.profile]: {
    component: <ProfilePage />,
    label: i18next.t('profile'),
    path: routes.profile
  },
  [routes.strategy]: {
    component: <StrategyPage />,
    label: i18next.t('strategy'),
    path: routes.strategy
  },
  [routes.resilience]: {
    component: <ResiliencePage />,
    label: i18next.t('build_resilience'),
    path: routes.resilience
  },
  [routes.careers]: {
    component: <CareersPage />,
    label: i18next.t('careers'),
    path: routes.careers,
    public: true
  },
  [routes.risk]: {
    component: <RiskPage />,
    label: i18next.t('analyse_risk'),
    path: routes.risk
  },
  [routes.riskTab]: {
    component: <RiskPage />,
    label: i18next.t('analyse_risk'),
    path: routes.riskTab
  },
  [routes.riskOpened]: {
    component: <RiskPage />,
    label: i18next.t('analyse_risk'),
    path: routes.riskOpened
  },
  [routes.news]: {
    component: <CMSListPage />,
    label: i18next.t('news'),
    path: routes.news,
    public: true
  },
  [routes.newsArticle]: {
    component: <CMSItemPage />,
    label: i18next.t('news'),
    path: routes.newsArticle,
    public: true
  },
  [routes.tech]: {
    component: <TechPage />,
    label: i18next.t('our_tech'),
    path: routes.tech,
    public: true
  },
  // [routes.blogs]: {
  //   component: CMSListPage,
  //   label: i18next.t('blog'),
  //   public: true
  // },
  // [routes.blog]: {
  //   component: CMSItemPage,
  //   label: i18next.t('blog'),
  //   public: true
  // },
  [routes.signup]: {
    component: process.env.REACT_APP_TENKO_DISABLE_SIGNUP ? <LoginPage /> : <SignupPage />,
    label: i18next.t('sign_up'),
    path: routes.signup,
    public: true
  },
  [routes.contact]: {
    component: <ContactPage />,
    label: i18next.t('contact'),
    path: routes.contact,
    public: true
  }
}

export default routeComponents
