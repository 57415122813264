import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

export const useInviteQuery = (user, company, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    payload => {
      return axios
        .post(`/user/${user.id}/invite/`, {
          company_id: company.id,
          ...payload
        })
        .then(({ data }) => data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(company.endpoint)
      },
      ...options
    }
  )
}
