// TODO: Move this at API level:
export const processError = error => {
  if (!error) return { msg: null }
  if (error.response?.status === 422) {
    const fieldErrors = error.response?.data?.errors?.json || {}
    // const fieldErrorStr = JSON.stringify(fieldErrors)
    return { fields: fieldErrors, msg: 'Validation failed…' }
  } else if (error.response) {
    if (error.response.data?.message) return { msg: error.response.data.message }
    else return { msg: `${error.response.status}: ${error.response.statusText}` }
  } else {
    return { msg: 'Network Error' }
  }
}
