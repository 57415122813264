// import { AppContext } from '../App'
import { Box, CircularProgress, Grid } from '@mui/material'
import { CreateButton } from './UI/CreateButton'
import PropTypes from 'prop-types'
// import { VisibilityTwoTone } from '@mui/icons-material'
// import { useAddUpdateSettingsMutation } from '../mutations/settings'
import {
  useCreateVendor,
  useDeleteVendor,
  useGetVendor,
  useGetVendors,
  useMutateVendor
} from '../api/vendors'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccordionItem from './UI/AccordionItem'
import Address from './Address'
import Fieldset from './UI/Fieldset'
import FilterInput from './UI/FilterInput'
import SectorCombo from './SectorCombo'

const VendorDetails = props => {
  const { handleChange, openedVendorFacility, vendor: initialVendor } = props
  const { data: vendor, isLoading } = useGetVendor(initialVendor, {
    initialData: initialVendor
  })
  const updateMutation = useMutateVendor(vendor)
  const deleteMutation = useDeleteVendor(vendor)

  return (
    <AccordionItem
      deleteMutation={deleteMutation}
      details={vendor.address?.country.display_name}
      dimmed={!!openedVendorFacility}
      entityName={vendor.name}
      entityNamePlaceholder="<unnamed vendor>"
      handleChange={handleChange(vendor)}
      isExpanded={vendor?.vendor_company?.facility.id === openedVendorFacility?.id}
      updateMutation={updateMutation}
    >
      <Fieldset>
        <SectorCombo
          disabled={isLoading}
          mutation={updateMutation}
          sector={vendor.sector}
          withToggle
        />
      </Fieldset>
      <Fieldset>
        <Address address={vendor.address} mutation={updateMutation} />
      </Fieldset>
    </AccordionItem>
  )
}

export const Vendors = ({ facility, selectedVendorFacility, setSelectedVendorFacility }) => {
  const { t } = useTranslation()
  // const { setSettings, settings } = useContext(AppContext)
  // const addUpdateSettingsMutation = useAddUpdateSettingsMutation({
  //   onSuccess: settings => setSettings(settings)
  // })
  const createMutation = useCreateVendor(facility)
  const { data: vendors, isLoading } = useGetVendors(facility, {
    enabled: !!facility
  })

  const [filteredVendors, setFilteredVendors] = useState(vendors)
  const [filterReset, setFilterReset] = useState(false)
  const [autoSelected, setAutoSelected] = useState(null)

  useEffect(() => {
    if (filteredVendors?.length === 1) setAutoSelected(filteredVendors[0].vendor_company.facility)
    else setAutoSelected(null)

    return () => setAutoSelected(null) // cleanup
  }, [filteredVendors])

  useEffect(() => {
    if (
      selectedVendorFacility &&
      !filteredVendors?.find(i => i.vendor_company.facility.id === selectedVendorFacility?.id)
    )
      setSelectedVendorFacility(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredVendors, setSelectedVendorFacility])

  useEffect(() => {
    if (
      selectedVendorFacility &&
      !filteredVendors?.find(i => i.vendor_company.facility.id === selectedVendorFacility?.id)
    )
      setFilterReset(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVendorFacility, setFilterReset])

  useEffect(() => {
    if (!vendors?.find(i => i.vendor_company.facility.id === selectedVendorFacility?.id))
      setSelectedVendorFacility(null)
  }, [vendors, setSelectedVendorFacility, selectedVendorFacility])

  const handleChange = vendor => (_, isExpanded) => {
    setSelectedVendorFacility(isExpanded ? vendor?.vendor_company?.facility : null)
  }

  // const addUpdateSettings = value => addUpdateSettingsMutation.mutate({ are_vendors_hidden: value })

  // if (settings.are_vendors_hidden) {
  //   return (
  //     <Box>
  //       <VisibilityTwoTone onClick={() => addUpdateSettings(false)} />
  //     </Box>
  //   )
  // }
  if (isLoading)
    return (
      <Box sx={{ backgroundColor: '#FFF', paddingTop: 3, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <Box sx={{ backgroundColor: '#FFF' }}>
      <Fieldset sx={{ paddingTop: 1, px: 1, textAlign: 'center' }}>
        <Grid container spacing={1}>
          <Grid item sx={{ display: !vendors?.length ? 'none' : 'inherit' }} xs>
            <FilterInput
              extraSearchString={v => v.name}
              filterReset={filterReset}
              items={vendors}
              setFilterReset={setFilterReset}
              setFilteredItems={setFilteredVendors}
            />
          </Grid>
          <Grid item xs>
            <CreateButton
              createMutation={createMutation}
              onSuccess={({ data }) => {
                setSelectedVendorFacility(data?.vendor_company?.facility)
              }}
              payload={{ name: '' }}
            >
              {t('add_vendor')}
            </CreateButton>
          </Grid>
        </Grid>
      </Fieldset>
      {filteredVendors?.map(vendor => (
        <VendorDetails
          facility={facility}
          handleChange={handleChange}
          key={vendor.id}
          openedVendorFacility={selectedVendorFacility || autoSelected}
          vendor={vendor}
        />
      ))}
    </Box>
  )
}

Vendors.propTypes = {
  facility: PropTypes.object.isRequired,
  selectedVendorFacility: PropTypes.object,
  setSelectedVendorFacility: PropTypes.func.isRequired
}
