import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { alpha } from '@mui/material/styles'

export default function TableList({ children, headers, isLoading, ...props }) {
  if (isLoading) return <LinearProgress />

  return children.length ? (
    <Table padding="none" {...props}>
      <TableHead sx={{ '& .MuiTableCell-root': { padding: 0.5 } }}>
        <TableRow>
          {headers.map((header, idx) => (
            <TableCell align="right" key={idx} sx={{ fontSize: '80%', lineHeight: 1.2 }}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          '& .Mui-selected, & .MuiTableRow-root.Mui-selected:hover': {
            backgroundColor: theme => alpha(theme.palette.secondary.main, 0.2)
          },
          '& .MuiTableCell-root': { padding: 0.5 },
          '& .MuiTableCell-root:first-of-type': { paddingLeft: 1 },
          '& .MuiTableCell-root:last-child': { width: 20 },
          '& .MuiTableRow-root:hover': {
            backgroundColor: theme => alpha(theme.palette.secondary.light, 0.2),
            cursor: 'pointer'
          }
        }}
      >
        {children}
      </TableBody>
    </Table>
  ) : null
}
