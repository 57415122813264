import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import QueryErrors from './QueryErrors'
import React from 'react'

export default function DeleteConfirmButton({
  actionLabel = 'Delete',
  btnSize = 'medium',
  deleteMutation,
  disableQueryErrors,
  mutationPayload = null,
  noMargin,
  onSuccess,
  targetLabel = 'this item',
  targetName
}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = cancel => {
    setOpen(false)
    if (!cancel)
      deleteMutation.mutateAsync(mutationPayload).then(data => {
        if (onSuccess) onSuccess(data)
      })
  }

  return (
    <>
      <IconButton
        aria-label={actionLabel}
        onClick={handleClickOpen}
        size={btnSize}
        sx={{ '&:hover': { color: 'error.main' }, color: '#666', margin: noMargin ? 0 : 0.5 }}
      >
        <DeleteIcon />
      </IconButton>
      {!disableQueryErrors && <QueryErrors mutation={deleteMutation} />}
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">
          {actionLabel} {targetLabel}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {targetLabel}:
            {targetName && <strong> {targetName}</strong>}?<br />
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="error" onClick={() => handleClose(false)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteConfirmButton.propTypes = {
  deleteMutation: PropTypes.object.isRequired
}
