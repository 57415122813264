import { ArrowDropDown, ArrowRight, Help } from '@mui/icons-material'
import { Box, Collapse, LinearProgress, Typography } from '@mui/material'
import { TreeItem, TreeView, useTreeItem } from '@mui/lab'
import { alpha, styled } from '@mui/material/styles'
import { animated, useSpring } from '@react-spring/web'
import { useGetOperation } from '../api/operations'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

// DEBUG:
// function later(delay) {
//   return new Promise(function (resolve) {
//     setTimeout(resolve, delay)
//   })
// }

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` }
  })
  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  )
}

const CustomContentRoot = styled(Box)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    borderRadius: 10,
    color: '#FFFFFF',
    fontWeight: theme.typography.fontWeightBold
  },
  '.label-action-text': {
    flexGrow: 1,
    fontStyle: 'italic',
    fontWeight: 'inherit'
  },
  '.label-icon': {
    marginRight: theme.spacing(1),
    opacity: '50%'
  },
  '.label-root': {
    alignItems: 'center',
    color: 'inherit',
    display: 'flex',
    fontWeight: 'inherit',
    padding: theme.spacing(0.5, 0)
  },
  '.label-text': {
    flexGrow: 1,
    fontWeight: 'inherit'
  },
  '.progress-bar': {
    minWidth: '90%',
    opacity: '50%'
  },
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'inherit',
  paddingRight: theme.spacing(1)
}))

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    LabelIcon,
    className,
    displayIcon,
    expansionIcon,
    icon: iconProp,
    isLoading,
    label,
    nodeId,
    onClick
  } = props

  const { disabled, expanded, focused, handleExpansion, handleSelection, selected } =
    useTreeItem(nodeId)

  const icon = iconProp || expansionIcon || displayIcon

  return (
    <CustomContentRoot
      className={clsx(className, {
        'Mui-disabled': disabled,
        'Mui-expanded': expanded,
        'Mui-focused': focused,
        'Mui-selected': selected
      })}
      onClick={onClick || handleSelection}
      ref={ref}
    >
      <Box onClick={handleExpansion} sx={{ width: '20px' }}>
        {icon}
      </Box>
      <div className="label-root">
        <LabelIcon className="label-icon" color="inherit" />
        {isLoading ? (
          <LinearProgress className="progress-bar" color="secondary" />
        ) : (
          <Typography className="label-text" variant="body2">
            {label}
          </Typography>
        )}
      </div>
    </CustomContentRoot>
  )
})

function OperationTreeItem(props) {
  const { cachedOp, expandedItems, selectItem, updateOperation, ...other } = props
  const { data: operation, dataUpdatedAt, isLoading } = useGetOperation(cachedOp)
  const labelIcon = cachedOp.labelIcon || Help
  // const isExpanded = expandedItems?.includes(cachedOp.endpoint)

  useEffect(() => {
    updateOperation(operation, dataUpdatedAt)
  }, [dataUpdatedAt, operation, updateOperation])

  // const creation = useCreateOperation(operation.childrenOpType, operation)

  return (
    <TreeItem
      ContentComponent={CustomContent}
      ContentProps={{ LabelIcon: labelIcon, isLoading }}
      TransitionComponent={TransitionComponent}
      label={
        <>
          {operation.name || (operation.placeholder ? `<${operation.placeholder}>` : '')}
          {operation.children?.length > 1 ? ` (${operation.children?.length})` : ''}
        </>
      }
      nodeId={cachedOp.endpoint}
      // onLabelClick={e => {
      //   if (isExpanded) e.preventDefault()
      // }}
      {...other}
    >
      {
        !isLoading &&
          operation.childrenOpType &&
          // operation.opType !== opTypes.FACILITY &&
          operation?.children?.map((c, idx) => (
            <OperationTreeItem
              cachedOp={c}
              expandedItems={expandedItems}
              key={c.endpoint}
              nodeId={c.endpoint}
              selectItem={selectItem}
              updateOperation={updateOperation}
            />
          ))
        // <TreeItem
        //   ContentComponent={CustomContent}
        //   ContentProps={{
        //     LabelIcon: Add,
        //     isLoading: isLoading,
        //     onClick: e => {
        //       e.preventDefault()
        //       creation.mutateAsync({ name: '' }).then(op => {
        //         selectItem(op.endpoint)
        //       })
        //     }
        //   }}
        //   TransitionComponent={TransitionComponent}
        //   key={`${cachedOp.endpoint}/add`}
        //   label={<span className="label-action-text">Add {operation.childrenOpType}</span>}
        //   nodeId={`${cachedOp.endpoint}/add`}
        // />
      }
    </TreeItem>
  )
}

const StyledTreeView = styled(TreeView)(({ theme }) => ({
  '.MuiTreeItem-group': {
    borderLeft: `1px dashed ${alpha(theme.palette.text.secondary, 0.2)}`
  },
  ".MuiTreeItem-root[aria-selected='true']": {
    backgroundColor: alpha('#EEF', 0.5),
    borderRadius: 10
  }
}))

const OperationTreeView = ({ operationTree, selectedItem, setOperationTree, setSelectedItem }) => {
  const [expandedItems, setExpandedItems] = useState([])
  const [endpointUpdatedAt, setEndpointUpdatedAt] = useState({})

  // Update tree
  const updateOperation = (operation, updatedAt) => {
    if (endpointUpdatedAt[operation.endpoint] === updatedAt) {
      return
    }

    const recurUpdate = (opTree, operation) => {
      if (opTree.endpoint === operation.endpoint) return operation
      return {
        ...opTree,
        children: opTree.children?.map(t => recurUpdate(t, operation)) || undefined
      }
    }

    setEndpointUpdatedAt(arr => ({ ...arr, [operation.endpoint]: updatedAt }))
    setOperationTree(oldTree => oldTree?.map(x => recurUpdate(x, operation)))

    // Deselect if item no longer exists:
    // if (!findRecursive(operationTree, op => op.endpoint === selectedItem)) {
    //   // setSelectedItem(null)
    //   setDisplayOp(null)
    // }
  }

  const shouldSetExpandedItems = expandedItems.length === 0
  useEffect(() => {
    if (operationTree) {
      if (shouldSetExpandedItems) {
        // Recursively grab all sub-items with only one child:
        const recurStartOpen = (accum, el) => {
          return el.children
            ? [
                ...(accum.length < 3 || el.children.length === 1 ? [el.endpoint] : []),
                ...el.children?.reduce(recurStartOpen, accum)
              ]
            : accum
        }
        setExpandedItems([...operationTree?.reduce(recurStartOpen, [])])
      }
    }
  }, [operationTree, shouldSetExpandedItems])

  // add selection to expanded set
  useEffect(() => {
    setExpandedItems(items => [...items, selectedItem])
  }, [selectedItem])

  return (
    <StyledTreeView
      defaultCollapseIcon={<ArrowDropDown fontSize="small" />}
      defaultExpandIcon={<ArrowRight fontSize="small" />}
      expanded={expandedItems}
      onFocus={e => {
        e.preventDefault()
      }}
      onNodeFocus={(e, val) => {
        if (val !== selectedItem) setSelectedItem(val)
        e.preventDefault()
      }}
      onNodeSelect={(e, val) => {
        if (val !== selectedItem) setSelectedItem(val)
      }}
      onNodeToggle={(e, val) => setExpandedItems(val)}
      selected={selectedItem || null}
    >
      {operationTree?.map((p, idx) => (
        <OperationTreeItem
          cachedOp={p}
          expandedItems={expandedItems}
          key={p.endpoint}
          selectItem={setSelectedItem}
          updateOperation={updateOperation}
        />
      ))}
    </StyledTreeView>
  )
}

export default OperationTreeView
