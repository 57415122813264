import { AppContext } from 'App'
import {
  Tab as TabPrimitive,
  Tabs,
  Box,
  IconButton,
  Drawer,
  ListItem,
  ListItemButton,
  List,
  ListItemText,
  Divider,
  Button
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link as RouterLink, generatePath, matchPath, useLocation } from 'react-router-dom'
import { logoutUser, useAuthDispatch, useAuthState } from 'Context'
import { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import routeComponents from './routeComponents'
import routes from './routes'
import { Menu as MenuIcon, Close } from '@mui/icons-material'
import i18next, { t } from 'i18next'
import { supportedLangs } from 'services/i18n'
import { sanitizePath } from 'utils/routing'

const HIDDEN_STYLE = {
  height: 0,
  maxWidth: 0,
  minWidth: 0,
  padding: 0,
  visibility: 'hidden'
}
const Tab = styled(TabPrimitive)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: HIDDEN_STYLE
}))

export const BottomNavigation = () => {
  const { isOnboarding } = useContext(AppContext)

  const { pathname } = useLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = [routes.aboutUs, routes.legal]
  const [selectedTab, setSelectedTab] = useState(false)

  useEffect(() => {
    // TODO: use router matching:
    if (!tabs.includes(pathname)) setSelectedTab(false)
    else setSelectedTab(pathname)
  }, [pathname, tabs])

  if (isOnboarding) return null

  return (
    <Tabs
      onChange={(_, value) => setSelectedTab(value)}
      sx={{
        '.MuiTabs-flexContainer': { justifyContent: 'flex-end' },
        backgroundColor: 'transparent'
      }}
      value={selectedTab}
    >
      {tabs.map(r => (
        <Tab
          component={RouterLink}
          key={r}
          label={routeComponents[r].label}
          to={generatePath(r)}
          value={r}
        />
      ))}
    </Tabs>
  )
}

const guestTab = [
  {
    anchor: 'our-services',
    href: '/#our-services-nav',
    label: t('our_services')
  },
  {
    anchor: 'use-cases',
    href: '/#use-cases-nav',
    label: t('use_cases')
  },
  { href: '/about-us', label: t('about_us') }
]

const ListItemLink = ({ label, onClick, path }) => {
  const usedLanguage = useMemo(() => {
    const locale = i18next?.language

    return locale?.split('-')[0]
  }, [])

  return (
    <ListItem>
      <ListItemButton component={RouterLink} hrefLang={usedLanguage} onClick={onClick} to={path}>
        <ListItemText>{label}</ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

export const MainNavigation = () => {
  const { t } = useTranslation()
  const dispatch = useAuthDispatch()
  const { isLoggedIn } = useAuthState()
  const { isOnboarding, operations, user } = useContext(AppContext)
  const { hash, pathname } = useLocation()

  const [strategyTabLabel, setStrategyTabLabel] = useState('Strategies')
  const [isMenuDrawerOpen, setMenuDrawerOpen] = useState(false)
  const loginDisabled = process.env.REACT_APP_TENKO_DISABLE_LOGIN
  // setStrategies(stratQueries.map(({ data }) => data).flat(1))
  // setStrategies([])
  const [strategies, setStrategies] = useState([])

  const handleClickLanguageSwitcher = useCallback(
    selectedLang => {
      if (usedLanguage !== selectedLang) {
        if (!pathname.includes(selectedLang)) {
          const newPath = supportedLangs.some(lang => pathname.includes(lang))
            ? pathname.replace(usedLanguage.toLowerCase(), selectedLang)
            : `/${selectedLang + pathname}`

          setTimeout(() => {
            window.location.replace(sanitizePath(newPath))
          }, 300)
        }
      }
    },
    [pathname, usedLanguage]
  )

  const usedLanguage = useMemo(() => {
    const locale = i18next?.language

    return locale?.split('-')[0]
  }, [])

  useEffect(() => {
    setStrategies(
      [
        operations?.map(p => p.strategies?.map(s => ({ ...s, opId: p.id, opType: p.opType }))),
        operations?.map(p =>
          p.companies?.map(c => c.strategies?.map(s => ({ ...s, opId: c.id, opType: c.opType })))
        )
      ]
        .flat(3)
        .filter(s => !!s)
    )
  }, [operations])

  const handleLogout = () => {
    setSelectedTab(false)
    logoutUser(dispatch)
  }

  const loggedInTabs = useMemo(
    () => [
      routes.dashboard,
      routes.data,
      routes.risk,
      routes.resilience,
      routes.strategy,
      routes.profile
    ],
    []
  )

  const [selectedTab, setSelectedTab] = useState(false)

  useEffect(() => {
    let tabPath = pathname

    setStrategyTabLabel('Strategies')
    const route = Object.entries(routes).find(([_, route]) =>
      matchPath({ exact: true, path: route }, pathname)
    )
    const match = route && matchPath({ path: route[1] }, pathname)

    if (match) {
      tabPath = match.pathname

      if (tabPath === routes.strategy) {
        const strategyParams = match.params
        tabPath = routes.strategy
        const strategy = strategies?.find(
          s =>
            s.id === Number(strategyParams.strategyId) &&
            s.opType === strategyParams.opType &&
            s.opId === Number(strategyParams.opId)
        )
        if (strategy) setStrategyTabLabel(`Strategy: ${strategy.name || '<unnamed strategy>'}`)
        else setStrategyTabLabel('Strategy')
      }
    }

    if (tabPath.split('/').length > 2) tabPath = `/${tabPath.split('/')[1]}`

    if (hash) {
      if (!hash.includes('-nav')) {
        tabPath = `/${hash}`
      } else {
        tabPath = false
      }
    }

    if (tabPath === routes.signup) tabPath = routes.login
    if (tabPath === routes.landing) tabPath = false
    setSelectedTab(tabPath)
  }, [pathname, strategies, hash])

  // const [anchorEl, setAnchorEl] = useState(null)
  // const showSfix: trategyMenu = event => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  return (
    <>
      <Drawer
        anchor="right"
        onClose={() => setMenuDrawerOpen(false)}
        open={isMenuDrawerOpen}
        sx={{ display: { sm: 'none', xs: 'initial' } }}
      >
        <Box
          sx={{
            ' a, span': {
              color: '#EFEFEF',
              fontSize: '16px',
              fontWeight: 700,
              textDecoration: 'none'
            },
            background: 'rgba(3,23,58,0.9)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '200px'
          }}
        >
          <IconButton
            onClick={() => setMenuDrawerOpen(false)}
            sx={{ alignSelf: 'flex-end', color: '#EFEFEF' }}
          >
            <Close />
          </IconButton>
          <List>
            {!isLoggedIn &&
              guestTab.map((item, idx) => (
                <ListItemLink
                  key={`drawer-menu-guest-${idx}`}
                  label={item.label}
                  onClick={() => {
                    setMenuDrawerOpen(false)
                  }}
                  path={item.href}
                />
              ))}
            {isLoggedIn &&
              loggedInTabs.map((path, idx) => (
                <ListItemLink
                  key={`drawer-menu-${idx}`}
                  label={
                    path === routes.profile
                      ? user?.first_name || user?.last_name
                        ? `${user.first_name || ''} ${user.last_name || ''}`
                        : t('profile')
                      : routeComponents[path].label
                  }
                  onClick={() => {
                    setMenuDrawerOpen(false)
                  }}
                  path={path}
                />
              ))}
            <ListItemLink
              label={routeComponents[routes.news].label}
              onClick={() => {
                setMenuDrawerOpen(false)
              }}
              path={routes.news}
            />
            <Divider sx={{ borderColor: 'rgba(255,255,255,0.5)' }} />
            {!isLoggedIn ? (
              <ListItemLink
                label="Log In"
                onClick={() => {
                  setMenuDrawerOpen(false)
                }}
                path="/login"
              />
            ) : (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    setMenuDrawerOpen(false)
                    handleLogout()
                  }}
                >
                  <ListItemText>Log Out</ListItemText>
                </ListItemButton>
              </ListItem>
            )}

            <Box sx={{ display: 'flex' }}>
              {supportedLangs.map(lang => (
                <ListItem key={`${lang}-drawer-option`} sx={{ width: '50px' }}>
                  <ListItemButton
                    disabled={usedLanguage === lang}
                    onClick={() => {
                      setMenuDrawerOpen(false)
                      handleClickLanguageSwitcher(lang)
                    }}
                    rel="canonical"
                    sx={{ ':hover': { background: 'transparent' } }}
                  >
                    <ListItemText
                      sx={{
                        color: usedLanguage === lang ? '#808080' : 'initial'
                      }}
                    >
                      {lang.toUpperCase()}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
          </List>
        </Box>
      </Drawer>
      <Tabs
        sx={{
          '.MuiTabs-flexContainer': {
            'a:first-of-type': {
              marginRight: 'auto'
            },
            alignItems: 'center',
            color: 'white',
            flex: 1,
            fontSize: '16px',
            justifyContent: 'flex-end'
          }
        }}
        value={selectedTab}
        variant="scrollable"
      >
        <TabPrimitive
          className="logo-tab"
          component={RouterLink}
          disableFocusRipple
          disableRipple
          hrefLang={usedLanguage}
          icon={<img alt="Tenko" src="/tenko_white.png" />}
          sx={{
            img: {
              height: '35px',
              marginBottom: '3px',
              marginRight: '10px',
              marginTop: '2px'
            },
            minWidth: 'inherit',
            paddingRight: 0
          }}
          to={generatePath(routes.landing)}
        />
        {!isLoggedIn &&
          guestTab.map((item, idx) => (
            <Tab
              component={RouterLink}
              hrefLang={usedLanguage}
              key={`guest-tab-${idx}`}
              label={item.label}
              sx={{ display: { sm: 'initial', xs: 'none' } }}
              to={item.href}
              value={item?.anchor ? `/#${item.anchor}` : item.href}
            />
          ))}
        {loggedInTabs.map(r =>
          r === routes.strategy ? (
            (!!strategies?.length || r === selectedTab) && (
              <Tab
                disabled={isOnboarding || !strategies}
                id="strategy-tab"
                key={r}
                label={strategyTabLabel}
                // onMouseDown={showStrategyMenu}
                value={r}
              />
            )
          ) : (
            <Tab
              component={RouterLink}
              disabled={isOnboarding}
              hrefLang={usedLanguage}
              key={r}
              label={
                r === routes.profile
                  ? user?.first_name || user?.last_name
                    ? `${user.first_name || ''} ${user.last_name || ''}`
                    : t('profile')
                  : routeComponents[r].label
              }
              sx={!isLoggedIn ? HIDDEN_STYLE : {}}
              to={generatePath(r)}
              value={r}
            />
          )
        )}
        {!isLoggedIn && (
          <Tab
            anchor="contact-us"
            component={RouterLink}
            hrefLang={usedLanguage}
            label={t('contact_us')}
            to="/#contact-us-nav"
            value="/#contact-us"
          />
        )}
        <Tab
          component={RouterLink}
          disabled={isOnboarding}
          hrefLang={usedLanguage}
          label={routeComponents[routes.news].label}
          to={generatePath(routes.news)}
          value={routes.news}
        />

        <Tab label={t('log_out')} onClick={handleLogout} sx={!isLoggedIn ? HIDDEN_STYLE : {}} />

        {(!loginDisabled || loginDisabled === '0') && (
          <Tab
            component={RouterLink}
            disabled={isOnboarding}
            hrefLang={usedLanguage}
            label={routeComponents[routes.login].label}
            sx={isLoggedIn ? HIDDEN_STYLE : {}}
            to={{
              pathname: generatePath(routes.login),
              state: { to: pathname }
            }}
            value={routes.login}
          />
        )}

        {!isLoggedIn &&
          supportedLangs.map(lang => (
            <Tab
              component={Button}
              disabled={usedLanguage === lang}
              key={`${lang}-option`}
              label={lang.toUpperCase()}
              onClick={() => handleClickLanguageSwitcher(lang)}
              rel="canonical"
              sx={{
                color: usedLanguage === lang ? '#808080 !important' : 'initial',
                display: { sm: 'initial', xs: 'none' },
                maxWidth: '50px !important',
                minWidth: '50px !important'
              }}
            />
          ))}
        <TabPrimitive
          component={IconButton}
          label={<MenuIcon />}
          onClick={() => setMenuDrawerOpen(!isMenuDrawerOpen)}
          sx={{ display: { sm: 'none', xs: 'initial' }, pt: '18px' }}
        />
      </Tabs>

      {/* <Menu
        MenuListProps={{ 'aria-labelledby': 'strategy-tab' }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        sx={{
          '& .MuiPaper-root': { backgroundColor: '#FFFFFFF4', borderRadius: 0, minWidth: '10em' }
        }}
      >
        {strategies?.map((s, idx) => (
          <Link
            component={RouterLink}
            key={idx}
            to={generatePath(routes.strategy, {
              opId: s.opId,
              opType: s.opType,
              strategyId: s.id
            })}
            underline="none"
          >
            <MenuItem key={idx} onClick={handleClose}>
              {s.name}
            </MenuItem>
          </Link>
        ))}
      </Menu> */}
    </>
  )
}
