import {
  Assessment,
  Business,
  BusinessCenter,
  Category,
  Input,
  LocationOn,
  Warehouse
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export const opTypes = {
  ASSET: 'asset',
  COMPANY: 'company',
  FACILITY: 'facility',
  INPUT: 'input',
  PORTFOLIO: 'portfolio',
  PRODUCT: 'product',
  VENDOR: 'vendor'
}

export const opMetadata = {
  top: { childrenLabel: 'portfolios' },
  [opTypes.PORTFOLIO]: {
    childrenLabel: 'companies',
    endpoint: '/operation/portfolio/',
    idLabel: 'portfolio_id',
    labelIcon: BusinessCenter,
    opType: opTypes.PORTFOLIO,
    placeholder: 'Unnamed Portfolio'
  },
  [opTypes.COMPANY]: {
    childrenLabel: 'assets',
    endpoint: '/operation/company/',
    idLabel: 'company_id',
    labelIcon: Business,
    opType: opTypes.COMPANY,
    placeholder: 'Unnamed Company'
  },
  [opTypes.ASSET]: {
    childrenLabel: 'facilities',
    endpoint: '/operation/asset/',
    idLabel: 'asset_id',
    labelIcon: Assessment,
    opType: opTypes.ASSET,
    placeholder: 'Unnamed Asset'
  },
  [opTypes.FACILITY]: {
    childrenLabel: 'products',
    endpoint: '/operation/facility/',
    idLabel: 'facility_id',
    labelIcon: LocationOn,
    opType: opTypes.FACILITY,
    placeholder: 'Unnamed Facility'
  },
  [opTypes.PRODUCT]: {
    childrenLabel: 'inputs',
    endpoint: '/operation/product/',
    idLabel: 'product_id',
    labelIcon: Category,
    opType: opTypes.PRODUCT,
    placeholder: 'Unnamed Product'
  },
  [opTypes.INPUT]: {
    childrenLabel: 'vendors',
    endpoint: '/supply_chain/input/',
    // TODO: give proper endpoint
    idLabel: 'input_id',
    labelIcon: Input,
    opType: opTypes.INPUT,
    placeholder: 'Unnamed Input'
  },
  [opTypes.VENDOR]: {
    endpoint: '/supply_chain/vendor/', // TODO: give proper endpoint
    idLabel: 'vendor_id',
    labelIcon: Warehouse,
    opType: opTypes.VENDOR,
    placeholder: 'Unnamed Vendor'
  }
}

const opStructure = [
  opMetadata.top,
  opMetadata[opTypes.PORTFOLIO],
  opMetadata[opTypes.COMPANY],
  opMetadata[opTypes.ASSET],
  opMetadata[opTypes.FACILITY],
  opMetadata[opTypes.PRODUCT],
  opMetadata[opTypes.INPUT],
  opMetadata[opTypes.VENDOR]
]

export const useGetOperation = (operation, options = {}) => {
  return useQuery(
    operation?.endpoint || '<disabled>',
    // () =>
    //   later(1000).then(() => {
    //     console.log(cachedOp)
    //     return cachedOp
    //   }),
    () => axios.get(operation?.endpoint).then(({ data }) => data),
    {
      cacheTime: 0,
      enabled: !!operation?.endpoint,
      initialData: operation,
      onSuccess: operation?.wrap,
      refetchOnMount: true,
      staleTime: Infinity,
      structuralSharing: false, // TODO: reenable…
      ...options
    }
  )
}

export const useGetPortfolio = (portfolioId, options = {}) => {
  const endpoint = `${opMetadata[opTypes.PORTFOLIO].endpoint}${portfolioId}`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    onSuccess: op => wrapOperation(op, opTypes.PORTFOLIO),
    ...options
  })
}

export const useGetCompany = (companyId, options = {}) => {
  const endpoint = `${opMetadata[opTypes.COMPANY].endpoint}${companyId}`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    onSuccess: op => wrapOperation(op, opTypes.COMPANY),
    ...options
  })
}

//   To do: merge handling of optimistic update with debounce handling (or hope that React Query adds it)
export const useMutateOperation = (
  operation,
  optimisticUpdate = true,
  reloadParent = false,
  options = {}
) => {
  const queryClient = useQueryClient()
  const mutationEvents = {}
  if (optimisticUpdate) {
    mutationEvents.onError = (error, payload, context) => {
      console.log(`Error: ${error}`)
      console.log(`rolling back optimistic update`)
      queryClient.setQueryData(operation.endpoint, context.previousData)
    }
    mutationEvents.onMutate = async payload => {
      await queryClient.cancelQueries(operation.endpoint)
      const previousData = queryClient.getQueryData(operation.endpoint)
      queryClient.setQueryData(operation.endpoint, old => ({ ...old, ...payload }))
      return { previousData }
    }
  }
  if (reloadParent) {
    mutationEvents.onSettled = () => {
      console.log('useMutateOperation onSettled setQueryData')
      if (operation.parent?.endpoint) queryClient.invalidateQueries(operation.parent.endpoint)
    }
  }

  return useMutation(payload => axios.put(operation.endpoint, payload), {
    ...mutationEvents,
    onSuccess: ({ data }) => {
      // console.log('useMutateOperation onsucess setQueryData')
      queryClient.setQueryData(operation.endpoint, data)
      // queryClient.setQueryData(operation.endpoint, operation.wrap(data))
    },
    ...options
  })
}

export const useCreateOperation = (opType, operationParent, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    payload => {
      return axios
        .post(opMetadata[opType].endpoint, {
          [operationParent.idLabel]: operationParent.id,
          ...payload
        })
        .then(({ data }) => wrapOperation(data, opType))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(operationParent.endpoint)
      },
      ...options
    }
  )
}

export const useDeleteOperation = (operation, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(() => axios.delete(operation.endpoint), {
    onSuccess: () => {
      operation.parent.children = operation.parent.children.filter(op => op.id !== operation.id)
      queryClient.setQueryData(operation.parent.endpoint, operation.parent)
      // console.log('invalidateQueries', operation.parent.endpoint)
      queryClient.invalidateQueries(operation.parent.endpoint)
    },
    ...options
  })
}

export const useMutateFinancial = (asset, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(payload => axios.post(`${asset.endpoint}/financial/`, payload), {
    onSettled: () => {
      queryClient.invalidateQueries(asset.endpoint)
    },
    ...options
  })
}

export const useMutateFixAsset = (asset, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(() => axios.post(`${asset.endpoint}/fix_facility_shares`), {
    onSettled: () => {
      queryClient.invalidateQueries(asset.endpoint)
    },
    ...options
  })
}

const wrapData = (node, structure, parent) => {
  // Recursively parse operation tree and:
  // - add endpoint
  // - add other metadata
  // - create 'children' and parse with wrapped versions of sub-entities

  const { endpoint, ...others } = structure[0]
  node.endpoint = `${endpoint}${node.id}`
  node.parent = parent
  Object.assign(node, others)
  node.wrap = n => wrapData(n, [...structure], parent)
  if (node.strategy_of && node.strategy_of.id) {
    node.baseline = {
      endpoint: `${endpoint}${node.strategy_of.id}`,
      wrap: n => wrapData(n, [...structure], null),
      ...node.strategy_of
    }
  }

  if (structure.length && structure[0].childrenLabel in node) {
    node.childrenOpType = structure[1].opType
    node.children = node[structure[0].childrenLabel].map(child =>
      wrapData(child, structure.slice(1), node)
    )
  }
  // node.component = 'test'

  return endpoint ? node : node.children
}

export const wrapOperation = (operation, opType) => {
  if (!opType) opType = operation.opType
  const idx = opStructure.findIndex(s => s.opType === opType)
  const structure = opStructure.slice(idx)
  return wrapData(operation, structure)
}

export const wrapPortfolios = portfolios => wrapData({ portfolios }, opStructure)
export const wrapCompanies = companies => wrapData({ companies }, opStructure.slice(1)).children
export const wrapAssets = assets => wrapData({ assets }, opStructure.slice(2)).children
export const wrapFacilities = facilities => wrapData({ facilities }, opStructure.slice(3)).children

export const useBulkExport = ({ fileName, params }) => {
  const endpoint = 'bulk/export'

  return useMutation(endpoint, () =>
    axios
      .get(endpoint, { params, responseType: 'blob' })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', `${fileName || 'data'}.json`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(error => console.log(error))
  )
}

export const useBulkImport = data => {
  const [result, setResult] = useState(null)
  const endpoint = 'bulk/import'
  const queryClient = useQueryClient()

  useEffect(() => {
    if (data) {
      const fileReader = new FileReader()
      fileReader.readAsText(data)
      fileReader.onload = e => {
        setResult(JSON.parse(e.target.result))
      }
    }
  })

  return useMutation(
    endpoint,
    () => axios.post(endpoint, result).catch(error => console.log(error)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useGetUserOperations')
      }
    }
  )
}
