import { CircularProgress, Grid, Slider, Typography } from '@mui/material'
import { opTypes, useGetOperation, useMutateOperation } from '../api/operations'
import { useTranslation } from 'react-i18next'
import Address from '../components/Address'
import Fieldset from './UI/Fieldset'
import OperationDetails from './OperationDetails'
import React, { useEffect, useState } from 'react'
import SectorCombo from './SectorCombo'
import ToggleContent from './UI/ToggleContent'

export default function FacilityDetails(props) {
  const { t } = useTranslation()
  const { baselineOp, cachedOp, selectItem } = props

  const { data: facility, isLoading } = useGetOperation(cachedOp)

  const mutation = useMutateOperation(facility)
  const mutationReloadAsset = useMutateOperation(facility, true, true)
  // const [selected, setSelected] = useState(null)
  const [shareRevenue, setShareRevenue] = useState(facility?.share_revenue)
  const [shareSpend, setShareSpend] = useState(facility?.share_spend)

  useEffect(() => {
    setShareRevenue(facility?.share_revenue)
    setShareSpend(facility?.share_spend)
  }, [facility])

  if (isLoading) return <CircularProgress />

  return (
    <OperationDetails
      createBtnProps={{
        opType: opTypes.PRODUCT
      }}
      data={facility}
      isLoading={isLoading}
      mutation={mutation}
      selectItem={selectItem}
    >
      <Fieldset>
        <SectorCombo
          baseline={baselineOp?.sector}
          mutation={mutation}
          sector={facility.sector}
          withToggle
        />
      </Fieldset>
      <Fieldset>
        <Address address={facility?.address} baseline={baselineOp?.address} mutation={mutation} />
      </Fieldset>
      <Fieldset dividerAfter>
        <ToggleContent
          closed={
            `${t('share_of_revenue')}: ${(facility?.share_revenue * 100).toFixed(1)}%, ` +
            `${t('share_of_spend')}: ${(facility?.share_spend * 100).toFixed(1)}%`
          }
          startOpen={false}
          // color={modified ? 'red' : null}
          title={t('share_of_revenue_spend')}
        >
          <Grid container>
            <Grid item sm={6} sx={{ px: 1.3 }}>
              <Typography>{t('revenue')}:</Typography>
              <Slider
                aria-label="Default"
                max={100}
                onChange={(_, val) => {
                  setShareRevenue(val / 100)
                }}
                onChangeCommitted={(_, val) => {
                  setShareRevenue(val / 100)
                  mutationReloadAsset.mutate({ share_revenue: shareRevenue })
                }}
                value={Math.round(shareRevenue * 100)}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item sm={6} sx={{ px: 1.3 }}>
              <Typography>{t('spend')}:</Typography>
              <Slider
                aria-label="Default"
                max={100}
                onChange={(_, val) => {
                  setShareSpend(val / 100)
                }}
                onChangeCommitted={(_, val) => {
                  setShareSpend(val / 100)
                  mutation.mutate({ share_spend: shareSpend })
                }}
                value={Math.round(shareSpend * 100)}
                valueLabelDisplay="auto"
              />
            </Grid>
          </Grid>
        </ToggleContent>
      </Fieldset>
    </OperationDetails>
  )
}
