import {
  AlexPic,
  ChrisPic,
  DavePic,
  GinaPic,
  AminePic,
  KaidlynePic,
  MaxPic,
  PierrePic,
  RachaPic
} from 'assets/team-images'

const tenkoTeam = [
  {
    description: [
      'Working with the team to make Tenko&apos;s vision a reality and ensuring that our platform aligns with the needs of our users. He has worked for decades in Artificial Intelligence, Machine Learning, and software development, across academia and industry.'
    ],
    description_fr: [
      "Travaille avec toute l&apos;équipe pour transformer la vision Tenko en réalité, et s'assurer que notre plateforme est parfaitement alignée avec les besoins de nos utilisateurs. Il a plusieurs décennies d'expérience en Intelligence Artificielle et développement logiciel, dans la recherche et le privé."
    ],
    img: DavePic,
    name: 'Dave duVerle',
    role: 'Showrunner',
    role_fr: 'Showrunner'
  },
  {
    description: [
      'Helping with company strategy and drafting our data products, based on his background in financial modelling and years of experience pricing financial risk.'
    ],
    description_fr: [
      'Aide à la stratégie de l&apos;entreprise et à la conception de nos produits data, grâce à son expérience en modélisation financière et la quantification des risques financiers.'
    ],
    img: PierrePic,
    name: 'Pierre Millet',
    role: 'Finance Models Maven',
    role_fr: 'Finance Models Maven'
  },
  {
    description: [
      'Developing machine learning models on climate change physical risks, working with up-to-date and state-of-the-art data.'
    ],
    description_fr: [
      "Développe des modèles d'apprentissage automatique sur les risques physiques du changement climatique, en rassemblant des données de pointe."
    ],
    img: MaxPic,
    name: 'Max Lutz',
    role: 'Data Magician',
    role_fr: 'Data Magician'
  },
  {
    description: ['Delivering a seamless experience for our platform end-users.'],
    description_fr: ['Assure une expérience fluide aux utilisateurs de notre plateforme Web.'],
    img: GinaPic,
    name: 'Gina Adzani',
    role: 'Frontend Rockstar',
    role_fr: 'Frontend Rockstar'
  },
  {
    description: [
      'Building data analytics products of global commodities exposure to climate-related hazards.'
    ],
    description_fr: [
      "Développe des produits d'analyse de données sur l&apos;exposition mondiale des matières premières aux risques climatiques."
    ],
    img: AminePic,
    name: 'Amine Farez',
    role: 'Master of Satellite Data',
    role_fr: 'Master of Satellite Data'
  },
  {
    description: [
      'Managing social media accounts, newsletters, and sharing Tenko&apos;s latest developments in climate risk management.'
    ],
    description_fr: [
      "Gère les comptes sociaux, les bulletins d'information et partage les dernières actualités de Tenko en matière de gestion des risques climatiques."
    ],
    img: KaidlynePic,
    name: 'Kaidlyne Neukam',
    role: 'Markitten Tamer',
    role_fr: 'Markitten Tamer'
  },
  {
    description: [
      'Building the backbone to our simulation engine, allowing us to capture the intricate relations between climate change physical risks.'
    ],
    description_fr: [
      'Construit la charpente de notre moteur de simulation, qui nous permet de capturer les relations complexes entre les risques physiques du changement climatique.'
    ],
    img: AlexPic,
    name: 'Alexandru Gherega',
    role: 'Statistics Wizard',
    role_fr: 'Statistics Wizard'
  },
  {
    description: [
      'Responsible for North American business development, and strategic guidance on our core product offering. Broad industry experience in analytics, finance, and product management.'
    ],
    description_fr: [
      'Responsable du développement commercial en Amérique du Nord, et de l&apos;orientation stratégique de notre offre de produits. Expérience variée dans l&apos;analyse, la finance et la gestion de produits.'
    ],
    img: ChrisPic,
    name: 'Chris Carothers',
    role: 'Business Development',
    role_fr: 'Business Development'
  },
  {
    description: ['Providing legal advice and assistance for day to day operations.'],
    description_fr: [
      'Fournit assistance juridique et encadrement légal au fonctionnement de l&apos;entreprise.'
    ],
    img: RachaPic,
    name: 'Racha Wylde',
    role: 'Legal Queen',
    role_fr: 'Legal Queen'
  }
]

export default tenkoTeam
