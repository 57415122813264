import { useQuery } from 'react-query'
import axios from 'axios'
import i18next from 'i18next'

export const useGetCMSCategories = () => {
  const endpoint = `${process.env.REACT_APP_CMS_REST_URL}/categories/`
  return useQuery(
    endpoint,
    () =>
      axios
        .get(endpoint)
        .then(({ data }) => data)
        .catch(error => console.log(error)),
    {
      staleTime: Infinity
    }
  )
}

export const useGetCMSTags = () => {
  const endpoint = `${process.env.REACT_APP_CMS_REST_URL}/tags/`
  return useQuery(
    endpoint,
    () =>
      axios
        .get(endpoint)
        .then(({ data }) => data)
        .catch(error => console.log(error)),
    {
      staleTime: Infinity
    }
  )
}

export const useGetCMSPostsByCategory = ({
  categoryId,
  currentPage,
  tags,
  params,
  options = {}
}) => {
  const endpoint = `${process.env.REACT_APP_CMS_REST_URL}/posts/`

  return useQuery(
    `${endpoint}-tag${tags}`,
    () =>
      axios
        .get(endpoint, {
          params: {
            _embed: true,
            categories: categoryId,
            page: currentPage,
            tags,
            ...params
          }
        })
        .then(res => {
          return {
            data: res.data,
            totalPage: res.headers['x-wp-totalpages']
          }
        })
        .catch(error => console.log(error)),
    {
      enabled: !!categoryId,
      refetchOnMount: 'always',
      staleTime: Infinity,
      ...options
    }
  )
}

export const useGetCMSPostBySlug = (slug, params = {}, options = {}) => {
  const endpoint = `${process.env.REACT_APP_CMS_REST_URL}/posts/`
  return useQuery(
    endpoint,
    () =>
      axios
        .get(endpoint, { params: { _embed: true, slug, ...params } })
        .then(({ data }) => (data.length > 0 ? data[0] : null)),
    {
      staleTime: Infinity,
      ...options
    }
  )
}

export const useGetCMSPageBySlug = (slug, params = {}, options = {}) => {
  const endpoint = `${process.env.REACT_APP_CMS_REST_URL}/pages/`

  return useQuery(
    `${endpoint}-${slug}`,
    () =>
      axios
        .get(endpoint, { params: { _embed: true, slug, ...params } })
        .then(({ data }) => (data.length > 0 ? data[0] : null)),
    {
      staleTime: Infinity,
      ...options
    }
  )
}

export const useGetCMSPageBySlugAndLang = (slug, params = {}, options = {}) => {
  const lang = i18next.language

  const resWithLang = useGetCMSPageBySlug(`${slug}_${lang}`, params, options)
  const fallbackRes = useGetCMSPageBySlug(slug, params, options)

  if (resWithLang?.data) return resWithLang

  return fallbackRes
}

export const useGetMediaDetail = id => {
  const endpoint = `${process.env.REACT_APP_CMS_REST_URL}/media/${id}`
  return useQuery(
    endpoint,
    () =>
      axios
        .get(endpoint)
        .then(({ data }) => data)
        .catch(error => console.log(error)),
    {
      enabled: !!id,
      staleTime: Infinity
    }
  )
}

export const useGetCMSPostByID = id => {
  const endpoint = `${process.env.REACT_APP_CMS_REST_URL}/posts/${id}`
  return useQuery(
    endpoint,
    () =>
      axios
        .get(endpoint)
        .then(({ data }) => data)
        .catch(error => console.log(error)),
    {
      enabled: !!id,
      staleTime: Infinity
    }
  )
}
