import { createTheme } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    // action: {
    //   active: '#FF0000',
    //   activeOpacity: 1,
    //   hover: '#FF0000',
    //   hoverOpacity: 0.7,
    //   selected: '#FF0000',
    //   selectedOpacity: 1
    // },
    background: { default: '#EDEDED' },

    error: { main: '#C5001B' },
    // primary: { contrastText: '#FEFDFC', light: '#487D9D', main: '#285D7D' },
    primary: { contrastText: '#FEFDFC', light: '#5B1E47', main: '#330B27' },
    secondary: { contrastText: '#FEFDFC', dark: '#01072A', light: '#030B63', main: '#02173A' },
    success: { main: '#169A39' },
    text: { primary: '#30353a' },
    warning: { main: '#FEB517' }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    body1: { fontSize: 14 },
    body2: { fontSize: '.9rem' },
    button: { fontSize: 14 },
    caption: { fontSize: 12 },
    display1: { fontSize: 14 },
    display2: { fontSize: 16 },
    display3: { fontSize: 18 },
    display4: { fontSize: 20 },
    fontFamily: 'Lato, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    h1: {
      fontSize: 48,
      fontWeight: 'bold',
      marginBottom: 15,
      marginTop: 20
    },
    h2: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 15
    },
    h3: {
      fontSize: 18,
      marginBottom: 10,
      marginTop: 12
    },
    h4: {
      color: '#555',
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 3,
      marginTop: 4
    },
    h5: {
      color: '#555',
      fontSize: 16,
      // fontVariant: 'all-small-caps',
      fontWeight: 700,
      marginBottom: 3,
      marginTop: 3
    },
    h6: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.75
      // marginBottom: 1
    },
    headline: { fontSize: 15 },
    subheading: { fontSize: 15 },
    title: { fontSize: 20 }
  }
})

theme = createTheme(theme, {
  // Not working:
  // props: { MuiLink: { underline: 'none' } },
  // components: {
  //   // Name of the component
  //   MuiInputLabel: {
  //     styleOverrides: {
  //       '.MuiInputLabel-sizeSmall': {
  //         zIndex: 0
  //       }
  //     }
  //   }
  // },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiToggleButton-root': {
            textTransform: 'none'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#42000f'
          },
          color: '#02112c',
          fontWeight: 700,
          letterSpacing: '-0.02em',
          textDecoration: 'none'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
          },
          '&.Mui-selected:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
          },
          color: '#333',
          fontWeight: 700,
          textTransform: 'none'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: { display: 'none' },
        root: {
          backgroundColor: '#DDD' // 'rgb(15,74,106)'
        }
      }
    }
  }
})

export default theme
