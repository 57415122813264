import {
  Assessment,
  Business,
  BusinessCenter,
  Input,
  LocationOn,
  Warehouse
} from '@mui/icons-material'

// CONSTANTS
export const PORTFOLIO_ID = 'portfolio_id'
export const COMPANY_ID = 'company_id'
export const ASSET_ID = 'asset_id'
export const FACILITY_ID = 'facility_id'
export const INPUT_ID = 'input_id'
export const VENDOR_ID = 'vendor_id'
export const PRODUCT_ID = 'product_id'
export const SUPPLIER_FACILITY_ID = 'supplier_facility_id'

export const PORTFOLIO = 'portfolio'
export const COMPANY = 'company'
export const ASSET = 'asset'
export const FACILITY = 'facility'
export const INPUT = 'input'
export const VENDOR = 'vendor'
export const PRODUCT = 'product'
export const SUPPLIER_FACILITY = 'supplier_facility'

export const getOperationsIds = ops => {
  const res = {}

  ops?.forEach(op => {
    if (!res[op.idLabel]) {
      res[op.idLabel] = [op.id]
    } else {
      res[op.idLabel].push(op.id)
    }

    if (op.children) {
      const childrenIds = getOperationsIds(op.children)
      Object.keys(childrenIds).forEach(key => {
        if (!res[key]) {
          res[key] = childrenIds[key]
        } else {
          res[key] = res[key].concat(childrenIds[key])
        }
      })
    }
  })

  return res
}

export const getOperationsIdsNames = ops => {
  const res = {}

  ops?.forEach(op => {
    if (!res[op.opType]) {
      res[op.opType] = [
        {
          children: op.children || null,
          id: op.id,
          name:
            op.name ||
            `${op.address.city}, ${op.address.state ? op.address.state + ', ' : ''}${
              op.address.country.display_name
            }`,
          suppliers: op.suppliers || null
        }
      ]
    } else {
      res[op.opType].push({
        children: op.children || null,
        id: op.id,
        name:
          op.name ||
          `${op.address.city}, ${op.address.state ? op.address.state + ',' : ''} ${
            op.address.country.display_name
          }`,
        suppliers: op.suppliers || null
      })
    }

    if (op.children) {
      const childrenIds = getOperationsIdsNames(op.children)
      Object.keys(childrenIds).forEach(key => {
        if (!res[key]) {
          res[key] = childrenIds[key]
        } else {
          res[key] = res[key].concat(childrenIds[key])
        }
      })
    }
  })

  return res
}

export const getOperationIcon = (opType, sx) => {
  switch (opType) {
    case PORTFOLIO_ID:
      return <BusinessCenter sx={sx} />
    case COMPANY_ID:
      return <Business sx={sx} />
    case ASSET_ID:
      return <Assessment sx={sx} />
    case FACILITY_ID:
      return <LocationOn sx={sx} />
    case INPUT_ID:
      return <Input sx={sx} />
    case VENDOR_ID:
      return <Warehouse sx={sx} />
    default:
      return null
  }
}
