import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AutosaveInput from './AutoSaveInput'
import DeleteConfirmButton from './DeleteConfirmButton'
import QueryErrors from './QueryErrors'

const AccordionItem = ({
  children,
  deleteMutation,
  details,
  dimmed,
  entityName,
  entityNamePlaceholder = '<unnamed>',
  handleChange,
  hideCollapse,
  isExpanded,
  updateMutation
}) => {
  const { t } = useTranslation()
  const summaryRef = useRef(null)
  useEffect(() => {
    if (isExpanded) summaryRef.current?.scrollIntoView(true)
  }, [isExpanded])

  // TODO: use hideCollapse to hide collapse button (when only one option)

  return (
    <Accordion
      disableGutters={true}
      expanded={isExpanded}
      onChange={handleChange}
      sx={{
        '&.Mui-expanded': {
          backgroundColor: '#F4F7FF',
          borderTop: '1px solid #AAA'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        ref={summaryRef}
        sx={{
          px: 1.5
        }}
      >
        <Grid
          alignItems="center"
          container
          spacing={0}
          sx={{ opacity: dimmed && !isExpanded ? 0.3 : 1.0 }}
        >
          {isExpanded ? (
            <>
              {deleteMutation?.isError && (
                <Grid item xs={12}>
                  <QueryErrors mutation={deleteMutation} />
                </Grid>
              )}
              <Grid item xs={10}>
                <AutosaveInput
                  autoFocus={!entityName}
                  fullWidth
                  label={t('name')}
                  mutation={updateMutation}
                  name="name"
                  onClick={e => e.stopPropagation()}
                  size="small"
                  value={entityName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <DeleteConfirmButton
                  deleteMutation={deleteMutation}
                  disableQueryErrors={true}
                  targetLabel="input"
                  targetName={entityName}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={8} sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                {entityName || entityNamePlaceholder}
              </Grid>
              {!!details && (
                <Grid item sx={{ color: 'text.secondary', fontSize: '80%' }} xs>
                  {details}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'block',
          paddingBottom: 0,
          paddingLeft: 1,
          paddingRight: 1
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionItem
