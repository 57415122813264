import { Grid } from '@mui/material'
import { useDeleteOperation } from '../../api/operations'
import DeleteConfirmButton from './DeleteConfirmButton'
import PropTypes from 'prop-types'
import React from 'react'

export default function DeleteOpButton(props) {
  const { gridItem, operation, selectItem } = props
  const opName = operation.opType
  const deletion = useDeleteOperation(operation)

  const button = (
    <DeleteConfirmButton
      deleteMutation={deletion}
      onSuccess={() => {
        if (selectItem) selectItem(operation.parent.endpoint)
      }}
      targetLabel={opName}
      targetName={operation.name || operation.placeholder}
    />
  )
  if (gridItem)
    return gridItem ? (
      <Grid item xs={deletion.isError ? 12 : 2}>
        {button}
      </Grid>
    ) : (
      button
    )
}

DeleteOpButton.propTypes = {
  operation: PropTypes.object.isRequired,
  selectItem: PropTypes.func.isRequired
}
