import { AppContext } from '../App'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ComboSelect from './UI/ComboSelect'
import PropTypes from 'prop-types'
import ToggleContent from './UI/ToggleContent'

const SectorCombo = ({ baseline, sector, withToggle, ...props }) => {
  const { t } = useTranslation()
  const { sectors } = useContext(AppContext)

  const combo = <ComboSelect items={sectors} name="sector_code" value={sector?.code} {...props} />
  const modified = baseline !== undefined && baseline?.code !== sector?.code

  if (withToggle)
    return (
      <ToggleContent
        closed={sector?.description}
        color={modified ? 'red' : null}
        startOpen={!sector?.code}
        title={t('sector')}
      >
        {combo}
      </ToggleContent>
    )
  else return combo
}

export default SectorCombo

SectorCombo.propTypes = {
  sector: PropTypes.object
}
