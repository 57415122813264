import { AuthReducer, initialState } from './reducer'
import { reloadSession } from './actions'
import React, { useEffect, useReducer } from 'react'

const AuthStateContext = React.createContext()
const AuthDispatchContext = React.createContext()

export function useAuthState() {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider')
  }

  return context
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  }

  return context
}

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(AuthReducer, initialState)

  const token = localStorage.getItem('token')
  if (token && !authState.isLoading && !authState.authUser?.id) dispatch({ type: 'REQUEST_LOGIN' })

  useEffect(() => {
    if (token) {
      reloadSession(dispatch, token)
    }
  }, [token])

  return (
    <AuthStateContext.Provider value={{ ...authState, isLoggedIn: !!authState.authUser?.id }}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}
