export const capitalizeFirstLetter = word => {
  if (word === null || word === undefined) return word

  if (word.length === 1) return word.toUpperCase()

  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export const capitalizeAndSplit = word => {
  if (word === null || word === undefined) return word

  return capitalizeFirstLetter(word).replaceAll('_', ' ')
}

export const formatFileName = word => {
  if (word === null || word === undefined) return word

  return word.toLowerCase().replaceAll(' ', '_')
}

const modifyStringConnector = (str, connector) => {
  if (!str) return null

  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join(connector)
}

export const toSnakeCase = str => {
  return modifyStringConnector(str, '_')
}

export const hyphenate = str => {
  return modifyStringConnector(str, '-')
}

export const getPrefix = str => {
  if (!str) return null

  if (!str.includes('-')) return str.toLowerCase()

  return str.split('-')[0].toLowerCase()
}

export const getLastChar = str => {
  if (!str) return null

  return str.slice(-1)
}

export const decodeHTMLEntities = text => {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = text
  return textArea.value
}
