import { AppContext } from '../App'
import { Box, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import { cloneElement, useContext, useEffect, useState } from 'react'
import { opTypes } from 'api/operations'
import { useIsPrintView } from 'hooks/useUrlQuery'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ThermostatIcon from '@mui/icons-material/Thermostat'

const RiskResilienceSelectorHeader = ({ children, isExperimental = false }) => {
  const { t } = useTranslation()
  const { isLoading, operations } = useContext(AppContext)
  const [operation, setOperation] = useState()
  const [selectedOp, setSelectedOp] = useState(null)
  const isPrintView = useIsPrintView()

  useEffect(() => {
    if (!operation && operations.length) {
      setOperation(operations[0])
      setSelectedOp(0)
    }
  }, [operations, operation])

  const [climateScenario, setClimateScenario] = useState('scenario_consensus')

  const currYear = new Date().getFullYear()

  const maxDate = new Date(`${currYear + 5}-12-31`)
  const minDate = new Date(`${currYear}-01-01`)
  const defaultEndDate = new Date(`${currYear + 2}-12-31`)
  const [startDate, setStartDate] = useState(minDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const handleChange = ({ target: { value } }) => setClimateScenario(value)
  const handleEndDateChange = date => setEndDate(date)
  const handleStartDateChange = date => setStartDate(date)

  return (
    <Box>
      {isExperimental && (
        <Box
          sx={{
            backgroundColor: 'secondary.main',
            borderRadius: 1,
            display: isPrintView ? 'none' : 'block',
            mb: 1,
            padding: 1
          }}
        >
          <Typography
            sx={{ color: 'primary.contrastText', fontStyle: 'italic', textAlign: 'center' }}
          >
            {t('experimental_feature')}
          </Typography>
        </Box>
      )}
      <Box
        mb={2}
        sx={{
          '.MuiInputLabel-root': {
            '&.Mui-disabled': {
              color: '#AAA',
              transform: 'translate(14px, -15px) scale(0.75)'
            },
            color: 'white',
            transform: 'translate(14px, -15px) scale(0.75)'
          },
          '.MuiOutlinedInput-root, .MuiOutlinedInput-input': {
            backgroundColor: 'secondary.contrastText'
          },
          backgroundColor: 'secondary.main',
          // borderColor: 'secondary.main',
          borderRadius: 1,

          display: isPrintView ? 'none' : 'block',
          // borderStyle: 'solid',
          // borderWidth: '2px',
          mx: 'auto',
          paddingBottom: 1.5,
          paddingTop: 3
          // zIndex: -100
        }}
      >
        <Grid alignContent="flex-end" container justifyContent="center" spacing={2}>
          <Grid item sx={{ '&.MuiGrid-item': { paddingTop: 1 } }}>
            {!!operation && (
              <TextField
                disabled={!operation}
                label=""
                margin="dense"
                select
                size="small"
                // value={`${operation?.opType}-${operation?.id}`}
                sx={{
                  '.MuiOutlinedInput-root': {
                    '.MuiSelect-select': {
                      alignItems: 'center',
                      display: 'flex',
                      py: '8px'
                    },
                    backgroundColor: 'secondary.main',
                    // fontSize: '120%',
                    fontWeight: 700
                    // height: '100%'
                  },
                  backgroundColor: 'secondary.main'
                  // height: '100%'
                }}
                value={selectedOp}
              >
                {operations.map((op, idx) => [
                  <MenuItem
                    key={idx}
                    onClick={() => {
                      setSelectedOp(idx)
                      setOperation(op)
                    }}
                    value={idx}
                  >
                    <op.labelIcon sx={{ color: '#999', mr: 1 }} />
                    {op.name || `<${op.placeholder}>`}
                  </MenuItem>,
                  ...(op.opType === opTypes.PORTFOLIO ? op.children : []).map((childOp, idx2) => (
                    <MenuItem
                      data-op={childOp}
                      key={[idx, idx2]}
                      onClick={e => {
                        setSelectedOp(`${idx}-${idx2}`)
                        setOperation(childOp)
                      }}
                      sx={{ paddingLeft: 5 }}
                      value={`${idx}-${idx2}`}
                    >
                      <childOp.labelIcon sx={{ color: '#999', mr: 1 }} />
                      {childOp.name || `<${childOp.placeholder}>`}
                    </MenuItem>
                  ))
                ])}
              </TextField>
            )}
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item>
              <DesktopDatePicker
                inputFormat="yyyy-MM-dd"
                label={t('from')}
                mask="____-__-__"
                maxDate={endDate}
                minDate={minDate}
                onChange={handleStartDateChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      input: {
                        '&.MuiOutlinedInput-input': { backgroundColor: 'transparent' },
                        height: '24px'
                      },
                      width: '10em'
                    }}
                  />
                )}
                value={startDate}
              />
            </Grid>
            <Grid item>
              <DesktopDatePicker
                inputFormat="yyyy-MM-dd"
                label={t('to')}
                mask="____-__-__"
                maxDate={maxDate}
                minDate={startDate}
                onChange={handleEndDateChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      input: {
                        '&.MuiOutlinedInput-input': { backgroundColor: 'transparent' },
                        height: '24px'
                      },
                      width: '10em'
                    }}
                  />
                )}
                value={endDate}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ThermostatIcon />
                  </InputAdornment>
                ),
                sx: { lineHeight: '24px' }
              }}
              disabled
              label={t('ngfs_climate_scenario')}
              onChange={handleChange}
              select
              // helperText="Please select"
              size="small"
              value={climateScenario}
            >
              <MenuItem value="scenario_consensus">{t('consensus')}</MenuItem>
              <MenuItem value="scenario_1_5C">{t('ngfs_scenario_1')}</MenuItem>
              <MenuItem value="scenario_2C">{t('ngfs_scenario_2')}</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>
      {cloneElement(children, {
        climateScenario,
        endDate,
        isLoadingOperation: isLoading,
        operation,
        startDate
      })}
    </Box>
  )
}

export default RiskResilienceSelectorHeader
