import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from '@mui/material'
import { processError } from 'api/error'
import { useTranslation } from 'react-i18next'
import QueryErrors from './QueryErrors'
import React, { useEffect, useState } from 'react'

export default function FormDialog({
  btnLabel,
  btnType,
  dialogContentText = '',
  dialogTitle,
  mutation,
  fixOverflowY,
  onSubmit,
  isLoading,
  onSuccess,
  setFormErrors,
  btnOptions = { color: 'primary', variant: 'outlined' },
  children
}) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (setFormErrors) setFormErrors(processError(mutation?.error).fields || {})
  }, [mutation?.error, setFormErrors])

  // const submitForm = formData => {
  //   const payload = {
  //     recipient_email: formData.recipient_email.value,
  //     recipient_first_name: formData.recipient_first_name.value,
  //     recipient_last_name: formData.recipient_last_name.value
  //   }
  //   mutation.mutateAsync(payload).then(() => setOpen(false))
  // }
  const ButtonEl = btnType || Button

  //   if (isLoading) return <CircularProgress />
  return (
    <>
      <Dialog
        PaperProps={fixOverflowY ? { style: { overflowY: 'visible' } } : {}}
        aria-describedby="dialog-description"
        aria-labelledby="dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={() => setOpen(false)}
        open={open}
      >
        <form
          action="POST"
          disabled={mutation.isLoading}
          onSubmit={e => {
            e.preventDefault()
            const payload = Object.values(e.target).reduce((obj, field) => {
              if (field.name) obj[field.name] = field.value
              return obj
            }, {})
            if (onSubmit)
              onSubmit(payload).then(() => {
                setOpen(false)
              })
            else {
              mutation.mutateAsync(payload).then(data => {
                setOpen(false)
                if (onSuccess) return onSuccess(data)
              })
            }
          }}
        >
          <DialogTitle>{dialogTitle || btnLabel}</DialogTitle>
          {mutation.isLoading && <LinearProgress />}
          <DialogContent
            style={fixOverflowY ? { overflowY: 'visible' } : {}}
            sx={{ '.MuiInputLabel-sizeSmall': { zIndex: 0 } }} // prevent interference w/ ComboSelect
          >
            <DialogContentText>{dialogContentText}</DialogContentText>
            <QueryErrors mutation={mutation} />
            {children}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              disabled={mutation?.isLoading || isLoading}
              onClick={() => setOpen(false)}
            >
              {t('cancel')}
            </Button>
            <Button color="primary" disabled={mutation?.isLoading || isLoading} type="submit">
              {btnLabel}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ButtonEl {...btnOptions} disabled={mutation.isLoading} onClick={() => setOpen(true)}>
        {btnLabel}
      </ButtonEl>
    </>
  )
}
