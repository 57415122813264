import { useQuery } from 'react-query'
import axios from 'axios'
import sortBy from 'lodash/sortBy'

export const useGetSectors = (options = {}) => {
  const endpoint = '/operation/sector/'
  return useQuery(
    endpoint,
    () =>
      axios.get(endpoint).then(({ data: sectors }) =>
        sortBy(
          sectors.map(s => ({ label: `${s.display_name}`, value: s.code })),
          ['label']
        )
      ),
    {
      staleTime: Infinity,
      ...options
    }
  )
}
