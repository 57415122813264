import { Alert, Box } from '@mui/material'

const NoDataAlert = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Alert
        severity="info"
        sx={{
          background: 'none',
          justifyContent: 'center',
          ml: 1,
          mt: 3,
          width: '150px'
        }}
      >
        No data
      </Alert>
    </Box>
  )
}

export default NoDataAlert
