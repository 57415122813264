import { LinearProgress, Box } from '@mui/material'
import { useAuthState } from 'Context'
import { AppContext } from 'App'
import { Navigate, Route, generatePath, Routes, useLocation } from 'react-router-dom'
import { NotFoundPage, OnboardingPage } from 'pages'
import { useContext, Suspense } from 'react'
import routeComponents from 'navigation/routeComponents'
import routes, { aliasRoutes } from 'navigation/routes'

const ProtectedRoute = ({ children }) => {
  const { isLoading: isLoggingIn, isLoggedIn } = useAuthState()
  const { pathname } = useLocation()

  if (isLoggingIn)
    return (
      <Box>
        <LinearProgress color="primary" />
      </Box>
    )

  return isLoggedIn ? (
    <Suspense fallback={<LinearProgress color="primary" />}>{children}</Suspense>
  ) : (
    <Navigate
      to={{
        pathname: generatePath(routes.login),
        state: { to: pathname }
      }}
    />
  )
}

const AppRoutes = () => {
  const { isOnboarding } = useContext(AppContext)
  const { isLoggedIn } = useAuthState()
  const { pathname } = useLocation()

  if (isLoggedIn && pathname === routes.landing)
    return <Navigate to={{ pathname: generatePath(routes.dashboard) }} />

  if (isOnboarding) {
    return <OnboardingPage />
  }

  return (
    <Routes>
      {Object.values(routeComponents)
        .filter(route => route?.component)
        .map(route =>
          route.public ? (
            <Route
              element={
                <Suspense fallback={<LinearProgress color="primary" />}>{route.component}</Suspense>
              }
              key={route.path}
              path={route.path}
            />
          ) : (
            <Route
              element={<ProtectedRoute>{route.component}</ProtectedRoute>}
              key={route.path}
              path={route.path}
            />
          )
        )}
      {aliasRoutes.map(route => (
        <Route
          element={<Navigate to={route.to} />}
          key={`redirect-${route.from}`}
          path={route.from}
        />
      ))}

      <Route element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
