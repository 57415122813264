import '@fontsource/lato'
import '@fontsource/roboto/400.css'
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material'
import { AuthProvider, useAuthState } from 'Context'
import { BottomNavigation, MainNavigation } from 'navigation/Navbars'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { StyledEngineProvider, ThemeProvider, styled } from '@mui/material/styles'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useGetCountries } from 'api/countries'
import { useGetCurrentUser, useGetSettings, useGetUserOperations } from 'api/user'
import { useGetSectors } from 'api/sectors'
import Note from 'components/Note'
import axios from 'axios'
import theme from 'AppTheme.js'
import useMediaQuery from '@mui/material/useMediaQuery'
import ScrollToTop from 'hoc/ScrollToTop'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { getPrefix } from 'utils/typography'
import AppRoutes from 'navigation/AppRoutes'

// setup
axios.defaults.baseURL = process.env.REACT_APP_TENKO_API_URL

export const AppContext = createContext()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 60000
    }
  }
})

const AppContextProvider = ({ children }) => {
  const [countries, setCountries] = useState([])
  const [sectors, setSectors] = useState([])
  const [settings, setSettings] = useState({})
  const [user, setUser] = useState({})
  const { authUser, isLoggedIn } = useAuthState()
  const [operations, setOperations] = useState([])

  const { data, isLoading } = useGetUserOperations(authUser, {
    enabled: !!authUser?.id
  })
  useEffect(() => {
    setOperations(data || [])
  }, [data])

  const setSettingsFromAPI = useCallback(
    data => setSettings(data.reduce((obj, s) => ({ ...obj, [s.key]: s.value }), {})),
    [setSettings]
  )

  useEffect(() => {
    const langOnUrl = window.location.pathname.split('/')[1]
    const currentLanguage = i18next.language
    const langPrefix = getPrefix(currentLanguage)

    if (settings?.language) {
      if (settings?.language !== 'default') {
        const settingLangPrefix = getPrefix(settings.language)
        // language on settings preference takes precedence
        if (langPrefix !== settingLangPrefix && langOnUrl !== settingLangPrefix) {
          i18next.changeLanguage(settingLangPrefix)
        }
      }
    }
  }, [settings?.language])

  useGetCurrentUser({
    enabled: !!authUser?.id,
    onSuccess: user => {
      setSettingsFromAPI(user.settings)
      setUser(user)
    }
  })

  useGetSettings(user, { enabled: !!user?.id, onSuccess: setSettingsFromAPI })

  useGetSectors({ enabled: isLoggedIn, onSuccess: setSectors })
  useGetCountries({ enabled: isLoggedIn, onSuccess: setCountries })

  useEffect(() => {
    if (!isLoggedIn) {
      console.log('Cleaning up session…')
      queryClient.getQueryCache().clear()
      queryClient.cancelMutations()
      queryClient.cancelQueries()
      setCountries([])
      setSettings({})
      setUser({})
    }
  }, [isLoggedIn])

  const appContext = useMemo(() => {
    const isOnboarding = user?.id && !settings.is_onboarding_complete
    return {
      countries,
      isLoading,
      isOnboarding,
      operations,
      sectors,
      settings,
      user
    }
  }, [operations, countries, sectors, settings, user, isLoading])

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
}

const Root = styled(Box)(({ theme: { spacing } }) => ({
  '&.tenko-page-data, &.tenko-page-strategy': {
    '.tenko-frame .tenko-content': {
      padding: spacing(2, 2, 0, 2)
    },
    '.tenko-wrapper': {
      height: `calc(100% - ${spacing(2)})`,
      position: 'absolute',
      width: `calc(100% - ${spacing(4)})`
    }
  },
  '&.tenko-page-landing': {
    '.MuiTab-textColorPrimary': { color: '#EFEFEF' },
    '.tenko-frame': {
      '.MuiAppBar-root': {
        '.MuiTabs-root': {
          position: 'fixed',
          zIndex: 99
        },
        background: 'transparent',
        boxShadow: 'none'
      },
      '.logo-tab': {
        visibility: 'hidden'
      },
      '.tenko-content': {
        padding: 0
      },
      backgroundColor: 'inherit'
    }
  },
  '&.tenko-page-login, &.tenko-page-signup': {
    '.tenko-frame': {
      '.tenko-content': {
        padding: 0
      }
    }
  },
  // '.MuiTab-textColorPrimary, .MuiTab-textColorPrimary.Mui-selected': {
  //   color: '#FAFFFC',
  //   fontWeight: 'bold'
  // },
  // '.MuiTab-textColorPrimary.Mui-selected': {
  //   backgroundColor: '#FFFFFF33'
  // },
  '.tenko-frame': {
    '.MuiToolbar-root>.MuiTabs-root': {
      '.Mui-selected': {
        backgroundColor: '#FFFFFF22'
      },
      '.MuiTab-root': {
        color: theme.palette.secondary.contrastText,
        fontSize: '110%'
      },
      '.MuiTabScrollButton-root': {
        color: 'white'
      },
      '.MuiTabs-indicator': {
        backgroundColor: theme.palette.secondary.contrastText,
        display: 'block',
        height: '3px'
      },
      // background:
      // 'linear-gradient(90deg, rgba(15,74,106,1) 0%, rgba(40,90,121,1) 11%, rgba(18,48,81,1) 49%, rgba(0,17,46,1) 100%)', // Teal gradient
      background:
        'linear-gradient(90deg, rgba(3,23,58,1) 0%, rgba(3,23,58,1) 25%, rgba(41,13,45,1) 75%, rgba(52,12,42,1) 100%)', // Prune gradient
      width: '100%'
    },

    '.tenko-content': {
      padding: spacing(2)
    }
    // backgroundColor: 'rgb(29, 67, 99)'
    // backgroundColor: '#E9E9E9'
  }
}))

const Page = () => {
  const { pathname } = useLocation()
  const routeName = useMemo(() => pathname.split('/')[1] || 'landing', [pathname])
  const printMedia = useMediaQuery('print')
  return (
    <Root
      className={`tenko-page-${routeName}`}
      tabIndex={0} // Prevent scrolling when focussed elements get hidden
    >
      <Box
        className="tenko-frame"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        {!printMedia && (
          <AppBar color="inherit" position="static">
            <Toolbar disableGutters>
              <MainNavigation />
            </Toolbar>
          </AppBar>
        )}
        <Note />
        <Box
          className="tenko-content"
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <Box className="tenko-wrapper">
            <AppRoutes />
          </Box>
        </Box>
      </Box>
      {!printMedia && <BottomNavigation />}
    </Root>
  )
}

export const baseUrl = () => {
  const langPrefix = i18next.language.includes('-')
    ? i18next.language.split('-')[0].toLowerCase()
    : i18next.language.toLowerCase()
  return '/' + langPrefix
}

const App = () => {
  const showDevtools = false
  const { t } = useTranslation()

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <title>Tenko – Face the Change</title>
          {/* Google / Search Engine Tags */}
          <meta content="Tenko" itemProp="name" />
          <meta content={t('tagline')} itemProp="description" />
          <meta content="https://tenko.ai/tenko_preview.png" itemProp="image" />

          {/* Facebook Meta Tags */}
          <meta content="https://tenko.ai" property="og:url" />
          <meta content="website" property="og:type" />
          <meta content="Tenkō" property="og:title" />
          <meta content={t('tagline')} property="og:description" />
          <meta content="https://tenko.ai/tenko_preview.png" property="og:image" />

          {/* Twitter Meta Tags */}
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="Tenkō" name="twitter:title" />
          <meta content={t('tagline')} name="twitter:description" />
          <meta content="https://tenko.ai/tenko_preview.png" name="twitter:image" />
        </Helmet>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <AppContextProvider>
                <BrowserRouter basename={baseUrl()}>
                  <ScrollToTop />
                  <CssBaseline />
                  <Page />
                </BrowserRouter>
              </AppContextProvider>
            </AuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
        {showDevtools && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
