import { Box, Button, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Something went wrong</Typography>
      </Box>
      <Box sx={{ mb: 1 }}>
        <Typography>{error?.message}</Typography>
      </Box>
      <Button onClick={resetErrorBoundary} variant="outlined">
        Try again
      </Button>
    </Box>
  )
}

export const FullPageErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '50vh',
        justifyContent: 'center'
      }}
    >
      <Box>
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Something went wrong</Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography>{error?.message}</Typography>
      </Box>

      <Button onClick={resetErrorBoundary} sx={{ width: '200px' }} variant="outlined">
        Try again
      </Button>

      <Link
        component={RouterLink}
        sx={{ '&:hover': { fontWeight: 'bold' }, mt: 3, textDecoration: 'none' }}
        to="/"
      >
        Go back to homepage
      </Link>
    </Box>
  )
}

export default ErrorFallback
