import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled(Box)(({ theme }) => ({
  '& .buttonMargin': {
    marginTop: theme.spacing.unit * 3
  },
  '& .content': {
    paddingBottom: theme.spacing.unit * 8,
    paddingTop: theme.spacing.unit * 8,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing.unit * 12
    }
  },
  '& .headline': {
    marginTop: theme.spacing.unit,
    maxWidth: 500,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    textAlign: 'center'
  },
  '& .hero': {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.type === 'light' ? theme.palette.primary.dark : theme.palette.primary.main,
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center',
    minHeight: '80vh'
  },
  '& .text': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  '& .title': {
    fontWeight: theme.typography.fontWeightLight,
    letterSpacing: '.7rem',
    textIndent: '.7rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: 28
    },
    whiteSpace: 'nowrap'
  },
  flex: '1 0 100%'
}))

const Hero = ({ actions, children, subTitle, title }) => {
  return (
    <Root>
      <div className="hero">
        <div className="content">
          <div className="text">
            <Typography
              align="center"
              className="title"
              color="inherit"
              component="h1"
              gutterBottom
              variant="display2"
            >
              {title}
            </Typography>
            <Typography
              className="headline"
              color="inherit"
              component="h2"
              gutterBottom
              variant="headline"
            >
              {subTitle}
            </Typography>
            {children}
            <div className="buttonMargin">{actions}</div>
          </div>
        </div>
      </div>
    </Root>
  )
}

export default Hero
