import { Box, Divider, Link, Stack, Typography } from '@mui/material'
import DataPage from './DataPage'
import { Link as RouterLink, generatePath, useParams } from 'react-router-dom'
import { opTypes, useGetCompany, useGetPortfolio } from 'api/operations'
import { useGetStrategy } from 'api/strategies'
import ErrorBoundaryWithFallback from 'components/ErrorBoundaryWithFallback'
import QueryErrors from 'components/UI/QueryErrors'
import routes from 'navigation/routes'

const PortfolioStrategyData = ({ isStrategyLoading, opId }) => {
  const { data: operation, isLoading: isOpLoading } = useGetPortfolio(opId, { enabled: !!opId })

  return (
    <DataPage
      isLoading={isStrategyLoading || isOpLoading}
      operations={!!operation && [operation]}
    />
  )
}

const CompanyStrategyData = ({ isStrategyLoading, opId }) => {
  const { data: operation, isLoading: isOpLoading } = useGetCompany(opId, { enabled: !!opId })

  return (
    <DataPage
      isLoading={isStrategyLoading || isOpLoading}
      operations={!!operation && [operation]}
    />
  )
}

const StrategyPageWithData = () => {
  const { opId, opType, strategyId } = useParams()
  const {
    data: strategy,
    error,
    isError,
    isLoading: isStrategyLoading
  } = useGetStrategy({ id: opId, opType }, strategyId, { enabled: !!strategyId })

  if (isError) return <QueryErrors error={error} />

  return (
    <>
      {!isStrategyLoading && (
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '3px',
            height: '3em',
            marginBottom: '1em',
            padding: '0.75em 1em 0.5em 1em'
          }}
        >
          <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} spacing={2}>
            <Box>
              <Typography sx={{ display: 'inline', textTransform: 'uppercase' }}>
                Resilience strategy:
              </Typography>{' '}
              <strong>{strategy?.name}</strong>
            </Box>
            <Box>
              <Link component={RouterLink} to={generatePath(routes.resilience)}>
                Compare strategies…
              </Link>
            </Box>
          </Stack>
        </Box>
      )}
      <Box sx={{ height: 'calc(100% - 4em)', position: 'relative' }}>
        {opType === opTypes.PORTFOLIO ? (
          <ErrorBoundaryWithFallback>
            <PortfolioStrategyData
              isLoading={isStrategyLoading}
              opId={strategy?.strategy_portfolio.id}
            />
          </ErrorBoundaryWithFallback>
        ) : (
          <ErrorBoundaryWithFallback>
            <CompanyStrategyData
              isLoading={isStrategyLoading}
              opId={strategy?.strategy_company.id}
            />
          </ErrorBoundaryWithFallback>
        )}
      </Box>
    </>
  )
}

export const StrategyPage = () => {
  return (
    <ErrorBoundaryWithFallback fullPageError>
      <StrategyPageWithData />
    </ErrorBoundaryWithFallback>
  )
}
