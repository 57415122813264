import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { CustomContainer } from 'layout/CustomContainer'
import tenkoTeam from 'components/AboutUs/tenkoTeam'
import { DefaultImg } from 'assets/team-images'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { decodeHTMLEntities } from 'utils/typography'

export const AboutUsPage = () => {
  const { t } = useTranslation()
  const lang = i18next.language

  return (
    <CustomContainer sx={{ background: 'white' }}>
      <Typography variant="h1">{t('about_us')}</Typography>
      <Box sx={{ my: 4 }}>
        <Typography variant="h3">{t('about_us_description_1')}</Typography>
        <Typography variant="h3">{t('about_us_description_2')}</Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography sx={{ fontSize: 36, my: 5 }} variant="h2">
          {t('about_us_team')}
        </Typography>

        <Grid container spacing={3} sx={{ margin: 'auto', mb: 4 }}>
          {tenkoTeam.map((team, idx) => {
            const description = team[`description_${lang}`] || team.description
            return (
              <Grid item key={`team-${idx}`} md={6} sx={{ my: 2, p: 4 }} xs={12}>
                <Card
                  sx={{
                    background: 'transparent',
                    border: 'none',
                    borderRadius: 0,
                    boxShadow: 'none',
                    display: 'flex',
                    gap: 4,
                    justifyItems: 'flex-start'
                  }}
                >
                  <CardMedia
                    component="img"
                    height={200}
                    image={team?.img || DefaultImg}
                    sx={{
                      maxHeight: { sm: '200px', xs: '100px' },
                      maxWidth: { sm: '200px', xs: '100px' },
                      objectFit: 'contain'
                    }}
                  />
                  <CardContent sx={{ p: 0 }}>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{team.name}</Typography>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {team[`role_${lang}`] || team.role}
                    </Typography>
                    {description?.map((d, index) => (
                      <Typography key={`team-${idx}-desc-${index}`} sx={{ my: 1 }}>
                        {decodeHTMLEntities(d)}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </CustomContainer>
  )
}
