import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

const PREFIX = 'SplitLayout'

const classes = {
  container: `${PREFIX}-container`,
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`
}

const StyledGrid = styled(Grid)(({ theme: { breakpoints, spacing } }) => ({
  [`&.${classes.container}`]: {
    height: 'calc(100vh - 56px)',
    [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 'calc(100vh - 48px)'
    },
    [breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px)'
    }
  },

  [`& .${classes.left}`]: {
    backgroundImage: 'url(/background.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },

  [`& .${classes.right}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: spacing(3, 4)
  }
}))

// local hooks

export const SplitLayout = ({ children }) => {
  return (
    <StyledGrid className={classes.container} container>
      <Grid className={classes.left} item md={7} sm={4} xs={false} />

      <Grid item md={5} sm={8} xs={12}>
        <div className={classes.right}>{children}</div>
      </Grid>
    </StyledGrid>
  )
}
