export const en = {
  translation: {
    about: 'About',
    about_us: 'About Us',
    about_us_description_1:
      'Tenkō was founded in 2021 to solve the business challenges of quantifying the impacts of climate volatility with state-of-the-art Machine Learning & Big Data. Our team combines decades of experience in data analytics and quantitative risk modelling.',
    about_us_description_2:
      'We are committed to leveraging data analytics technology to address the challenges of climate change.',
    about_us_team: 'Meet the Team',
    add: 'Add',
    add_input: 'Add Input',
    add_new_input: 'Add New Input',

    add_new_vendor: 'Add new Vendor',
    add_vendor: 'Add Vendor',
    analyse_risk: 'Analyse Risk',
    asset: 'Asset',
    assets: 'Assets',
    assign_vendor: 'Assign Vendor',
    back: 'Back',
    back_to_homepage: 'Go back to homepage',
    big_data: 'Big Data',
    blog: 'Blog',

    build_resilience: 'Build Resilience',
    // built_for_business: 'Built for Business',
    // built_for_business_description:
    //   'Understanding the financial impact on your business should not require Climate expertise. Our Frontend is built to support decision-making with Climate-impacted revenue-cost forecasts, not just asset risk-rating.',
    cancel: 'Cancel',
    carbon_costs: 'Carbon Costs',
    careers: 'Careers',
    change_language_success:
      'Language preference saved. Please refresh the page to apply the setting.',
    climate_related_risk: 'Climate-related Risk',
    collaborative_tools: 'Collaborative Tools',
    companies: 'Companies',
    company: 'Company',
    consensus: 'Consensus',
    contact: 'Contact',
    contact_us: 'Contact us',
    contact_us_excl: 'Contact us!',
    contact_us_subhead: 'Looking for next-generation business risk data analytics?',
    contact_us_title: 'Contact Us',

    continue: 'Continue',
    // corporate_lending: 'Corporate Lending',
    cost_forecast: 'Cost Forecast',
    dashboard: 'Dashboard',
    dataviz: 'Dataviz',
    decision_support: 'Decision-support',
    description: 'Description',
    drought_hazard_seasonality: 'Drought Hazard Seasonality',
    drought_risk: 'Drought Risk',
    economic_supply_chain_risk: 'Economic & Supply Chain Risk',
    email: 'Email',
    english: 'English',
    error_message: 'Something went wrong, please try again.',
    experimental_feature: 'Experimental feature',
    export_fail: 'Failed to export data',
    export_success: 'Data exported successfully',
    exposure_models: 'Exposure Models',
    external_data: 'External Data',
    external_models: 'External Models',
    facilities: 'Facilities',
    facility: 'Facility',
    facility_shares_warning: 'Facility shares of revenue-spend do not sum up to 100%',
    faq: 'FAQ',
    file_must_be_json: 'File must be in .json format',
    filter: 'Filter',
    financial_impact: 'Financial Impact',
    first_name: 'First Name',
    fix: 'Fix',
    flood_hazard_seasonality: 'Flood Hazard Seasonality',
    flood_risk: 'Flood Risk',
    footprint_assessment: 'Footprint Assessment',
    french: 'French',
    from: 'From',
    gross_profit: 'Gross Profit',
    hide_order_history: 'Hide order history',
    high: 'High',
    image_load_fail: 'Image could not be loaded',
    import_data_description: 'Data import will overwrite all your existing data.',
    import_fail: 'Failed to import data',
    import_from_json: 'Import data from JSON file',
    import_success: 'Data imported successfully',
    input: 'Input',
    input_disruption_risk: 'Input Disruption Risk',
    input_name: 'Input Name',
    input_vendors: 'Input Vendors',
    inputs: 'Inputs',
    insurance_costs: 'Insurance Costs',
    insurers: 'Insurers',
    inventory_days: 'Inventory Days',
    inventory_disruption_risk: 'Inventory Disruption Risk',
    inventory_management: 'Inventory Management',
    job_title: 'Job Title',
    landing_advantage_col_1: 'Innovative solutions to new challenges',
    landing_advantage_col_1_description:
      'We pioneered the use of large-scale data analytics and machine learning to quantify climate impacts on operations and supply chains.',
    landing_advantage_col_2: 'Unique expertise and powerful tools',
    landing_advantage_col_2_description:
      'Our team brings a unique combination of data expertise, supported by our proprietary analytics platform and models library.',
    landing_advantage_col_3: 'Bespoke analytics with an agile mindset',
    landing_advantage_col_3_description:
      'Our streamlined development process, tailored to each client’s needs, allows for fast cost-efficient solutions.',
    landing_advantage_description: '',
    landing_advantage_title: 'The Tenkō Advantage',
    landing_screen_1_description: '',
    landing_screen_1_title: 'The face of business risk is changing…',
    landing_screen_2_description:
      'Quantifying supply chain and operations disruption risk with high-resolution remote sensing data and Machine Learning models.',
    landing_screen_2_title: 'Tenkō: Data-Driven Risk Analytics for Business',
    landing_services_cta: 'to discuss how we can provide your business with bespoke risk analytics',
    landing_services_description_2: 'Delivering bespoke solutions using Tenkō’s platform',
    landing_services_tech_link: 'Read more',
    landing_services_tech_text: 'about our analytics platform',
    landing_use_cases_title: 'Supply Chain Risk Analytics: Client Use Cases',
    language_preference: 'Language Preference',
    last_name: 'Last Name',
    legal: 'Legal',
    load_more: 'Load more',
    log_in: 'Log In',
    log_out: 'Log Out',
    low: 'Low',
    manage_data: 'Manage Data',
    max_capacity: 'Max. Capacity',
    max_operation_risk: 'Max Operation Risk',
    medium: 'Medium',
    message: 'Message',
    name: 'Name',
    new_vendor: 'New vendor',
    news: 'News',
    ngfs_climate_scenario: 'NGFS Climate Scenario',
    ngfs_scenario_1: '1.5C rise by 2040',
    ngfs_scenario_2: '2C rise by 2040',
    no_articles_found: 'No articles found',
    no_operations_selected: 'No operations selected',
    no_risk_data: 'No risk data for selection',
    no_significant_exposure: 'No significant exposure',
    operation_disruption: 'Operation Disruption',
    operation_risk: 'Operation Risk',
    order_date: 'Order date',
    our_services: 'Our Services',
    password: 'Password',
    post_not_found: 'Post not found',
    private_market_investors: 'Private Market Investors',
    proceed_confirmation: 'Are you sure you want to proceed?',
    product: 'Product',
    products: 'Products',
    profile: 'Profile',
    projected: 'projected',
    public_institutions: 'Public Institutions',
    quantity: 'Quantity',
    read_more: 'Read more',
    regions: 'Regions',
    report_view: 'Report View',
    reporting: 'Reporting',
    revenue: 'Revenue',
    revenue_spend: 'Revenue-Spend',
    screen_1_col_1: 'Disruptions & Cost Increases',
    screen_1_col_1_description:
      'Climate volatility drives complex risks on operations and supply chains.',
    screen_1_col_2: 'Operational Threats',
    screen_1_col_2_description:
      'Impacts on transport, energy, commodities, & fiscal policies affect your efficiency & financial stability.',
    screen_1_col_3: 'Mitigation Challenges',
    screen_1_col_3_description: 'Quantification of impacts  is critical  to decision-making.',
    screen_2_col_1: 'Data Analytics',
    screen_2_col_1_description:
      'We model short-term economic and physical risks of climate volatility on operations & supply chains.',
    screen_2_col_2: 'Quantitative Models',
    screen_2_col_2_description:
      'We quantify impacts to determine exposure across regions, sectors and time.',
    screen_2_col_3: 'Decision Support',
    screen_2_col_3_description:
      'We deliver actionable business intelligence to support decision-making on risk mitigation.',
    screen_2_workflow_1: 'Business Data',
    screen_2_workflow_1_description: 'Known data on operations, procurement, suppliers…',
    screen_2_workflow_2: 'Tenkō Models',
    screen_2_workflow_2_description:
      'Climate-related risk, economic exposure, supply chain models.',
    screen_2_workflow_3: 'Quantification',
    screen_2_workflow_3_description:
      'Disruption and cost increase impacts across operations & supply chain.',
    sector: 'Sector',
    sectors: 'Sectors',
    send: 'Send',
    share_of_revenue: 'Share of Revenue',
    share_of_revenue_spend: 'Share of Revenue-Spend',
    share_of_spend: 'Share of Spend',
    show_order_history: 'Show order history',
    sign_up: 'Sign Up',
    small_medium_enterprises: 'Small & Medium Enterprises',
    specialist_models: 'Specialist Models',
    spend: 'Spend',
    spend_overview: 'Spend Overview',
    stakeholders: 'Stakeholders',
    stakeholders_clients: 'Stakeholders & Clients',
    strategy: 'Strategy',
    submission_confirmation_1: 'Thank you! We have sent a confirmation email to your address',
    submission_confirmation_2: 'and will be in touch shortly…',
    submission_error: 'Submission Error',
    summary: 'Summary',
    supplier_disruption_risk: 'Supplier Disruption Risk',
    suppliers_risk: 'Suppliers Risk',
    supply_chain: 'Supply Chain',
    supply_hazard_seasonality: 'Supply Hazard Seasonality',
    supply_risk: 'Supply Risk',
    system_preference: 'System Preference',
    tagline: 'Data-Driven Risk Analytics for Business',
    tech_page_contact_text:
      'to discuss how we can use our library of high-definition risk models and data infrastructure to provide you with bespoke risk analytics.',
    tech_page_desc: 'Science in the Back, Business in Front',
    tech_page_par_1:
      'In order to efficiently train and run our models, our team relies on our powerful proprietary platform.',
    tech_page_par_2:
      'Tenkō’s backend engine and model library can ingest terabytes of data across a large number of sectors, regions, and sources of risk, while our frontend dashboard allows business-friendly delivery of analytics to our users.',
    tech_page_title: 'Our Technology',
    tech_page_use_cases_link: 'Check out',
    tech_page_use_cases_text:
      'our client use cases, for some examples of what our risk modelling platform can achieve.',
    to: 'To',
    to_date: 'to date',
    try_again: 'Try again',
    unknown: 'Unknown',
    use_cases: 'Use Cases',
    vendor_name: 'Vendor Name',
    wind_hazard_seasonality: 'Wind Hazard Seasonality',
    wind_risk: 'Wind Risk'
  }
}
