// exported constants

export const notes = {
  '/risk': [
    // {
    //   content: 'Risk assessment…',
    //   id: 'adjust-dates',
    //   position: { left: 400, top: 50 },
    //   title: 'You can adjust the date selection…'
    // }
    // {
    //   content: 'Here you can adjust the dates…',
    //   id: 'adjust-dates',
    //   position: { left: 128, top: 128 }
    // }
  ]
}
