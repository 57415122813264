export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

export const findItemNested = (arr, comparatorKey, comparatorValue) =>
  arr.reduce((a, item) => {
    if (a) return a
    if (item[comparatorKey] === comparatorValue) return item
    if (item?.children) return findItemNested(item.children, comparatorKey, comparatorValue)
    return null
  }, null)
