import { IconButton } from '@mui/material'
import HighlightOff from '@mui/icons-material/HighlightOff'
import PropTypes from 'prop-types'
import React from 'react'

export default function DeleteButton({ deleteMutation, label, mutationParams }) {
  return (
    <>
      <IconButton
        aria-label={label || 'Delete'}
        disabled={deleteMutation.isLoading}
        onClick={() => deleteMutation.mutate(...(mutationParams || []))}
        size="small"
        sx={{ '&:hover': { color: 'error.main' } }}
      >
        <HighlightOff />
      </IconButton>
    </>
  )
}

DeleteButton.propTypes = {
  deleteMutation: PropTypes.object.isRequired
}
