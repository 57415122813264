import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

export const useGetVendors = (facility, options = {}) => {
  const endpoint = `/supply_chain/vendor/facility/${facility.id}/`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useGetVendor = (vendor, options = {}) => {
  const endpoint = `/supply_chain/vendor/${vendor.id}`
  return useQuery(endpoint, () => axios.get(endpoint).then(({ data }) => data), {
    staleTime: Infinity,
    ...options
  })
}

export const useMutateVendor = (vendor, options = {}) => {
  const endpoint = `/supply_chain/vendor/${vendor.id}`
  const queryClient = useQueryClient()
  return useMutation(payload => axios.put(endpoint, payload), {
    onSettled: () => {
      // queryClient.invalidateQueries(parentEndpoint)
      queryClient.invalidateQueries(endpoint)
    },
    ...options
  })
}

export const useDeleteVendor = (vendor, options = {}) => {
  const endpoint = `/supply_chain/vendor/${vendor.id}`
  const queryClient = useQueryClient()
  return useMutation(() => axios.delete(endpoint), {
    onSuccess: () => {
      const parentEndpoint = `/supply_chain/vendor/facility/${vendor.client_facility.id}/`
      queryClient.invalidateQueries(parentEndpoint)
    },
    ...options
  })
}

export const useCreateVendor = (facility, options = {}) => {
  const parentEndpoint = `/supply_chain/vendor/facility/${facility.id}/`
  const queryClient = useQueryClient()
  return useMutation(payload => axios.post(parentEndpoint, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(parentEndpoint)
    },
    ...options
  })
}
