// import React, { useReducer, useState } from 'react'

export const initialState = {
  authUser: '',
  error: null,
  isLoading: false,
  token: ''
}

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        isLoading: true
      }
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        authUser: action.payload.authUser,
        isLoading: false,
        token: action.payload.token
      }
    case 'LOGOUT':
      return {
        ...initialState,
        authUser: '',
        token: ''
      }

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        authUser: '',
        error: action.error,
        isLoading: false,
        token: ''
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
