import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback, { FullPageErrorFallback } from 'components/ErrorFallback'

const ErrorBoundaryWithFallback = ({ children, customErrorFallback, fullPageError = false }) => {
  return (
    <ErrorBoundary
      fallbackRender={
        customErrorFallback || (fullPageError ? FullPageErrorFallback : ErrorFallback)
      }
    >
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWithFallback
