export const fr = {
  translation: {
    about: 'À propos',
    about_us: 'À propos de Tenko',
    about_us_description_1:
      "Tenkō est né en 2021 pour répondre aux challenges de la quantification des impacts de la volatilité climatique avec des technologies de Machine Learning et Big Data de pointe. Notre équipe combine des décennies d'expérience en analyse de données et modélisation quantitative du risque.",
    about_us_description_2:
      "Nous sommes engagés à exploiter les technologies d'analyse de données pour relever les défis du changement climatique.",
    about_us_team: "L'équipe Tenkō",
    add: 'Add',
    add_input: 'Add Input',
    add_new_input: 'Add New Input',

    add_new_vendor: 'Add new Vendor',
    add_vendor: 'Add Vendor',
    analyse_risk: 'Analyse Risk',
    asset: 'Asset',
    assets: 'Assets',
    assign_vendor: 'Assign Vendor',
    back: 'Retour',
    back_to_homepage: 'Retour à la page d’accueil',
    big_data: 'Big Data',
    blog: 'Blog',
    build_resilience: 'Build Resilience',
    cancel: 'Cancel',
    carbon_costs: 'Carbon Costs',
    careers: 'Recrutement',
    change_language_success:
      'Language preference saved. Please refresh the page to apply the setting.',
    climate_related_risk: 'Climate-related Risk',
    collaborative_tools: 'Outils collaboratifs',
    companies: 'Companies',
    company: 'Société',
    consensus: 'Consensus',
    contact: 'Contact',
    contact_us: 'Contactez nous',
    contact_us_excl: 'Contactez nous!',
    contact_us_subhead:
      "À la recherche de solutions de pointe pour le traitement des données et l'analyse du risque ?",
    contact_us_title: 'Nous contacter',
    continue: 'Continue',
    cost_forecast: 'Cost Forecast',
    dashboard: 'Dashboard',
    dataviz: 'Dataviz',
    decision_support: 'Support à la décision',
    drought_hazard_seasonality: 'Drought Hazard Seasonality',
    drought_risk: 'Drought Risk',
    economic_supply_chain_risk: 'Risques Economiques & Supply Chain',
    email: 'Email',
    english: 'Anglais',
    error_message: 'Something went wrong, please try again.',
    experimental_feature: 'Experimental feature',
    export_fail: 'Failed to export data',
    export_success: 'Data exported successfully',
    exposure_models: 'Modèles d’exposition',
    external_data: 'Données externes',
    external_models: 'Modèles externes',
    facilities: 'Implantations',
    facility: 'Implantation',
    facility_shares_warning: 'Facility shares of revenue-spend do not sum up to 100%',
    faq: 'FAQ',
    file_must_be_json: 'File must be in .json format',
    filter: 'Filter',
    financial_impact: 'Impact financier',
    first_name: 'Prénom',
    fix: 'Fix',
    flood_hazard_seasonality: 'Flood Hazard Seasonality',
    flood_risk: 'Flood Risk',
    footprint_assessment: 'Footprint Assessment',
    french: 'French',
    from: 'From',
    gross_profit: 'Gross Profit',
    high: 'High',
    image_load_fail: 'Image could not be loaded',
    import_data_description: 'Data import will overwrite all your existing data.',
    import_fail: 'Failed to import data',
    import_from_json: 'Import data from JSON file',
    import_success: 'Data imported successfully',
    input: 'Input',
    input_disruption_risk: 'Input Disruption Risk',
    input_name: 'Input Name',
    input_vendors: 'Input Vendors',
    inputs: 'Inputs',
    insurance_costs: "Coûts d'assurance",
    insurers: 'Assureurs',
    inventory_days: 'Inventory Days',
    inventory_disruption_risk: 'Inventory Disruption Risk',
    inventory_management: 'Inventory Management',
    job_title: 'Profession',
    landing_advantage_col_1: 'Des solutions innovantes pour des challenges émergents',
    landing_advantage_col_1_description:
      "Grace aux techniques de machine learning et d'analyses de données satellites modernes, nous pouvons quantifier les impacts sur les opérations et les supply chains.",
    landing_advantage_col_2: 'Une expertise unique et des outils performants',
    landing_advantage_col_2_description:
      "Notre équipe rassemble une combinaison unique d'expertises autour de la donnée, épaulée par notre plateforme d'analyse et notre bibliothèque de modèles.",
    landing_advantage_col_3: 'Analyses sur mesure, avec une approche agile',
    landing_advantage_col_3_description:
      "Notre processus de dévelopement agile, façonné autour des besoins de chaque client, nous permet de d'offrir des solutions rapides et adaptées aux contraintes budgétaires.",
    landing_advantage_description: '',
    landing_advantage_title: 'L’avantage Tenkō',
    landing_screen_1_description: '',
    landing_screen_1_title: 'Les nouveaux risques business…',
    landing_screen_2_description:
      "Quantifier les risques de perturbation sur la supply chain et les opérations, avec des données satellites haute-résolution et des modèles d'apprentissage automatique.",
    landing_screen_2_title: 'Tenkō: quantifier le risque pour les entreprises',
    landing_services_cta: 'pour échanger sur vos besoins en analyses de risque',
    landing_services_description_2: 'Des solutions sur mesure, grace à la plateforme Tenkō',
    landing_services_tech_link: 'Plus de détails',
    landing_services_tech_text: 'sur notre plateforme analytique',
    landing_use_cases_title: 'Analyse de risques Supply Chain : cas d’étude client',
    language_preference: 'Rêglage langue',
    last_name: 'Nom',
    legal: 'Mentions légales',
    load_more: 'Load more',
    log_in: 'Se connecter',
    log_out: 'Se déconnecter',
    low: 'Low',
    manage_data: 'Manage Data',
    max_capacity: 'Max. Capacity',
    max_operation_risk: 'Max Operation Risk',
    medium: 'Medium',
    message: 'Message',
    name: 'Nom',
    new_vendor: 'New vendor',
    news: 'Articles',
    ngfs_climate_scenario: 'NGFS Climate Scenario',
    ngfs_scenario_1: '1.5C rise by 2040',
    ngfs_scenario_2: '2C rise by 2040',
    no_articles_found: 'No articles found',
    no_operations_selected: 'No operations selected',
    no_risk_data: 'No risk data for selection',
    no_significant_exposure: 'No significant exposure',
    operation_disruption: 'Perturbations des opérations',
    operation_risk: 'Risques opérationnels',
    our_services: 'Nos solutions',
    password: 'Mot de passe',
    post_not_found: 'Post not found',
    private_market_investors: 'Investisseurs privés',
    proceed_confirmation: 'Are you sure you want to proceed?',
    product: 'Product',
    products: 'Products',
    profile: 'Profile',
    projected: 'projected',
    public_institutions: 'Institutions publiques',
    quantity: 'Quantity',
    read_more: 'Lire la suite',
    regions: 'Regions',
    report_view: 'Report View',
    reporting: 'Reporting',
    revenue: 'Revenue',
    revenue_spend: 'Revenue-Spend',
    screen_1_col_1: 'Perturbations & Coûts',
    screen_1_col_1_description:
      'La volatilité climatique entraîne des risques complexes sur les opérations et les supply chains.',
    screen_1_col_2: 'Menaces opérationnels',
    screen_1_col_2_description:
      'Impacts sur le transport, l’énergie, les matières premières et les politiques fiscales, menacent votre efficacité et stabilité financière',
    screen_1_col_3: 'Challenges de la résilience',
    screen_1_col_3_description: 'Quantifier les impacts est crucial pour construire sa résilience.',
    screen_2_col_1: 'Analytiques Données',
    screen_2_col_1_description:
      'Nous modélisons les risques physique et économiques à court-terme causés par la volatilité climatique sur les opérations et les chaînes d’approvisionnement.',
    screen_2_col_2: 'Modèles quantitatifs',
    screen_2_col_2_description:
      'Nous quantifions les impacts par implantations géographiques, secteurs et périodes.',
    screen_2_col_3: 'Support à la décision',
    screen_2_col_3_description:
      'Nos apportons les informations nécessaires pour aider la décision et construire la résilience.',
    screen_2_workflow_1: 'Données entreprise',
    screen_2_workflow_1_description: 'Données sur les implantations, achats, fournisseurs…',
    screen_2_workflow_2: 'Modèles Tenkō',
    screen_2_workflow_2_description:
      'Modèles de risques climat, macro-économiques et supply chain.',
    screen_2_workflow_3: 'Quantification',
    screen_2_workflow_3_description:
      'Perturbations et augmentation des coûts sur les opérations et la supply chain.',
    sector: 'Sector',
    sectors: 'Sectors',
    send: 'Envoyer',
    share_of_revenue: 'Share of Revenue',
    share_of_revenue_spend: 'Share of Revenue-Spend',
    share_of_spend: 'Share of Spend',
    sign_up: 'Nouveau compte',
    small_medium_enterprises: 'Small & Medium Enterprises',
    specialist_models: 'Specialist Models',
    spend: 'Spend',
    spend_overview: 'Spend Overview',
    stakeholders: 'Parties prenantes',
    stakeholders_clients: 'Partie prenantes & Clients',
    strategy: 'Strategy',
    submission_confirmation_1:
      "Merci! Nous avons envoyé un email de confirmation à l'adresse que vous avez fournie",
    submission_confirmation_2: 'et nous vous contacterons sous peu.',
    submission_error: 'Submission Error',
    summary: 'Summary',
    supplier_disruption_risk: 'Supplier Disruption Risk',
    suppliers_risk: 'Suppliers Risk',
    supply_chain: 'Supply Chain',
    supply_hazard_seasonality: 'Supply Hazard Seasonality',
    supply_risk: 'Supply Risk',
    system_preference: 'System Preference',
    tagline: 'IA et modélisation pour la résilience des entreprises',
    tech_page_contact_text:
      'pour discuter de la façon dont nous pouvons utiliser notre bibliothèque de modèles et notre infrastructure pour vous fournir des analyses de risques sur mesure.',
    tech_page_desc: 'Science in the Back, Business in Front',
    tech_page_par_1:
      'Grâce à notre plateforme de traitement des données satellite, notre équipe peut entraîner et exécuter nos modèles de manière efficace.',
    tech_page_par_2:
      'Le moteur de Tenkō et sa bibliothèque de modèles peuvent traiter des téraoctets de données provenant de nombreux secteurs, régions et sources de risques, tandis que notre dashboard utilisateurs permet une présentation des analyses en toute efficacité.',
    tech_page_title: 'Notre technologie',
    tech_page_use_cases_link: 'Consultez',
    tech_page_use_cases_text:
      "nos cas d'étude, pour quelques exemples de l'impact de nos modèles sur les entreprises.",
    to: 'To',
    to_date: 'to date',
    try_again: 'Try again',
    unknown: 'Unknown',
    use_cases: 'Cas d’étude',
    vendor_name: 'Vendor Name',
    wind_hazard_seasonality: 'Wind Hazard Seasonality',
    wind_risk: 'Wind Risk'
  }
}
