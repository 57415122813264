import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import routes from 'navigation/routes'

const Widget = ({
  title,
  className,
  isLoading,
  sx = {},
  children,
  allowBackNavigation = false,
  startAdornment,
  ...other
}) => {
  const navigate = useNavigate()
  const { tab } = useParams()

  return (
    <Box
      className={className}
      sx={{
        backgroundColor: '#FFF',
        borderRadius: 1,
        height: '100%',
        // maxHeight: '50vh',
        minHeight: '360px',
        overflow: 'hidden',
        ...sx
      }}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'secondary.main',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 1.5
        }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          {startAdornment}
          <Typography sx={{ color: 'primary.contrastText' }} variant="h6">
            {title}
          </Typography>
        </Box>
        {allowBackNavigation && (
          <IconButton
            aria-label="Back to panels dashboard"
            onClick={() => navigate(generatePath(routes.riskTab, { tab }))}
            size="small"
            sx={{ color: 'primary.contrastText', height: '1px' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {isLoading ? (
        <Box mt={2} sx={{ textAlign: 'center' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 84px)', margin: 2 }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

export default Widget
