import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
// import { useMutateOperation } from '../api/operations'
import { processError } from 'api/error'
import React, { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

export default function AutosaveInput(props) {
  const { isNumber, mutation, name, value: initialValue, sx = {}, ...others } = props
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    setFormErrors(processError(mutation.error).fields || {})
  }, [mutation.error])

  const [textVal, setTextVal] = useState(initialValue)
  useEffect(() => {
    setTextVal(initialValue)
  }, [initialValue])

  const handleChange = (key, value) => {
    setTextVal(value)
    const payload = key
      .split('.')
      .reverse()
      .reduce((accum, el) => {
        return { [el]: accum }
      }, value)
    // console.log(payload)
    debouncedMutate(payload)
  }

  /* eslint react-hooks/exhaustive-deps: "off" */
  const debouncedMutate = useCallback(
    debounce(payload => {
      mutation.mutate(payload)
    }, 500),
    []
  )
  return (
    <TextField
      error={!!formErrors[name] || false}
      fullWidth
      helperText={formErrors[name] || null}
      name={name}
      onChange={e => handleChange(e.target.name, e.target.value)}
      sx={{
        '.MuiInputLabel-sizeSmall': { zIndex: 0 },
        ...sx,
        ...(isNumber ? { '& .MuiInputBase-input': { textAlign: 'right' } } : {})
      }}
      value={textVal || ''}
      {...others}
    />
  )
}

AutosaveInput.propTypes = {
  mutation: PropTypes.object.isRequired
  // value: PropTypes.isRequired
}
