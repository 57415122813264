import { Alert } from '@mui/material'
import { processError } from '../../api/error'
import React from 'react'

export default function QueryErrors(props) {
  const { error, mutation } = props

  return (
    <>
      {error || mutation?.isError ? (
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          {processError(error || mutation.error).msg}
        </Alert>
      ) : null}
    </>
  )
}
